import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../ChatApp.css';
import { GlobalContext } from '../context/Context';

const UserList = (props) => {
  const { counter } = props;
  const [currentUser, setCurrentUser] = useState('User1');
  const [userList, setUserList] = useState([]);
  let { state } = useContext(GlobalContext);
  const role = state?.user?.role
  const auth = localStorage.getItem('user');
  const token = JSON.parse(auth).token
  const baseurl = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    getRecepient()
  }, [])


  const getRecepient = async () => {
    let result = await fetch(`${baseurl}/api/get-recipient`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    result = await result.json();
    setUserList(result)
  }

  return (
    <>
      <div className="user-list">
        {(role == 2 || role == 3) ?
          <ul>
            <h2 className='mb-5'>Learners</h2>
            {userList?.learners?.map((user, index) => (
              <Link style={{ textDecoration: "none", color: "#000" }} to={`/chat/${user?.id}`}>
                <li key={index} onClick={() => setCurrentUser(user)} className={user === currentUser ? 'active' : ''}>
                  <div className='d-flex justify-content-between'>
                    <p >
                      <img src={user?.image} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover", margin: "0px 10px 0px 0px" }} alt="" />
                      {user?.name}
                    </p>
                    <p className=''>
                      <Badge key={user.id} bg="danger" pill>{(counter[user.id] != 0) ? counter[user.id] : ""}</Badge>
                    </p>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
          : (role == 4) ?
            <ul>
              {userList?.assessors?.map((user, index) => (
                <Link style={{ textDecoration: "none", color: "#000" }} to={`/chat/${user?.id}`}>
                  <li key={index} onClick={() => setCurrentUser(user)} className={user === currentUser ? 'active' : ''}>
                    <div className='d-flex justify-content-between'>
                      <p >
                        <img src={user?.image} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover", margin: "0px 10px 0px 0px" }} alt="" />
                        {user?.name}
                      </p>
                      <p className=''>
                        <Badge key={user.id} bg="danger" pill>{(counter[user.id] != 0) ? counter[user.id] : ""}</Badge>
                      </p>
                    </div>
                  </li>
                </Link>
              ))}
              {userList?.trainers?.map((user, index) => (
                <Link style={{ textDecoration: "none", color: "#000" }} to={`/chat/${user?.id}`}>
                  <li key={index} onClick={() => setCurrentUser(user)} className={user === currentUser ? 'active' : ''}>
                    <div className='d-flex justify-content-between'>
                      <p >
                        <img src={user?.image} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover", margin: "0px 10px 0px 0px" }} alt="" />
                        {user?.name}
                      </p>
                      <p className=''>
                        <Badge key={user.id} bg="danger" pill>{(counter[user.id] != 0) ? counter[user.id] : ""}</Badge>
                      </p>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
            :
            null
        }
      </div >
    </>
  );
};

export default UserList;
