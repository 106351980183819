import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SetAvailabilityModal = ({ showModal, handleClose, handleSave }) => {
    
    const [meetingTimes, setMeetingTimes] = useState({
        Monday: { startTimeOption: "off", startTime: "", endTime: "" },
        Tuesday: { startTimeOption: "off", startTime: "", endTime: "" },
        Wednesday: { startTimeOption: "off", startTime: "", endTime: "" },
        Thursday: { startTimeOption: "off", startTime: "", endTime: "" },
        Friday: { startTimeOption: "off", startTime: "", endTime: "" },
        Saturday: { startTimeOption: "off", startTime: "", endTime: "" },
        Sunday: { startTimeOption: "off", startTime: "", endTime: "" },
    });
    const handleOptionChange = (event, day) => {
        const newStartTimeOption = event.target.value;
        setMeetingTimes((prevMeetingTimes) => ({
            ...prevMeetingTimes,
            [day]: {
                ...prevMeetingTimes[day],
                startTimeOption: newStartTimeOption,
            },
        }));
    };
    const handleStartTimeChange = (event, day) => {
        const newStartTime = event.target.value;
        setMeetingTimes((prevMeetingTimes) => ({
            ...prevMeetingTimes,
            [day]: {
                ...prevMeetingTimes[day],
                startTime: newStartTime,
            },
        }));
    };
    const handleEndTimeChange = (event, day) => {
        const newEndTime = event.target.value;
        setMeetingTimes((prevMeetingTimes) => ({
            ...prevMeetingTimes,
            [day]: {
                ...prevMeetingTimes[day],
                endTime: newEndTime,
            },
        }));
    };
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='d-flex justify-content-center'>
                    <div>
                        Set Availability
                        <p class="h6 mt-1 fw-normal">Set Your Availability</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table class="table SetAvailabilityModal">
                    <thead>
                        <tr >
                            <th className="text-primary" scope="col">Days</th>
                            <th className="text-primary" scope="col">Start Time</th>
                            <th className="text-primary" scope="col">End Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Monday</td>
                            <td >
                                {meetingTimes.Monday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Monday.startTimeOption} onChange={(event) => handleOptionChange(event, "Monday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Monday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Monday.startTime} onChange={(event) => handleStartTimeChange(event, "Monday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Monday: { ...meetingTimes.Monday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td width="200px">
                                {meetingTimes.Monday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Monday.endTime} onChange={(event) => handleEndTimeChange(event, "Monday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Tuesday</td>
                            <td >
                                {meetingTimes.Tuesday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Tuesday.startTimeOption} onChange={(event) => handleOptionChange(event, "Tuesday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Tuesday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Tuesday.startTime} onChange={(event) => handleStartTimeChange(event, "Tuesday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Tuesday: { ...meetingTimes.Tuesday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Tuesday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Tuesday.endTime} onChange={(event) => handleEndTimeChange(event, "Tuesday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Wednesday</td>
                            <td >
                                {meetingTimes.Wednesday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Wednesday.startTimeOption} onChange={(event) => handleOptionChange(event, "Wednesday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Wednesday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Wednesday.startTime} onChange={(event) => handleStartTimeChange(event, "Wednesday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Wednesday: { ...meetingTimes.Wednesday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Wednesday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Wednesday.endTime} onChange={(event) => handleEndTimeChange(event, "Wednesday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Thursday</td>
                            <td >
                                {meetingTimes.Thursday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Thursday.startTimeOption} onChange={(event) => handleOptionChange(event, "Thursday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Thursday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Thursday.startTime} onChange={(event) => handleStartTimeChange(event, "Thursday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Thursday: { ...meetingTimes.Thursday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Thursday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Thursday.endTime} onChange={(event) => handleEndTimeChange(event, "Thursday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Friday</td>
                            <td width="220">
                                {meetingTimes.Friday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Friday.startTimeOption} onChange={(event) => handleOptionChange(event, "Friday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Friday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Friday.startTime} onChange={(event) => handleStartTimeChange(event, "Friday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Friday: { ...meetingTimes.Friday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Friday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Friday.endTime} onChange={(event) => handleEndTimeChange(event, "Friday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Saturday</td>
                            <td >
                                {meetingTimes.Saturday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Saturday.startTimeOption} onChange={(event) => handleOptionChange(event, "Saturday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Saturday.startTimeOption === 'on' && (
                                    <>
                                        <input type="time" value={meetingTimes.Saturday.startTime} onChange={(event) => handleStartTimeChange(event, "Saturday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Saturday: { ...meetingTimes.Saturday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Saturday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Saturday.endTime} onChange={(event) => handleEndTimeChange(event, "Saturday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Sunday</td>
                            <td>
                                {meetingTimes.Sunday.startTimeOption === 'off' && (
                                    <select className="text-danger" value={meetingTimes.Sunday.startTimeOption} onChange={(event) => handleOptionChange(event, "Sunday")}>
                                        <option className="text-danger" value="off">Off</option>
                                        <option className="text-primary" value="on">Set Time</option>
                                    </select>)}
                                {meetingTimes.Sunday.startTimeOption === 'on' && (
                                    <>
                                        <input style={{ border: "none", outline: "none" }} type="time" value={meetingTimes.Sunday.startTime} onChange={(event) => handleStartTimeChange(event, "Sunday")} />
                                        <select onChange={(event) => setMeetingTimes({ ...meetingTimes, Sunday: { ...meetingTimes.Sunday, startTimeOption: event.target.value } })}>
                                            <option className="text-danger" value="off"></option>
                                            <option className="text-danger" value="off">Off</option>
                                        </select>
                                    </>
                                )}
                            </td>
                            <td>
                                {meetingTimes.Sunday.startTimeOption === 'on' ? (
                                    <input type="time" value={meetingTimes.Sunday.endTime} onChange={(event) => handleEndTimeChange(event, "Sunday")} />
                                ) :
                                    <option className="text-danger" value="off">Off</option>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary w-100 " onClick={handleClose}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default SetAvailabilityModal;
