import React from "react";
import ins from "../images/ins.png";
import { Link } from "react-router-dom";

const Footer = () => {
    
    return (
        <div className="footer">
            <div className="main-footer">
                <div>
                    <div className="footerText">
                        <p>© 2022 – out-lifts.com</p>
                        <div className="f-social">
                            <ul>
                                <li><Link target="_blank" to="https://www.facebook.com/"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 21" fill="none"><path d="M10.8474 8.79746C10.8474 8.25334 11.2885 7.81224 11.8326 7.81224H12.8178C13.362 7.81224 13.803 7.37115 13.803 6.82702C13.803 6.2829 13.362 5.8418 12.8178 5.8418H11.8326C10.2002 5.8418 8.87694 7.1651 8.87694 8.79746V10.7679H7.89172C7.3476 10.7679 6.90649 11.209 6.90649 11.7531C6.90649 12.2973 7.34759 12.7383 7.89172 12.7383H8.87694V18.6497C8.87694 19.1938 9.31802 19.6349 9.86216 19.6349C10.4063 19.6349 10.8474 19.1938 10.8474 18.6497V12.7383H12.8178C13.362 12.7383 13.803 12.2973 13.803 11.7531C13.803 11.209 13.362 10.7679 12.8178 10.7679H10.8474V8.79746Z" fill="#247AFF"></path><path fillRule="evenodd" clipRule="evenodd" d="M15.7736 2.88621H3.9509C2.86265 2.88621 1.98045 3.76841 1.98045 4.85666V16.6793C1.98045 17.7676 2.86265 18.6498 3.9509 18.6498H15.7736C16.8618 18.6498 17.744 17.7676 17.744 16.6793V4.85666C17.744 3.76841 16.8618 2.88621 15.7736 2.88621ZM3.9509 0.915771C1.7744 0.915771 0.0100098 2.68017 0.0100098 4.85666V16.6793C0.0100098 18.8558 1.7744 20.6202 3.9509 20.6202H15.7736C17.95 20.6202 19.7144 18.8558 19.7144 16.6793V4.85666C19.7144 2.68017 17.95 0.915771 15.7736 0.915771H3.9509Z" fill="#247AFF"></path></svg></Link></li>
                                <li><Link target="_blank" to="https://www.linkedin.com/"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M16.212 2.88621H4.38937C3.30113 2.88621 2.41893 3.76841 2.41893 4.85666V16.6793C2.41893 17.7676 3.30113 18.6498 4.38937 18.6498H16.212C17.3003 18.6498 18.1825 17.7676 18.1825 16.6793V4.85666C18.1825 3.76841 17.3003 2.88621 16.212 2.88621ZM4.38937 0.915771C2.21288 0.915771 0.448486 2.68017 0.448486 4.85666V16.6793C0.448486 18.8558 2.21288 20.6202 4.38937 20.6202H16.212C18.3885 20.6202 20.1529 18.8558 20.1529 16.6793V4.85666C20.1529 2.68017 18.3885 0.915771 16.212 0.915771H4.38937Z" fill="#247AFF"></path><path d="M5.37463 6.82713C5.91874 6.82713 6.35985 6.38603 6.35985 5.84191C6.35985 5.29779 5.91874 4.85669 5.37463 4.85669C4.83051 4.85669 4.3894 5.29779 4.3894 5.84191C4.3894 6.38603 4.83051 6.82713 5.37463 6.82713Z" fill="#247AFF"></path><path d="M5.37463 8.79761C4.83051 8.79761 4.3894 9.23869 4.3894 9.78283V15.6942C4.3894 16.2383 4.83051 16.6794 5.37463 16.6794C5.91874 16.6794 6.35985 16.2383 6.35985 15.6942V9.78283C6.35985 9.23869 5.91874 8.79761 5.37463 8.79761Z" fill="#247AFF"></path><path d="M12.2712 10.7681C11.1829 10.7681 10.3008 11.6502 10.3008 12.7385V15.6942C10.3008 16.2383 9.85968 16.6794 9.31554 16.6794C8.77141 16.6794 8.33032 16.2383 8.33032 15.6942V9.78283C8.33032 9.23869 8.77141 8.79761 9.31554 8.79761C9.71495 8.79761 10.0588 9.03524 10.2135 9.37682C10.8124 9.00943 11.5171 8.79761 12.2712 8.79761C14.4477 8.79761 16.2121 10.562 16.2121 12.7385V15.6942C16.2121 16.2383 15.771 16.6794 15.2269 16.6794C14.6827 16.6794 14.2417 16.2383 14.2417 15.6942V12.7385C14.2417 11.6502 13.3595 10.7681 12.2712 10.7681Z" fill="#247AFF"></path></svg></Link></li>
                                <li><Link target="_blank" to="https://twitter.com/"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 23 19" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2.98625 11.7381C1.34534 11.7428 0.286096 13.5988 1.297 15.005C2.74472 17.0187 5.36997 18.6349 9.75277 18.6349C16.5448 18.6349 21.9969 13.139 21.2336 6.64581L22.3518 4.42531C23.1273 2.88539 21.7798 1.13361 20.0794 1.47126L18.6022 1.76461C18.2105 1.55943 17.8052 1.40459 17.4588 1.29242C16.7837 1.0738 15.9666 0.900879 15.2102 0.900879C13.8515 0.900879 12.6788 1.247 11.7469 1.9412C10.8256 2.62762 10.3067 3.5297 10.0219 4.35807C9.8906 4.7399 9.80318 5.12289 9.74709 5.49047C9.21993 5.32574 8.68242 5.10409 8.15619 4.83344C6.96231 4.21942 5.9969 3.441 5.4523 2.75852C4.5386 1.61349 2.60228 1.69839 1.9228 3.1868C0.965142 5.28459 1.22784 7.77128 2.08866 9.78058C2.37719 10.454 2.75251 11.1197 3.21104 11.7366C3.13254 11.7375 3.05745 11.7379 2.98625 11.7381ZM9.75268 16.6644C5.91926 16.6644 3.93707 15.2858 2.91212 13.8602C2.86607 13.7961 2.91251 13.7088 2.99176 13.7086C4.03446 13.7056 6.17475 13.6566 7.58633 12.8363C7.65933 12.7939 7.64326 12.687 7.56309 12.6603C4.27261 11.5618 2.40218 6.90934 3.73019 4.00029C3.76065 3.93359 3.85094 3.92479 3.89675 3.9822C5.41378 5.88332 8.73068 7.74541 11.6165 7.79636C11.6791 7.79743 11.7265 7.74098 11.7169 7.67953C11.6006 6.93096 11.1566 2.87132 15.2101 2.87132C16.178 2.87132 17.6182 3.34012 18.1486 3.82039C18.1731 3.84258 18.2064 3.85266 18.2389 3.8462L20.4685 3.40344C20.5495 3.38736 20.6137 3.47078 20.5768 3.54411L19.1939 6.29023C19.1842 6.30929 19.1813 6.33117 19.1849 6.35217C20.1535 11.758 15.6943 16.6644 9.75268 16.6644Z" fill="#247AFF"></path></svg></Link></li>
                                <li><Link target="_blank" to="https://www.instagram.com/"><img src={ins} alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="f-nav">
                    <ul>
                        <li><Link to="/termsandservices">Terms of Service</Link></li>
                        <li><Link to="/termsandconditions">Terms & Condition</Link></li>
                        <li><Link to="/privacy">Privacy</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Footer;