import { Badge, Calendar } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssessorSideBar from "./AssessorSideBar";
import Header from "./Header";
import SetAvailabilityModal from "./SetAvailabilityModal";

const AssessorCalender = () => {

    const navigate = useNavigate()
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [showModal, setShowModal] = useState(false);
    const [calendarData, setCalendarData] = useState([])
    const [value, setValue] = useState();
    const [selectedDateEvent, setSelectedDateEvent] = useState([]);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        getCalendarData(token)
    }, [])

    const auth = localStorage.getItem("user");
    const token = JSON.parse(auth).token
    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    }
    const onSelect = async (newValue) => {
        setValue(newValue);
        var date = { "date": newValue.format('YYYY-MM-DD') }
        try {
            let result = await fetch(`${baseurl}/api/get-events`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(date)
            });
            if (!result.ok) {
                throw new Error('Failed to fetch calendar data');
            }
            result = await result.json();
            setSelectedDateEvent(result)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const onPanelChange = (newValue) => {
        setValue(newValue);
    };
    const getCalendarData = async (token) => {
        try {
            let result = await fetch(`${baseurl}/api/calendar`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setCalendarData(result);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        const auth = localStorage.getItem("user");
        if (auth) {
            getCalendarData(JSON.parse(auth).token);
        } else {
            navigate("/");
        }
    }, []);
    const dateCellRender = (value) => {
        const eventsForDate = calendarData.filter((item) => item.date === value.format("YYYY-MM-DD"));
        return (
            eventsForDate?.map((item, index) => (
                <ul className="events p-0">
                    <li key={index}>
                        <Badge
                            status={"success"}
                            text={truncateText(item.title, 5)}
                        />
                    </li>
                </ul >
            ))
        );
    };
    const monthCellRender = (value) => {
        if (value.month() === 8) {
            const backlogNumber = calendarData.filter((item) => item.category).length;
            return (
                <div className="notes-month">
                    <section>{backlogNumber}</section>
                    <span>Backlog number</span>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <Header />
                    <section className="Dashbord-page3-sec-two">
                        <div className="dashbord-container">
                            <div className="Dashbord-sec-two">
                                <div className="Calander-page-sec-one-left">
                                    <Calendar value={value} onSelect={onSelect} dateCellRender={dateCellRender} monthCellRender={monthCellRender} onPanelChange={onPanelChange} />
                                </div>
                                < div className="Calander-page-sec-one-right" >
                                    <h2 className="Calander-page-sec-one-right-heading">Tasks</h2>
                                    <br></br>
                                    {
                                        (selectedDateEvent?.map((item, index) =>
                                            <div key={index}>
                                                <p className="Tasks-tx text-primary mb-2"><b>{item?.student?.name}</b></p>
                                                <p className="Tasks-tx text-black mb-2"><b>{item?.title}</b></p>
                                                <p className="Tasks-tx  m-0 py-0">{new Date(item?.date).toLocaleString('en-GB').slice(0, 10)}</p>
                                                <p className="Tasks-tx">{item?.note}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="AssessorCalendarBottomSection">
                                <div className="AssessorCalendar-Sec1">
                                    <div className="YourAvailability">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="Upcoming-Deadlines-heading">Your Availability</h3>
                                            <p className="Upcoming-Deadlines-heading text-primary" onClick={() => setShowModal(true)}>Edit</p>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="Time">08:00AM - 05:00 PM</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="Time">08:00AM - 05:00 PM</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="Time">08:00AM - 05:00 PM</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="Time">08:00AM - 05:00 PM</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="24-Dec">24 Dec</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="24-Dec">24 Dec</h4>
                                        </div>
                                        <div className="Task-title">
                                            <h4 className="Task-title-heading">Task_title</h4>
                                            <h4 className="24-Dec">24 Dec</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div >
            <SetAvailabilityModal showModal={showModal} handleClose={handleCloseModal} />
        </>
    )
}
export default AssessorCalender;