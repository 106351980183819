import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import note2 from "../images/note-2.png";

const ObservatoryRecord = (props) => {

  const baseurl = process.env.REACT_APP_BASE_URL;
  const { unitId } = props;
  const navigate = useNavigate();
  const [JobAssessmentRecord, setJobAssessmentRecord] = useState([]);

  useEffect(() => {
    const auth = localStorage.getItem("user");
    if (auth) {
      getJobAssessmentRecord(JSON.parse(auth).token);
    } else {
      navigate("/");
    }
  }, []);

  const getJobAssessmentRecord = async (token) => {
    let result = await fetch(`${baseurl}/api/job-records?user_id=`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    result = await result.json();
    setJobAssessmentRecord(result);
  };

  return (
    <>
      <section className="sec-two-Underpinning-Questions">
        <div className="sec-two-Underpinning-Questions-container">
          <div className="Job-Records-sec">
            <div className="type-bar">
              <div className="Entry Job-Records-Entry">
                <h3 className="Job-Records">Observation Records</h3>

                <Link to={`/addjobrecord/${unitId}`}>
                  <button type="submit" className="btns2">
                    + Add New Observation Record
                  </button>
                </Link>
              </div>

              <div className="table-responsive">
                <table className="jobAssessmentrecordTable">
                  <tr className="First-row">
                    <th>Date</th>
                    <th>Job Entry Title</th>
                    <th>Job Location</th>
                    <th></th>
                  </tr>
                  {JobAssessmentRecord?.map((item, index) => (
                    (item?.course_id == unitId) ?
                      <tr>
                        <td>{item?.job_date}</td>
                        <td class="overflow-ellipsis">{item?.job_title}</td>
                        <td class="overflow-ellipsis" >{item?.job_location}</td>
                        <td>
                          <Link to={"/editjobrecord/" + item?.id + "/" + unitId}>
                            <div className="Review-icon-box">
                              <div className="Review-icon">
                                <img src={note2} alt="" />
                              </div>
                              <h4 className="Review-tx">Edit</h4>
                            </div>
                          </Link>
                        </td>
                      </tr>
                      : null
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ObservatoryRecord;
