import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const MyModal = ({ showModal, handleClose, handleSave }) => {
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='text-primary'>Invalid Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please Enter Correct Credentials.</p>
      </Modal.Body>
      <div className='d-flex justify-content-center'>
        <Modal.Footer>
          <Button variant="primary" style={{width: "200px"}} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default MyModal;
