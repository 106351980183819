import React from 'react'
import Header from './Header'

function TermsAndConditions() {
    return (
        <>
            <div>
                <Header />
                <div className='termsandservices'>
                    <h3>TERMS AND CONDITIONS</h3>
                    <p>Welcome to the website of Onwards &amp; Upwards Training LTD (O.U.T), a specialised NVQ Level 3 Lift Engineering Assessment company. These Terms and Conditions govern the use of our website and the services we provide. Please read these terms carefully before using our services.</p>
                    <h4>1. Acceptance of Terms</h4>
                    <p>By using our website, you agree to these Terms and Conditions. If you disagree with any part of these terms, please do not use our services. We reserve the right to modify these terms at any time, and you agree to abide by the most recent version of this.</p>
                    <h4>2. Description of Service</h4>
                    <p>We provide an online platform for NVQ Level 3 Lift Engineering Assessment. The services include but are not limited to the provision of an online portfolio, communication forums and product information.</p>
                    <h4>3. Personal and Non-Commercial Use</h4>
                    <p>Unless otherwise specified, the services are for your personal and non-commercial use. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, products or services obtained from our services.</p>
                    <h4>4. User Obligations</h4>
                    <p>You agree to use our services for lawful purposes and in a way that does not infringe the rights of, or restrict or inhibit the use and enjoyment of, this site by any third party. Such restriction or inhibition includes, without limitation, conduct which is unlawful, or which may harass or cause distress or inconvenience to any person and the transmission of obscene or offensive content or disruption of normal flow of dialogue within this site.</p>
                    <h4>5. Intellectual Property Rights</h4>
                    <p>All copyright, trademarks and all other intellectual property rights in the website and its content (including without limitation the website design, text, graphics and all software and source codes connected with the website) are owned by or licensed to Onwards &amp; Upwards Training LTD or otherwise used by Onwards &amp; Upwards Training LTD as permitted by law.</p>
                    <h4>6. Limitation of Liability</h4>
                    <p>Under no circumstances will Onwards &amp; Upwards Training LTD be liable for any losses or damage resulting from the use of our services, including, without limitation, indirect or consequential losses or damage.</p>
                    <h4>7. Governing Law</h4>
                    <p>These Terms and Conditions shall be governed by and construed in accordance with the law of England and Wales, and you hereby submit to the exclusive jurisdiction of the English courts.</p>
                    <h4>8. Changes to Terms and Conditions</h4>
                    <p>We reserve the right to change these terms and conditions at any time. The new version will be posted on the website and will take effect immediately upon posting.</p>
                    <p>This document was last updated on July 9, 2023.</p>
                </div>
            </div>



        </>)
}

export default TermsAndConditions; 