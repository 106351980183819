import JoditEditor from "jodit-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import Header from "./Header";

const ShowJobRecord = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    const [job_title, setJobTitle] = useState("");
    const [job_date, setJobDate] = useState("");
    const [job_location, setJobLocation] = useState("");
    const [description, setContent] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [trainers, setTrainers] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState("");
    const editor = useRef(null);
    let { state, dispatch } = useContext(GlobalContext);
    const role = state?.user?.role

    useEffect(() => {
        getJobRecord();
        getTrainers()
    }, []);
    const params = useParams();
    const getJobRecord = async () => {
        const auth = localStorage.getItem('user');
        let result = await fetch(`${baseurl}/api/job-records/${params.id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(auth).token}`
            }
        });
        result = await result.json();
        if (result) {
            setJobTitle(result.job_title);
            setJobDate(result.job_date);
            setJobLocation(result.job_location);
            setContent(result.description);
            setFileUrl(result.file);
            setSelectedTrainer(result.trainer_id)
        }
    };
    const getTrainers = async () => {
        const auth = localStorage.getItem('user');
        try {
            let result = await fetch(`${baseurl}/api/get-trainers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(auth).token}`
                }
            });
            result = await result.json();
            setTrainers(result?.trainers)
        }
        catch {
            console.log("error");
        }
    }
    return (
        <>
            <Header />
            <section className="Add-New-Job-Assessment-Record">
                <div className="Add-New-Job-Assessment-Record-container">
                    <div className="Add-New-Job-Assessment-Record-main-one">
                        <h1 className="Add-New-Job-Assessment-Record-heading">Show Online Job Entry Submission</h1>
                        <br />
                        <form>
                            <div className="top-fields">
                                <div className="form-col-one">
                                    <div className="field">
                                        <h5>Job Title</h5>
                                        <input disabled type="text" value={job_title} onChange={(e) => setJobTitle(e.target.value)} placeholder="Job Title" />
                                    </div>
                                    <div className="field">
                                        <h5>Job Date</h5>
                                        <input disabled type="date" value={job_date} onChange={(e) => setJobDate(e.target.value)} placeholder="Job Date" />
                                    </div>
                                </div>
                            </div>
                            <div className="location-field">
                                <h5 className="Job-Location">Job Location</h5>
                                <input disabled type="text" value={job_location} onChange={(e) => setJobLocation(e.target.value)} placeholder="Job Location" className="Job-Location" />
                            </div>
                            <div className="job-editor">
                                <div className="d-flex justify-content-between">
                                    <h5>Describe what you did to carry out this task</h5>
                                    {role === 4 && (
                                        <div>
                                            <label className="px-2">Select Witness Trainer</label>
                                            <select
                                                className="px-4"
                                                value={selectedTrainer}
                                            >
                                                {trainers?.map((item, index) => (
                                                    <option key={item.value} value={item.value} disabled>
                                                        {item.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                                {
                                    (description[0] != null) ?
                                        description?.map((item, index) => (
                                            <JoditEditor
                                                ref={editor}
                                                value={item}
                                                config={{
                                                    readonly: true,
                                                }}
                                            />
                                        ))
                                        :
                                        (description[0] == null || description?.length == 0) ?
                                            <p className="text-secondary m-4 text-center">No description added</p>
                                            : null
                                }
                            </div>
                        </form>
                    </div>
                    <div className="Add-New-Job-Assessment-Record-main-two">
                        <div className="Add-New-Job-Assessment-Record-main-two-top-bar">
                            <div className="Add-New-Job-Assessment-Record-main-two-tx">
                                <h1 className="Add-New-Job-Assessment-Record-heading-one">Doc/files Submission</h1>
                                <br />
                            </div>
                        </div>
                        {
                            (description[0] == null || description?.length == 0) &&
                            <div style={{ textAlign: 'center' }}>
                                <a href={fileUrl} download target="_blank">
                                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/file-and-folder-type/file-download-icon.png" alt="W3Schools" width="104" />
                                </a>
                                <p>Download File</p>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default ShowJobRecord;
