import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

function OverallProgrammeAssessment() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const { id } = useParams()
    const [overallprogramme, setOverallProgramme] = useState([])
    useEffect(() => {
        getOverallProgrammeAssessment()
    }, [])
    
    const getOverallProgrammeAssessment = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/get-overall-program-assessment/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setOverallProgramme(result)
        }
        catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className='commentsDashboard'>
                <div className='table-responsive'>
                    <table>
                        <tr className='text-center bg-primary text-white'>
                            <th className="col-md-1">Start Date</th>
                            <th className="col-md-1">Planned End Date</th>
                            <th className="col-md-2">Anticipated Comp. Date</th>
                            <th className="col-md-1">On Target</th>
                            <th className="col-md-1">Actions</th>
                        </tr>
                        {overallprogramme.length > 0 ?
                            overallprogramme?.map((item, index) =>
                                <tr className="Assignments text-center">
                                    <td className="grading">{new Date(item?.start_date).toLocaleString('en-GB').slice(0, 10)}</td>
                                    <td className="grading">{new Date(item?.planned_end_date).toLocaleString('en-GB').slice(0, 10)}</td>
                                    <td className="grading">{new Date(item?.anticipated_completed_date).toLocaleString('en-GB').slice(0, 10)}</td>
                                    <td className="grading">{item?.on_target}</td>
                                    <td className="grading">
                                        <Link to={`/editoverallprogramme/${item?.id}`}>
                                            <Button variant='primary'>Edit</Button>
                                        </Link>
                                    </td>
                                </tr>
                            )
                            :
                            null
                        }
                    </table>
                </div>
            </div>
            {overallprogramme.length > 0 ?
                null
                :
                <div className='d-flex justify-content-center text-center my-4'>
                    <tr>
                        <td >
                            <Link to={`/addoverallprogramme/${id}`}>
                                <p className='px-4 py-2' variant="primary"> + Add Overall Programme Assessment</p>
                            </Link>
                        </td>
                    </tr>
                </div>
            }
        </>
    )
}
export default OverallProgrammeAssessment;