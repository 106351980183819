import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useParams } from 'react-router-dom';
import DeleteReviewModal from './DeleteReviewModal';
import JoditToPlainText from './JodittoPlainText';

function AssessorComments() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const { id } = useParams()
    const [showModal, setShowModal] = useState(false)
    const [comments, setComments] = useState([])
    const [commentToDelete, setCommentToDelete] = useState(null)
    const auth = localStorage.getItem("user");
    var token = JSON.parse(auth).token

    useEffect(() => {
        getComments()
    }, [])
    const DeleteReview = (id) => {
        setCommentToDelete(id)
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }
    const deleteComments = async (id) => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        let result = await fetch(`${baseurl}/api/planned-comment/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        setComments(prevComments => prevComments.filter(comment => comment.id !== commentToDelete))
        setShowModal(false)
    }
    const getComments = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/get-planned-comment/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setComments(result)
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className='CommentsDashboard'>
                <div className='overflow-auto'>
                    <div className='d-flex justify-content-between mb-5'>
                        <h2>Review Comments</h2>
                        <Link to={`/addcomment/${id}`}>
                            <Button className='p-2' variant="primary"> + Add New Review Comment</Button>
                        </Link>
                    </div>
                    <div className='table-responsive overflow-auto'>
                        <div className='comments-table'>
                            <table>
                                <tr className='text-center bg-primary text-white'>
                                    <th class="col-md-1">Review Made</th>
                                    <th class="col-md-3">Review Comments</th>
                                    <th class="col-md-1">Location</th>
                                    <th class="col-md-1">Action</th>
                                </tr>
                                {comments?.map((item, index) =>
                                    <tr className="Assignments text-center">
                                        <td className="grading">{new Date(item?.review_made).toLocaleString('en-GB').slice(0, 10)}</td>
                                        <td className="grading"><JoditToPlainText html={item?.description} /></td>
                                        <td className="grading">{item?.location}</td>
                                        <td className="grading">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    Actions
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item >
                                                        <Link to={`/editcomment/${item?.id}/${id}`}>
                                                            <div>
                                                                Edit
                                                            </div>
                                                        </Link>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item >
                                                        <div onClick={()=>DeleteReview(item?.id)}>
                                                            Delete
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div >
            <DeleteReviewModal showModal={showModal} handleClose={handleClose} DeleteFunction={() => deleteComments(commentToDelete)} />
        </>
    )
}
export default AssessorComments;