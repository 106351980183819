import React, { createContext, useReducer } from 'react';
import { reducer } from './Reducer';

export const GlobalContext = createContext();

const initialState = {
  user: undefined,
  token: null,
  qaLearners: [],
  qaSelectedLearner: null,
  qaSelectedLearnerName: null,
};

export default function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
}
