import { React, useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row
} from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GlobalContext } from '../context/Context';
import AssessorSideBar from "./AssessorSideBar";
import ConfirmationModal from "./ConfirmationModal";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import TrainerSidebar from "./TrainerSidebar";

const Profile = () => {
    
    const baseurl = process.env.REACT_APP_BASE_URL;
    let { state, dispatch } = useContext(GlobalContext);
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const id = state?.user?.id;
    const username = state?.user?.name;
    const email = state?.user?.email;
    const unformatted_date_of_birth = state?.user?.date_of_birth;
    const phone_no = state?.user?.phone_no;
    const image = state?.user?.image;
    const role = state?.user?.role;
    const date_of_birth = new Date(unformatted_date_of_birth).toISOString().substring(0, 10);
    const [form, setForm] = useState({
        id: id, name: username, email: email, current_password: '', password: '', image: image, phone_no: phone_no, date_of_birth: date_of_birth, role: role
    });
    const closeModal = () => {
        setShowModal(false)
    }
    useEffect(() => {
        setPreviewImage(image)
    }, [])
    const getProfileData = async () => {
        try {
            if (
                form.name == "" || form.email == "" || form.phone_no == "" || form.phone_no == null || form.date_of_birth == "") {
                alert("Please Fill All Details")
                return
            }
            if ((form.password !== '' && form.current_password === '') || (form.password === '' && form.current_password !== '')) {
                alert("Make sure that both current and new password fields are filled.");
                return;
            }
            const auth = localStorage.getItem('user');
            const token = JSON.parse(auth).token
            let result = await fetch(`${baseurl}/api/users/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(form)
            });
            if (!result.ok) {
                alert("Current password is incorrect")
                return
            }
            result = await result.json();
            setShowModal(true)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    function uploadImage(event) {
        if (event.target.files[0]) {
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
            var reader = new FileReader();
            reader.onloadend = function () {
                setForm({ ...form, image: reader.result })
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    return (
        <>
            <div className="das-main">
                {role == 4 || role == 5 ?
                    <SideBar />
                    : role == 2 ?
                        <AssessorSideBar />
                        : role == 3 ?
                            < TrainerSidebar />
                            : null
                }
                <section className="das-cntn bg-white">
                    <Header />
                    <div className="py-5 shadow-lg">
                        <Container fluid >
                            <Row >
                                <Col>
                                    <Card className="strpied-tabled-with-hover shadow">
                                        <Card.Body className="table-full-width table-responsive px-0 overflow-hidden">
                                            <Form >
                                                <Row className="px-4">
                                                    <Col className="mb-4 pl-2" md="6">
                                                        <Form.Group className="">
                                                            <label className="my-1">Name</label>
                                                            <Form.Control
                                                                style={{ fontSize: 14 }}
                                                                defaultValue={form.name}
                                                                placeholder="Enter Name"
                                                                type="text"
                                                                onChange={(event) => { setForm({ ...form, name: event.target.value }) }}
                                                                required
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="mb-4" md="6">
                                                        <Form.Group>
                                                            <label className="my-1">Date Of Birth</label>
                                                            <Form.Control
                                                                style={{ fontSize: 14 }}
                                                                defaultValue={form.date_of_birth}
                                                                type="date"
                                                                onChange={(event) => { setForm({ ...form, date_of_birth: event.target.value }) }}
                                                                required
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="mb-4" md="6">
                                                        <Form.Group>
                                                            <label className="my-1">Email</label>
                                                            <Form.Control
                                                                style={{ fontSize: 14 }}
                                                                defaultValue={form.email}
                                                                placeholder="Enter Email"
                                                                type="email"
                                                                readOnly
                                                                required
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="mb-4" md="6">
                                                        <Form.Group>
                                                            <label className="my-1">Phone Number</label>
                                                            <PhoneInput
                                                                value={form.phone_no}
                                                                onChange={(e) => setForm({ ...form, phone_no: e })}
                                                                className="w-100"
                                                                inputStyle={{ width: '100%' }}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="mb-4" md="6">
                                                        <Form.Group>
                                                            <label className="my-1">Current Password</label>
                                                            <Form.Control
                                                                style={{ fontSize: 14 }}
                                                                defaultValue={form.current_password}
                                                                placeholder="Enter Password"
                                                                type="password"
                                                                onChange={(event) => { setForm({ ...form, current_password: event.target.value }) }}
                                                                required
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="mb-4" md="6">
                                                        <Form.Group>
                                                            <label className="my-1">New Password</label>
                                                            <Form.Control
                                                                style={{ fontSize: 14 }}
                                                                defaultValue={form.password}
                                                                placeholder="Enter New Password"
                                                                type="password"
                                                                onChange={(event) => { setForm({ ...form, password: event.target.value }) }}
                                                                required
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="pb-4" md="12">
                                                        <Form.Group>
                                                            <label className="my-1">Image</label>
                                                            <Form.Control
                                                                as="input"
                                                                type="file"
                                                                style={{ fontSize: 14 }}
                                                                onChange={(event) => { uploadImage(event) }}
                                                            >
                                                            </Form.Control>
                                                            {previewImage ? (
                                                                <div className="mt-5"><img src={previewImage} style={{ width: '300px', display: 'block', margin: 'auto' }} /></div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <div className="d-flex justify-content-end">
                                                        <Button className="btn-fill" style={{ fontSize: 14 }} variant="primary" onClick={() => { getProfileData() }}>
                                                            Save Changes
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container >
                        {
                            showModal &&
                            <ConfirmationModal content="Profile Updated SuccessFully" onButtonClick={closeModal} />
                        }
                    </div>
                    <Footer />
                </section>
            </div>
        </>
    );
};
export default Profile;