import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import calendar from "../images/calendar.svg";
import chart from "../images/chart.svg";
import clipboard from "../images/clipboard-text.svg";
import AssessorComments from "./AssessorComments";
import AssessorHeader from "./AssessorHeader";
import Footer from "./Footer";
import JobEnteries from "./JobEnteries";
import ObservatoryRecords from "./ObservatoryRecords";
import OverallProgrammeAssessment from "./OverallProgrammeAssessment";
import StudentProfileAssignments from "./StudentProfileAssignments";
import StudentProfileAssignments_up from "./StudentProfileAssignments_up";
import StudentProfileCalendar from "./StudentProfileCalendar";
import StudentProfileProgress from "./StudentProfileProgress";

const StudentProfile = () => {

    const baseurl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate()
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'tab2');
    const [activeUnit, setActiveUnit] = useState(localStorage.getItem('activeUnit') || '');
    const [activeType, setActiveType] = useState(localStorage.getItem('activeType') || 'Cross Reference');
    const [studentAssignment, setStudentAssignment] = useState([])
    const [studentProfile, setStudentProfile] = useState([])
    const getStudentAssignment = async (token, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch student assignment data');
            }
            result = await result.json();
            setStudentAssignment(result);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const getStudentProfile = async (token, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-profile/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch student profile data');
            }
            result = await result.json();
            setStudentProfile(result);
            setTimeout(() => {
                new Promise((resolve) => {
                    const lastUnit = localStorage.getItem('activeUnit');
                    resolve();
                }).then((lastUnit) => {
                    setActiveUnit(lastUnit);
                })
            }, 1000);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleTabChange = (key) => {
        setActiveTab(key);
        localStorage.setItem('activeTab', key);
    };
    const handleUnitChange = (key) => {
        setActiveUnit(key);
        localStorage.setItem('activeUnit', key);
    };
    const handleTypeChange = (key) => {
        setActiveType(key)
        localStorage.setItem('activeType', key);
    }
    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getStudentAssignment(JSON.parse(auth).token, id);
            getStudentProfile(JSON.parse(auth).token, id);
            const lastUnit = localStorage.getItem('activeUnit');
            const lastType = localStorage.getItem('activeType');
            setActiveUnit(lastUnit);
            setActiveType(lastType);
        } else {
            navigate('/');
        }
    }, []);
    return (
        <>
            <AssessorHeader />
            <section className="sec-1-Student-Profile-Progress">
                <div className="container">
                    <div className="main-sec-1-Student-Profile-Progress">
                        <div className="col-1">
                            <div className="student-image">
                                <img src={studentProfile?.photo} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                            </div>
                            <h4 className="student-name text-sm-center">{studentProfile?.name}</h4>
                        </div>
                        <div className="col-2">
                            <h3 className="Course">Course</h3>
                            <p className="Course-tag">{studentProfile?.course_name}</p>
                        </div>
                        <div className="col-3">
                            <h3 className="student-percentage">{Math.round(studentProfile?.overall_progress)}%</h3>
                            <p className="percentage-tag">Overall Progress</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="custabs">
                <div className="container">
                    <Tabs activeKey={activeTab} onChange={handleTabChange} tabBarStyle={{ display: 'flex', flexDirection: 'column' }}>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={chart} alt="" />Progress Tracker
                            </span>
                        }
                            key="tab1">
                            <div>
                                <StudentProfileProgress id={id} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={clipboard} alt="" />Assignments
                            </span>
                        }
                            key="tab2">
                            <div className="custabs2">
                                <div className="custabs2 subcustabs2 row">
                                    <Tabs activeKey={activeUnit} onChange={handleUnitChange} className="col">
                                        {studentAssignment?.map((item, index) => (
                                            <Tabs.TabPane tab={
                                                <span>
                                                    {item?.row_number}
                                                </span>
                                            }
                                                key={`u-${item.id}`}
                                            >
                                                <div className="assignmentType">
                                                    <Tabs activeKey={activeType} onChange={handleTypeChange}
                                                    >
                                                        <Tabs.TabPane tab={
                                                            <span>
                                                                Cross Referencing
                                                            </span>
                                                        } key="Cross Reference">
                                                            <StudentProfileAssignments
                                                                id={id}
                                                                unitId={item?.id}
                                                                rowNumber={item?.row_number}
                                                                title={item?.title} />
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane tab={
                                                            <span>
                                                                Underpinning Questions
                                                            </span>
                                                        } key="Underpinning Knowledge Questions">
                                                            <StudentProfileAssignments_up
                                                                id={id}
                                                                unitId={item?.id}
                                                                rowNumber={item?.row_number}
                                                                title={item?.title} />
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane tab={
                                                            <span>
                                                                Job Enteries
                                                            </span>
                                                        } key="Job Entry">
                                                            <JobEnteries
                                                                id={id}
                                                                unitId={item?.id}
                                                                rowNumber={item?.row_number}
                                                                title={item?.title} />
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane tab={
                                                            <span>
                                                                Observation Records
                                                            </span>
                                                        }
                                                            key="tab6">
                                                            <div>
                                                                <ObservatoryRecords
                                                                    id={id}
                                                                    unitId={item?.id}
                                                                    rowNumber={item?.row_number}
                                                                    title={item?.title} />
                                                            </div>
                                                        </Tabs.TabPane>
                                                    </Tabs>
                                                </div>
                                            </Tabs.TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={calendar} alt="" />Calendar
                            </span>
                        }
                            key="tab3">
                            <div>
                                <StudentProfileCalendar id={id} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={calendar} alt="" />Programme Assessment
                            </span>
                        }
                            key="tab4">
                            <div>
                                <OverallProgrammeAssessment id={id} />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={calendar} alt="" />Review Comments
                            </span>
                        }
                            key="tab5">
                            <div>
                                <AssessorComments id={id} />
                            </div>
                        </Tabs.TabPane>
                    </Tabs >
                </div >
            </section >
            <Footer />
        </>
    )
}
export default StudentProfile;
