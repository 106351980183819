import React, { useState, useEffect } from "react";
import iconthree from "../images/icon-three.png";
import icontwo from "../images/icon-two.png";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import Select, { components } from 'react-select';
import { useNavigate } from "react-router-dom";
import FeedbackModal from "./FeedbackModal";

const StudentProfileAssignments_up = (props) => {
    const baseurl = process.env.REACT_APP_BASE_URL; const { id, rowNumber, title, unitId } = props;

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const recordsPerPage = 5;
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState("");
    const [feedbackData, setFeedbackData] = useState([]);
    const [CrossReferencingPageData, setCrossReferencingPageData] = useState([]);
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;

    const [grade, setGrade] = useState("")
    const navigate = useNavigate();

    const openFeedbackModal = () => {
        setShowFeedbackModal(true)
    }

    const closeFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const handleProvideFeedbackClick = (index, questionNumber) => {
        index = questionNumber - 1;
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = true;

        // Set the previous feedback value in the editingFeedback state
        updatedCrossReferencingPageData[index].editingFeedback = updatedCrossReferencingPageData[index]?.answers?.feedback[0]?.feedback || '';
        setCrossReferencingPageData(updatedCrossReferencingPageData);
    };

    const handleCancelFeedback = (index, questionNumber) => {
        index = questionNumber - 1
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = false;
        setCrossReferencingPageData(updatedCrossReferencingPageData);

    };

    const handleSaveFeedback = (index, questionNumber) => {
        index = questionNumber - 1
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = false;
        updatedCrossReferencingPageData[index].savedFeedback = updatedCrossReferencingPageData[index].editingFeedback;
        setCrossReferencingPageData(updatedCrossReferencingPageData);
    };

    const handleGradeChange = async (selectedOption, answerId, index, questionNumber) => {
        const selectedGrade = selectedOption?.value;
        setGrade(selectedOption);
        //when changing page number index is also updating
        //when clicking on question 1 of next page question of previous page is referred 
        // so replacing index with questionNumber variable
        index = questionNumber - 1
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].grade = selectedOption;
        updatedCrossReferencingPageData[index].answers.status = selectedOption.value == 1 ? "Passed" : "Failed";
        setCrossReferencingPageData(updatedCrossReferencingPageData);
        gradingData(
            JSON.parse(localStorage.getItem('user')).token,
            selectedGrade,
            answerId
        );
    };


    const getQuestionBarClassName = (grade, answer) => {
        var answerStatusID = answer?.status_id
        if (grade?.value == undefined && answerStatusID == 1) {
            return 'Question-bar pass';
        }
        if (grade?.value == undefined && answerStatusID == 2) {
            return 'Question-bar fail';
        }
        if (grade?.value == 1) {
            return 'Question-bar pass';
        } else if (grade?.value == 2) {
            return 'Question-bar fail';
        }
        return 'Question-bar';
    };

    const getBorderColor = (grade, answerStatusID) => {
        if (grade?.value == undefined && answerStatusID == 1) {
            return "border-success"
        }
        if (grade?.value == undefined && answerStatusID == 2) {
            return "border-danger"
        }
        if (grade?.value == 1) {
            return "border-success"
        } else if (grade?.value == 2) {
            return 'border-danger';
        }
        return "border";
    }

    const options = [
        {
            value: 1,
            label: 'Passed',
            icon: <AiOutlineCheckSquare color="green" style={{ fontSize: "22px" }} />,
            color: "green",
        },
        {
            value: 2,
            label: 'Failed',
            icon: <RxCross2 color="red" style={{ fontSize: "22px" }} />,
            color: 'red',
        },
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'inherit',
            width: '150px',
            color: state.selectProps.value?.color,
            '@media (max-width: 767px)': {
                paddingBottom: '10px',
                fontSize: '10px'
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#0062f4',
            fontWeight: '600'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 'bold',
        }),
    };

    const DropdownIndicator = (props) => {
        return <components.DropdownIndicator {...props} />;
    };

    const getOptionLabel = (option) => (
        <div style={{ color: option.color }}>{option.icon} {option.label}</div>
    );

    const getCrossReferencingData = async (token, unitId, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments-underpinning/${id}/${unitId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }

            result = await result.json();
            const initialCrossReferencingPageData = result?.questions?.map((question) => ({
                isFeedbackOpen: false,
                savedFeedback: '',
                editingFeedback: [],
                grade: null,
                ...question
            }));
            setCrossReferencingPageData(initialCrossReferencingPageData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const gradingData = async (token, selectedGrade, answerId) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments-underpinning-grading/${answerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "status": selectedGrade })
            });

            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const FeedBack = async (token, feedback, answerId, questionNumber) => {
        try {
            // Find the index of the question based on the questionNumber
            const index = questionNumber - 1;
            const updatedCrossReferencingPageData = [...CrossReferencingPageData];
            // updatedCrossReferencingPageData[index].editingFeedback = [{feedback:feedback}];

            updatedCrossReferencingPageData[index].editingFeedback = feedback
            setCrossReferencingPageData(updatedCrossReferencingPageData);
            let result = await fetch(`${baseurl}/api/student-assignments-underpinning-feedback/${answerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "feedback": feedback })
            });
            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }
            result = await result.json()

            // Update the value in the answers object as well
            updatedCrossReferencingPageData[index].answers = result.questions[index].answers;
            setCrossReferencingPageData(updatedCrossReferencingPageData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');

    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getCrossReferencingData(JSON.parse(auth).token, unitId, id);
        } else {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (CrossReferencingPageData.length > 0) {
            setTotalPages(Math.ceil(CrossReferencingPageData.length / recordsPerPage));
        }
    }, [CrossReferencingPageData.length, recordsPerPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <section className="sec-2-Student-Profile-Progress px-0">
                <div className="container px-0">
                    <h2 className="sec-2-heading">{rowNumber}</h2>
                    <p className="sec-2-Underpinning-Questions-tx">{title}</p>

                    {CrossReferencingPageData?.slice(startIndex, endIndex).map((item, index) => {
                        const currentNumber = index + 1;
                        const questionNumber = currentNumber + endIndex - recordsPerPage;

                        return (
                            <div
                                className={`Question-1  ${getBorderColor(item?.grade, item?.answers?.status_id)}`}
                                key={index}>
                                <div className={getQuestionBarClassName(item?.grade, item?.answers)}>
                                    <h4 className="Question">Question {questionNumber} :</h4>
                                    {!item.isFeedbackOpen ? (
                                        <div className="Student-Profile-Assignments-icons-boxes">
                                            <Select
                                                value={`<span>Grade :</span> ${item.grade}`}
                                                onChange={(grade, e) => handleGradeChange(grade, item.answers?.id, index, questionNumber)}
                                                className="grade-dropdown"
                                                styles={customStyles}
                                                isSearchable={false}
                                                placeholder={
                                                    <div className={`${item.answers?.status == "Passed" ? "text-success fw-bold" : item.answers?.status == "Failed" ? "text-danger fw-bold" : "text-primary"}`}>
                                                        {(item.answers?.status == "Passed") ?
                                                            <>
                                                                <AiOutlineCheckSquare style={{ fontSize: "22px" }} />
                                                                <span className="mx-1">Grade:</span>
                                                            </>
                                                            :
                                                            (item.answers?.status == "Failed") ?
                                                                <>
                                                                    <RxCross2 style={{ fontSize: "22px" }} />
                                                                    <span className="mx-1">Grade:</span>
                                                                </>
                                                                :
                                                                <>

                                                                    <img src={iconthree} className="selectimage" alt="" />
                                                                    <span className="mx-1">Grade: </span>
                                                                </>
                                                        }
                                                        {item.grade ?  item.grade.label : (item.answers?.status)}
                                                    </div>
                                                }
                                                style={{ border: "none", outline: "none", backgroundColor: "inherit", }}
                                                options={options}
                                                components={{ DropdownIndicator }}
                                                getOptionValue={(option) => option?.value}
                                                getOptionLabel={getOptionLabel}
                                            />
                                            {
                                                <div className="save-icon-box">
                                                    {item?.answers == null || item?.answers?.feedback?.length == 0 ? (
                                                        <div className="save-icon-box" >
                                                            <div className="save-icon mt-sm-2" onClick={() => handleProvideFeedbackClick(index, questionNumber)}>
                                                                <img src={icontwo} alt="" />
                                                                <p className="save-tx  mx-2" >Provide Feedback</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="save-icon-box mt-sm-1" >
                                                            <div className="save-icon" onClick={() => handleProvideFeedbackClick(index, questionNumber)}>
                                                                <img src={icontwo} alt="" />
                                                                <p className="save-tx mx-2" >Edit Feedback</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                    ) : (
                                        <div className="save_cancel">
                                            <div className="save-icon-box" onClick={() => handleCancelFeedback(index, questionNumber)}>
                                                <p className="save-tx">Cancel</p>
                                            </div>

                                            <div className="save-icon-box" onClick={() => handleSaveFeedback(index, questionNumber)}>
                                                <p className="save-tx"
                                                    onClick={() => {
                                                        FeedBack(
                                                            JSON.parse(localStorage.getItem('user')).token,
                                                            item.editingFeedback,
                                                            item.answers?.id,
                                                            questionNumber
                                                        )
                                                    }}>Save</p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="type-bar">
                                    <h3 className="type-bar-heading fw-normal" dangerouslySetInnerHTML={{ __html: item?.question }} />
                                    <br />
                                    <h4 className="Answer">Answer:</h4>
                                    {item?.answers ?
                                        (<>
                                            <h3 className="type-bar-heading-2">{item?.answers?.answer}</h3>
                                        </>
                                        ) :
                                        (<h5 className="text-secondary fw-normal mx-4 mb-1">
                                            N/A
                                        </h5>)}

                                    {item?.sub_questions ? (
                                        <>
                                            {item?.sub_questions.map((item, index) => (
                                                <>
                                                    <h3 className="type-bar-heading" key={index}><span className="text-primary">({alphabets[index]})</span> {item?.question}</h3>
                                                    <h4 className="Answer">Answer:</h4>
                                                    {item?.answers ?
                                                        (<>
                                                            <h3 className="type-bar-heading-2">{item?.answers}</h3>
                                                        </>
                                                        ) :
                                                        (<h5 className="text-secondary fw-normal mx-4 mb-1">
                                                            N/A
                                                        </h5>)}                                                </>
                                            ))}
                                        </>) :
                                        null
                                    }
                                </div>

                                {
                                    item?.isFeedbackOpen && (
                                        <div className="FeedBackInputContainer">
                                            <input
                                                type="text"
                                                className="FeedBackInput"
                                                value={item?.editingFeedback}
                                                onChange={(e) => {
                                                    const updatedCrossReferencingPageData = [...CrossReferencingPageData];
                                                    updatedCrossReferencingPageData[questionNumber - 1].editingFeedback = e.target.value;
                                                    setCrossReferencingPageData(updatedCrossReferencingPageData);
                                                }}
                                                placeholder="Enter feedback"
                                            />
                                        </div>
                                    )
                                }
                                {
                                    !item?.isFeedbackOpen && item?.answers && item?.answers?.feedback?.length != 0 && (
                                        <div>
                                            <h6 className="MyFeedBackHeading">My Feedback:</h6>
                                            <p className="MyFeedBack mb-2">{item?.answers?.feedback[0]?.feedback}</p>
                                            <div className="d-flex justify-content-end mt-0 m-4 ">
                                                <p className="text-primary cursor-pointer"
                                                    onClick={() => {
                                                        openFeedbackModal()
                                                        setSelectedQuestion(item?.question);
                                                        setFeedbackData(item?.answers?.feedback)
                                                    }
                                                    }>View All Feedbacks</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        );
                    })}
                    <ul className="pagination">
                        {currentPage !== 1 && (
                            <li className="page-item">
                                <a className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                    &lt;
                                </a>
                            </li>
                        )}
                        {Array.from({ length: totalPages }, (_, index) => (
                            <>
                                <li
                                    className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                    key={index + 1}
                                >
                                    <a className="page-link" onClick={() => handlePageChange(index + 1)}>
                                        {index + 1}
                                    </a>
                                </li>
                            </>
                        ))}
                        {currentPage !== totalPages && (
                            <li className="page-item">
                                <a className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                    &gt;
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                <FeedbackModal showModal={showFeedbackModal} Question={selectedQuestion} data={feedbackData} handleClose={closeFeedbackModal} />
            </section >
        </>
    );
};

export default StudentProfileAssignments_up;