import React, { useEffect, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";

const StudentProfileProgress = (props) => {

    const baseurl = process.env.REACT_APP_BASE_URL;
    const [studentData, setStudentData] = useState([])
    const [selectedValues, setSelectedValues] = useState([]);
    const [modal, setModal] = useState()
    const id = props.id

    const closeModal = () => {
        const auth = localStorage.getItem('user');
        setModal(false);
        getStudentData(JSON.parse(auth).token);
    };

    const getStudentData = async (token) => {

        try {
            let result = await fetch(`${baseurl}/api/student-progress-tracker/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch dashboard data');
            }

            result = await result.json();
            setStudentData(result);
            setSelectedValues(result.map(item => item.progress));

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const updateProgress = async (token) => {
        try {
            const requestBody = {
                data: selectedValues.map((value, index) => ({
                    id: studentData[index].id,
                    row_number: studentData[index].row_number,
                    title: studentData[index].title,
                    progress: parseInt(value, 10)
                }))
            };
            let result = await fetch(`${baseurl}/api/update-student-progress/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)

            });

            if (!result.ok) {
                throw new Error('Failed to fetch student data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleUpdateProgress = () => {
        const auth = localStorage.getItem('user');
        if (auth) {
            updateProgress(JSON.parse(auth).token);
            setModal(true)
        } else {
            console.log("error")
        }
    };

    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getStudentData(JSON.parse(auth).token);
        } else {
            console.log("error")
        }
    }, []);

    const handleSelectChange = (event, index) => {
        const newValue = parseInt(event.target.value);
        const minValue = 0
        const maxValue = 100
        if (!isNaN(newValue) && newValue >= minValue && newValue <= maxValue) {
            event.target.value = newValue
            setSelectedValues(prevValues => {
                const newValues = [...prevValues];
                newValues[index] = newValue;
                return newValues;
            });
        }
        else if (newValue <= minValue || isNaN(newValue)) {
            event.target.value = 0
            setSelectedValues(prevValues => {
                const newValues = [...prevValues];
                newValues[index] = 0;
                return newValues;
            });
        }
    }

    return (
        <>
            <section className="sec-2-Student-Profile-Progress">
                <div className="container px-0">
                    <div className="main-sec-2-Student-Profile-Progress">
                        {
                            studentData?.map((item, index) => (
                                <div className="main-1-Student-Profile-Progress" key={index}>
                                    <div className="unit-progress">

                                        <h4 className="unit-progress-heading">Unit {item?.row_number}</h4>
                                        <p className="unit-progress-tx">{item?.title} </p>
                                    </div>
                                    <input value={selectedValues[index]} className="progress-tracker-input" onChange={(event) => handleSelectChange(event, index)} type="number" />
                                </div>
                            ))}
                        <hr></hr>
                    </div>
                    <button type="submit" className="btns1" onClick={handleUpdateProgress}> Update</button>
                </div>
                {
                    modal && (
                        <ConfirmationModal content="Progress Updated Successfully" onButtonClick={closeModal} />
                    )
                }
            </section>
        </>
    )
}
export default StudentProfileProgress;