import React from 'react'
import Header from './Header'

function TermsAndServices() {
    return (
        <>
            <div>
                <Header />
                <div className='termsandservices'>
                    <h2>TERMS OF SERVICE</h2>
                    <p>Please read these Terms of Service carefully before using the Onwards &amp; Upwards Training LTD (O.U.T) website (the "Service") operated by us. These Terms include the conditions for users who want to access and use our services and apply to all users, registered or just visiting the site.</p>
                    <h4>1. Acceptance of Terms</h4>
                    <p>By accessing and/or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
                    <h4>2. Registration</h4>
                    <p>To use our Service, you may need to register and provide certain information about yourself. You agree to: (a) provide true, accurate, current, and complete information as prompted by the Service's registration form and (b) maintain and promptly update the information to keep it true, accurate, current, and complete.</p>
                    <h4>3. User Responsibility</h4>
                    <p>You are solely responsible for the activity that occurs on your account and for the security of your computer system. You must notify us immediately of any breach of security or unauthorized use of your account.</p>
                    <h4>4. Content Responsibility</h4>
                    <p>You are solely responsible for the files and information that you upload, post, publish, display, or link to the Service or send other users. You must ensure that the content provided is accurate, not misleading, and you have all the necessary rights to publish that content.</p>
                    <h4>5. Prohibited Conduct</h4>
                    <p>You agree not to use the Service to upload, post, or otherwise transmit any content that is offensive, contains harassment, hate speech, defamatory, or violates any laws or regulations.</p>
                    <h4>6. Intellectual Property Rights</h4>
                    <p>All contents of the Service are: Copyright © Onwards &amp; Upwards Training LTD. All rights reserved. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without express written permission by us.</p>
                    <h4>7. Termination</h4>
                    <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
                    <h4>8. Changes to Terms</h4>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will strive to provide at least 30 days' notice prior to any new terms taking effect.</p>
                    <h4>9. Governing Law</h4>
                    <p>These Terms shall be governed and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.</p>
                    <p>This document was last updated on July 9, 2023.</p>
                </div>
            </div>
        </>
    )
}

export default TermsAndServices