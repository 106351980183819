import { React, useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/Context';

const Header = () => {
    let { state, dispatch } = useContext(GlobalContext);
    const location = useLocation();
    const username = state?.user?.name;
    const userAvatar = state?.user?.image;
    const role = state?.user?.role
    const navigate = useNavigate();

    const selectLearner = (learnerId, learnerName) => {
        dispatch({
            type: "QA_SELECTED_LEARNER",
            payload: {
                qaSelectedLearner: learnerId,
                qaSelectedLearnerName: learnerName
            }
        })
    }

    const back = () => {
        if (location.pathname.includes("chat")) {
            navigate("/")
        }
        else {
            navigate(-1)
        }
    }

    return (
        <header>
            <div className="main-header">
                <div className="back-button" onClick={() => back()} style={{ cursor: "pointer" }}>
                    <div className="">
                        <IoArrowBack style={{ color: "#0163f4", fontSize: "24px" }} />
                    </div>
                    <p className="back-button-tx">Back</p>
                </div>

                <div className="d-flex align-items-center">
                    {role === 5 &&
                        <div>
                            <div >
                                <select onChange={(e) => selectLearner(e.target.value, e.target.options[e.target.selectedIndex].text)} className="rounded p-1">
                                    <option value="" className="text-secondary" >Select Learner</option>
                                    {state?.qaLearners?.map((item, index) =>
                                        <option key={index} value={item?.value}>{item?.label}</option>
                                    )}
                                </select>

                            </div>
                        </div>}

                    <div className="header-side-two">
                        <div className="user-info">
                            <div className="user-img">
                                <img src={userAvatar} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                            </div>
                            <div className="user-name-id">
                                <h3 className="user-name">{username}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header;