import React from 'react'
import Header from './Header'

function Privacy() {
    return (
        <>
            <div>
                <Header />
                <div className='termsandservices'>
                    <h2>PRIVACY POLICY</h2>
                    <p>This Privacy Policy delineates how Onwards &amp; Upwards Training LTD (O.U.T) (collectively known as "the Site"), a specialised NVQ Level 3 Lift Engineering Assessment company, collects, utilises, maintains, and discloses information collected from users (each, a "User"). This Policy adheres to the UK General Data Protection Regulation (UK GDPR). Please thoroughly read this Privacy Policy to comprehend our practices regarding your information and how we treat it.</p>
                    <h4>1. Personal Data</h4>
                    <p>We may collect personal data from Users through various methods, including, but not limited to, when Users visit our site, register on the site, subscribe to a newsletter, and in connection with other activities, services, features, or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, phone number. We will collect personal data from Users only if they voluntarily submit such information to us.</p>
                    <h4>2. Non-Personal Data</h4>
                    <p>We may collect non-personal data about Users whenever they interact with our Site. This non-personal data may include the browser name, the type of computer, and technical information about Users' means of connection to our Site, such as the operating system, the Internet service providers utilised, and other similar information.</p>
                    <h4>3. Cookies</h4>
                    <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
                    <h4>4. Usage of Collected Data</h4>
                    <p>The Site may collect and use Users' personal data for the following purposes:</p>
                    <ul>
                        <li>To improve customer service</li>
                        <li>To personalise user experience</li>
                        <li>To improve our Site</li>
                        <li>To send periodic emails, if consented to by the User.</li>
                    </ul>

                    <h4>5. Data Protection</h4>
                    <p>We adopt appropriate data collection, storage, processing practices, and security measures to protect against unauthorised access, alteration, disclosure, or destruction of your personal data, username, password, transaction information, and data stored on our Site.</p>

                    <h4>6. Sharing Personal Data</h4>
                    <p>We do not sell, trade, or rent Users' personal data to others. We may share generic aggregated demographic information not linked to any personal data regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

                    <h4>7. Your Rights</h4>
                    <p>Under the UK GDPR, you have a number of rights, including:</p>
                    <ul>
                        <li>The right to be informed about our collection and use of your personal data</li>
                        <li>The right to access the personal data we hold about you</li>
                        <li>The right to have your personal data rectified if it is inaccurate or incomplete</li>
                        <li>The right to erasure (also known as the ‘right to be forgotten’)</li>
                        <li>The right to restrict processing of your personal data</li>
                        <li>The right to data portability</li>
                        <li>The right to object to processing of your personal data.</li>
                    </ul>

                    <h4>8. Changes to This Privacy Policy</h4>
                    <p>We have the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal data we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

                    <h4>9. Your Acceptance of These Terms</h4>
                    <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
                    <p>This document was last updated on July 9, 2023.</p>
                </div>
            </div>
        </>)
}

export default Privacy