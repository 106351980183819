import React from 'react';

function SplashScreen() {
  return (
    <div className="splash-screen">
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default SplashScreen;
