import Pusher from 'pusher-js';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import "../ChatApp.css";
import Header from './Header';
import UserList from './UserList';
import null_image from "../images/nullimage.png"
function Chat() {
    const { userid } = useParams()
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [bagdeCounter, setBagdeCounter] = useState([])
    const baseurl = process.env.REACT_APP_BASE_URL;
    const auth = localStorage.getItem("user");
    const token = JSON.parse(auth).token
    var pusher;
    var recipientId = useRef([])
    const messageContainerRef = useRef(null);
    const handleSendMessage = async (e) => {
        e.preventDefault()
        await sendMessages()
        setNewMessage('')
    };

    const getMessages = async () => {
        let result = await fetch(`${baseurl}/api/get-messages/${userid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json()
        setMessages(result)
    }

    const sendMessages = async () => {
        let result = await fetch(`${baseurl}/api/post-message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ to_user_id: userid, message: newMessage })
        });
    }

    var channelName = []

    const updateBadgeCount = (userId) => {
        setBagdeCounter((prevBadgeCounter) => ({
            ...prevBadgeCounter,
            [userId]: (prevBadgeCounter[userId] || 0) + 1,
        }));
    };

    const PusherNotifications = async () => {

        let result = await fetch(`${baseurl}/api/get-recipient-ids`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        result = await result.json()
        Pusher.logToConsole = false;
        pusher = new Pusher('4a0829c13e55b642e1ae', {
            cluster: 'eu'
        });
        recipientId.current.values = result?.channelNames
        setBagdeCounter(result?.unreadCounts)
        result?.channelNames?.map((item, index) => {
            channelName.push(pusher.subscribe(`chat-channel-${item}`))
            channelName[index]?.bind('message', function (data) {
                setMessages((prevMessages) => [...prevMessages, data.data])
                const senderUserId = data.data.from_user_id;
                console.log(data.data);
                updateBadgeCount(senderUserId)
            })
        })

    }

    useEffect(() => {
        PusherNotifications()
        setMessages([])
        getMessages()

        setTimeout(function () {
            document.getElementById('chat-container')?.scrollTo(0, document.getElementById("chat-container").scrollHeight);
        }, 1000);

        return () => {
            recipientId.current?.values?.map((item, index) => {
                channelName[index].unbind('message');
                channelName[index].unsubscribe(`chat-channel-${item}`);
                channelName[index].disconnect();
            })
        };
    }, [userid])


    useEffect(() => {
        setTimeout(function () {
            document.getElementById('chat-container')?.scrollTo(0, document.getElementById("chat-container").scrollHeight);
        }, 1000);
    }, [messages])

    return (
        <>
            <div>
                <div className="chat-app">
                    <UserList counter={bagdeCounter} />
                    <div className="specific-chat">
                        <div className="message-container">
                            <Header />
                            {userid != 0 &&
                                <>
                                    <div id='chat-container' className="message-list" ref={messageContainerRef}>
                                        {messages?.map((item, index) => (
                                            <div id="" className="message-box" ref={messageContainerRef}>
                                                <div className={item?.to_user_id == userid ? `sender` : `recipient`}>
                                                    <div className="d-flex gap-2 my-2">
                                                        <div>
                                                            <img src={item?.fromuser?.image} className='chat-sender-image' />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='m-0'>{item?.fromuser?.name}</p>
                                                        </div>
                                                    </div>
                                                    <div key={index} className={`message`}>
                                                        {item?.message}
                                                    </div>
                                                    <div style={{ fontSize: "12px" }}>
                                                        {new Date(item?.created_at).toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <form onSubmit={handleSendMessage}>
                                        <div className="message-input">
                                            <input
                                                type="text"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                placeholder="Type your message..."
                                            />
                                            <button type='submit'>Send</button>
                                        </div>
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat