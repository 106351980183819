import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const CalendarModal_Edit = ({ showModal, handleClose, handleSave, calendarData }) => {
  const baseurl = process.env.REACT_APP_BASE_URL
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [personalNote, setPersonalNote] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (calendarData) {
      setType(calendarData.category);
      setTitle(calendarData.title);
      setDate(calendarData.date);
      setPersonalNote(calendarData.note);
    }
  }, [calendarData]);

  const handleSaveChanges = async () => {
    const updatedCalendar = {
      ...calendarData,
      title: title,
      date: date,
      note: personalNote
    };

    const auth = localStorage.getItem("user");
    var token = JSON.parse(auth).token
    try {
      let result = await fetch(`${baseurl}/api/calendar/${calendarData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(updatedCalendar)
      });

      if (!result.ok) {
        throw new Error('Failed to fetch dashboard data');
      }

    } catch (error) {
      console.error('Error:', error);
    }


    handleSave(updatedCalendar);
    handleClose();
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='text-primary d-flex justify-content-center'>
          Edit New Schedule
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='CalendarModal'>
          {type === 'Call' ? (
            <div className=' row gx-1'>
              <div className='col'>
                <p>Date</p>
                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="CalendarInputBox DateInput" />
              </div>
              <div className='col'>
                <p>Time</p>
                <input
                  type='time'
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className='CalendarInputBox DateInput'
                />
              </div>

            </div>
          ) : (
            <div>
              <p>Date</p>
              <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="CalendarInputBox" />
            </div>
          )}

          <div>
            <p>Title</p>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className='CalendarInputBox' />
          </div>
          <div>
            <p>Personal Note</p>
            <textarea className="CalendarInputBox" value={personalNote} onChange={(e) => setPersonalNote(e.target.value)}></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSaveChanges}>
          Edit Task
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarModal_Edit;
