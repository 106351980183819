import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { GlobalContext } from '../context/Context';
import note from "../images/note.png";
import ticksquare from "../images/tick-square.png";
import ConfirmationModal from "./ConfirmationModal";
import FeedBackModal from "./FeedbackModal";
import Footer from "./Footer";
import Header from "./Header";
import SupportFooter from "./SupportFooter";

const UnderpinningQuestions = () => {
  const baseurl = process.env.REACT_APP_BASE_URL
  const [modal, setModal] = useState(false);
  const textAreaRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [UnderpinningQuestions, setUnderpinningQuestions] = useState([]);
  const [allAnswersPassed, setAllAnswersPassed] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const recordsPerPage = 5;
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;

  let { state, dispatch } = useContext(GlobalContext);
  const role = state?.user?.role
  const qaSelectedLearner = state?.qaSelectedLearner

  const openFeedbackModal = () => {
    setShowFeedbackModal(true)
  }

  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const auth2 = localStorage.getItem("user");
  useEffect(() => {
    const auth = localStorage.getItem("user");
    if (auth) {
      getUnderpinningQuestions(JSON.parse(auth).token);
    } else {
      navigate("/");
    }
  }, [qaSelectedLearner]);

  const getUnderpinningQuestions = async (token) => {

    let result = await fetch(`${baseurl}/api/underpinning-questions?course_id=${params.id}${role === 5 ? '&user_id=' + qaSelectedLearner : ''}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    result = await result.json();

    for (let i = 0; i < result.questions.length; i++) {
      if (result.questions[i].answers == null) {
        result.questions[i].answers = {
          answer: "",
        }
      }
    }
    setUnderpinningQuestions(result);
    for (let i = 0; i < result?.questions?.length; i++) {
      if (result.questions[i].answers?.status == "Passed" || result.questions[i].answers?.status == "Pending") {
        setAllAnswersPassed(true);
      }
      else {
        setAllAnswersPassed(false);
        break;
      }
    }
  };

  const closeModal = () => {
    setModal(false);
    getUnderpinningQuestions(JSON.parse(auth2).token);
  };

  const submitAnswers = async () => {
    var temp = [...UnderpinningQuestions.questions]
    var postData = []

    for (let i = 0; i < temp.length; i++) {
      if (temp[i].answers.answer.length == 0) {
        alert("Please Answer Question " + (i + 1));
        setIsSaving(false);
        return;
      }
      var filtered = temp[i].answers.answer;

      if (temp[i].answers.status !== "Passed") {
        postData.push({
          "question_id": temp[i].id,
          "answer": filtered,
          "status": 0
        });
      }
    }

    const auth2 = localStorage.getItem("user");
    const token = JSON.parse(auth2).token;

    let result = await fetch(`${baseurl}/api/under-pinning-answer${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
      method: "POST",
      body: JSON.stringify({ "answers": postData }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    });
    result = await result.json();
    if (result) {
      setModal(true);
    }
  }

  useEffect(() => {
    if (UnderpinningQuestions?.questions?.length > 0) {
      setTotalPages(Math.ceil(UnderpinningQuestions?.questions?.length / recordsPerPage));
    }
  }, [UnderpinningQuestions, recordsPerPage]);

  const [isSaving, setIsSaving] = useState(false);

  const saveQuestion = async (item, cr_index, questionNumber, event) => {
    cr_index = questionNumber - 1
    if (item.answers?.status == "Edit") {
      var underpinning = { ...UnderpinningQuestions };
      underpinning.questions[cr_index].answers.status = "Save";
      setUnderpinningQuestions({ ...underpinning });
      return;
    }
    setIsSaving(true);
    const auth = localStorage.getItem("user");
    let result = await fetch(`${baseurl}/api/save-answer`, {
      method: "POST",
      body: JSON.stringify({ "questions": item, "type": 1 }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${JSON.parse(auth).token}`
      },
    });
    if (result.ok) {
      setIsSaving(false);
      var underpinning = { ...UnderpinningQuestions };
      underpinning.questions[cr_index].answers.status = "Edit";
      setUnderpinningQuestions({ ...underpinning });
    }
    else {
      alert("Answer Field is Empty")
      setIsSaving(false);
    }
  };

  const getQuestionBarClassName = (answerStatusID) => {
    var answerStatusID
    if (answerStatusID == 1) {
      return 'Question-bar pass';
    }

    if (answerStatusID == 2) {
      return 'Question-bar fail';
    }

    return 'Question-bar';
  };

  const getBorderColor = (answerStatusID) => {
    if (answerStatusID == 1) {
      return "border-success"
    }
    if (answerStatusID == 2) {
      return "border-danger"
    }
    return "border";
  }

  return (
    <>
      <Header />
      <section className="banner-2">
        <div className="main-banner">
          <div className="unit-1">
            <div className="unit-icon">
              <img src={note} alt="" />
            </div>
            <p className="unit-1-tx">
              Unit{UnderpinningQuestions?.course?.unit_number}
            </p>
          </div>
          <h2 className="unit-1-heading">
            {UnderpinningQuestions?.course?.title}
          </h2>
        </div>
      </section>

      <section className="sec-two-Underpinning-Questions">
        <div className="container">
          <h2 className="sec-2-heading">Knowledge Learning Outcomes</h2>
          <p className="sec-2-Underpinning-Questions-tx text-capitalize">
            {UnderpinningQuestions?.course?.underpinning_knowledge_outcome}
          </p>
          <br></br>
          {UnderpinningQuestions?.questions?.slice(startIndex, endIndex).map((item, index) => {
            const currentNumber = index + 1;
            const questionNumber = currentNumber + endIndex - recordsPerPage;
            return (
              <>
                <div
                  key={index}
                  className={`Question-1 ${item?.answers?.answer && "disabled-div"} ${getBorderColor(item?.answers?.status_id)}`}
                >
                  <div className={getQuestionBarClassName(item?.answers?.status_id)}>
                    <h4 className="Question">Question {questionNumber} :</h4>

                    <div className="save-icon-box">
                      {(item.answers.status == "Passed" || item.answers.status == "Failed" || item.answers.status == "Pending") ?
                        (
                          <>
                            <div className="save-icon">
                              {item.answers.status == "Passed" ?
                                <AiOutlineCheckSquare style={{ color: "green", fontSize: "20px" }} />
                                :
                                item.answers.status == "Failed" ?
                                  <RxCross2 style={{ color: "red", fontSize: "20px" }} />
                                  :
                                  <img src={ticksquare} alt="" />
                              }
                            </div>

                            <button disabled={isSaving} style={{
                              border: "none",
                              background: "none",
                              color: "none",
                              fontFamily: 'Lato',
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "0 0 0 0",
                              color: "#0062f478",
                            }}
                              className={`${item.answers.status == "Passed" ? 'text-success' : item.answers.status == "Failed" ? "text-danger" : "text-primary"}`}
                            >
                              {item.answers.status}
                            </button>
                          </>
                        )

                        : null
                      }

                      {(item.answers.status == "Edit" || item.answers.status == "Failed") ?
                        (<>
                          <div className="save-icon">
                            <img src={ticksquare} alt="" />
                          </div>

                          {role == 4 ?
                            <button disabled={isSaving} style={{
                              border: "none",
                              background: "none",
                              color: "none",
                              fontFamily: 'Lato',
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "0 0 0 0",
                              color: "#0062f478",
                            }}
                              onClick={(event) => {
                                saveQuestion(item, index, questionNumber, event);
                              }}
                            >
                              {
                                isSaving &&
                                <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
                              }
                              {item.answers.status == "Edit" ? item.answers.status : "Save"}
                            </button>
                            :
                            <button disabled={isSaving} style={{
                              border: "none",
                              background: "none",
                              color: "none",
                              fontFamily: 'Lato',
                              fontSize: "16px",
                              fontWeight: "bold",
                              margin: "0 0 0 0",
                              color: "#0062f478",
                            }}
                            >
                              {}
                              Save
                            </button>
                          }
                        </>
                        )
                        :
                        !item.answers.status || item.answers.status == "Edit" || item.answers.status == "Save" ?
                          <>
                            <div className="save-icon">
                              <img src={ticksquare} alt="" />
                            </div>

                            {role == 4 ?
                              <button disabled={isSaving} style={{
                                border: "none",
                                background: "none",
                                color: "none",
                                fontFamily: 'Lato',
                                fontSize: "16px",
                                fontWeight: "bold",
                                margin: "0 0 0 0",
                                color: "#0062f478",
                              }}
                                onClick={(event) => {
                                  saveQuestion(item, index, questionNumber, event);
                                }}
                              >
                                {
                                  isSaving &&
                                  <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
                                }
                                {item.answers.status == "Edit" ? item.answers.status : "Save"}
                              </button>
                              :
                              <button style={{
                                border: "none",
                                background: "none",
                                color: "none",
                                fontFamily: 'Lato',
                                fontSize: "16px",
                                fontWeight: "bold",
                                margin: "0 0 0 0",
                                color: "#0062f478",
                              }}
                              >
                                Save
                              </button>
                            }
                          </>
                          :
                          null
                      }

                    </div>
                  </div>

                  <div className="type-bar">
                    <h3 className="type-bar-heading mb-3">{item?.question}</h3>

                    <TextareaAutosize
                      disabled={item?.answers?.status == "Passed" || item?.answers?.status == "Pending" || item?.answers?.status == "Edit" ? true : false}
                      ref={textAreaRef}
                      placeholder="your answer here..."
                      onChange={(event) => {
                        var newAnswer = { ...UnderpinningQuestions };
                        newAnswer.questions[questionNumber - 1].answers.answer = event.target.value;
                        setUnderpinningQuestions(newAnswer);
                      }}
                      value={UnderpinningQuestions?.questions[questionNumber - 1]?.answers?.answer}
                      name={`answer` + item?.id}
                      className={`${item?.answers?.status == "Passed" || item?.answers?.status == "Pending" || item?.answers?.status == "Edit" ? "border-0" : "border"} auto-resize-textbox__input`}
                      required
                    >
                    </TextareaAutosize>

                    {(item?.answers != null && item?.answers?.feedback && item?.answers?.feedback.length != 0) ?

                      <div>
                        <h6 className="MyFeedBackHeading">Assessor's Feedback:</h6>
                        <p className="MyFeedBack mb-2">{item?.answers?.feedback[0]?.feedback}</p>
                        <div className="d-flex justify-content-end mt-0 m-4 ">
                          <p className="text-primary cursor-pointer" onClick={() => {
                            openFeedbackModal()
                            setSelectedQuestion(item?.question);
                            setFeedbackData(item?.answers?.feedback)
                          }
                          }>View All Feedbacks</p>
                        </div>

                      </div>
                      :
                      null
                    }

                  </div>
                </div>
              </>)
          })}

          <ul className="pagination">
            {currentPage !== 1 && (
              <li className="page-item">
                <a className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                  &lt;
                </a>
              </li>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <>
                <li
                  className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                  key={index + 1}
                >
                  <a className="page-link" onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </a>
                </li>
              </>
            ))}
            {currentPage !== totalPages && (
              <li className="page-item">
                <a className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                  &gt;
                </a>
              </li>
            )}
          </ul>

          {role == 4 ?
            <button disabled={isSaving || allAnswersPassed} type="submit" className={`btns1 ${allAnswersPassed ? "grayed-out-button" : ""}`} onClick={submitAnswers} title={allAnswersPassed ? 'All answers are answered' : ''}
            >
              {
                isSaving &&
                <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
              }
              Submit for Grading
            </button>
            :

            <button type="submit" className="btns1" style={{ backgroundColor: "gray", cursor: "not-allowed" }}
            >
              Submit for Grading
            </button>}

        </div>
        <FeedBackModal showModal={showFeedbackModal} data={feedbackData} Question={selectedQuestion} handleClose={closeFeedbackModal} />
      </section >
      <SupportFooter />
      {
        modal && (
          <ConfirmationModal content="Answer Submitted Successfully" onButtonClick={closeModal} />
        )
      }
      <Footer />
    </>
  );
};

export default UnderpinningQuestions;
