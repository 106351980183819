import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import note from "../images/note.png";
import edit from "../images/edit.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Col, Row } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import SupportFooter from "./SupportFooter";
import ConfirmationModal from "./ConfirmationModal";
import FeedbackModal from "./FeedbackModal";
import { GlobalContext } from '../context/Context';
import { useContext } from "react";

const CrossReferencing = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const params = useParams();
    const [CrossReferencing, setCrossReferencing] = useState([]);
    const [courses, setCourses] = useState([]);
    const [JobAssessmentRecord, setJobAssessmentRecord] = useState([]);
    const [activeJobs, setActiveJobs] = useState([]);
    const [modal, setModal] = useState(false);
    const [allAnswersPassed, setAllAnswersPassed] = useState(false)
    const [show, setShow] = useState(false);
    const [crIndex, setCrIndex] = useState(null);
    const [sqIndex, setSqIndex] = useState(null);
    const [activeUnit, setActiveUnit] = useState(null);
    const [activeJob, setActiveJob] = useState(null);
    const [unitList, setUnitList] = useState(false);
    const [jobList, setJobList] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [sections, setSections] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState("");
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackData, setFeedbackData] = useState([]);
    let { state, dispatch } = useContext(GlobalContext);

    const role = state?.user?.role
    const qaSelectedLearner = state?.qaSelectedLearner

    const auth = localStorage.getItem('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (auth) {
            getCrossReferencing(JSON.parse(auth).token);
            getCourses(JSON.parse(auth).token);
            getJobAssessmentRecord(JSON.parse(auth).token);
        } else {
            navigate('/');
        }

    }, [qaSelectedLearner]);

    const openFeedbackModal = () => {
        setShowFeedbackModal(true)
    }

    const closeFeedbackModal = () => {
        setShowFeedbackModal(false);
    };

    const closeModal = () => {
        setModal(false);
        getCrossReferencing(JSON.parse(auth).token);
    };

    const getCrossReferencing = async (token) => {

        let result = await fetch(`${baseurl}/api/cross-referencing?course_id=${params.id}${role === 5 ? '&user_id=' + qaSelectedLearner : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();

        setActiveJobs(result.course.jobs);

        for (let i = 0; i < result.questions.length; i++) {
            if (result.questions[i].answers == null) {
                result.questions[i].answers = {
                    answer: [
                    ]
                }
            }
            for (let j = 0; j < result.questions[i].sub_questions.length; j++) {
                if (result.questions[i].sub_questions[j].answers == null) {
                    result.questions[i].sub_questions[j].answers = {
                        answer: [
                        ]
                    }
                }
            }
        }
        setActiveUnit(result.course);
        setCrossReferencing(result);
        setIsSaving(false);
        for (let i = 0; i < result?.questions?.length; i++) {
            if (result.questions[i].answers?.status == "Passed" || result.questions[i].answers?.status == "Pending") {
                setAllAnswersPassed(true);
            }
            else {
                setAllAnswersPassed(false);
                break;
            }
        }
    }
    const getCourses = async (token) => {
        let result = await fetch(`${baseurl}/api/my-courses${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setCourses(result);
    }
    const getJobAssessmentRecord = async (token) => {

        let result = await fetch(`${baseurl}/api/job-records${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setJobAssessmentRecord(result);
    }

    const formSubmitHandler = (event) => {
        setIsSaving(true);
        event.preventDefault();
        var temp = [...CrossReferencing.questions];
        var postData = [];

        let submit = true;

        for (let i = 0; i < temp.length; i++) {

            if (temp[i].answers.answer.length == 0 && temp[i].sub_questions.length == 0) {
                alert("Please Answer Question " + (i + 1));
                setIsSaving(false);
                return;
            }

            var filtered = temp[i].answers.answer;

            if (temp[i].answers?.status !== "Passed") {
                postData.push({
                    "question_id": temp[i].id,
                    "data": filtered,
                    "status": 0
                });
            }

            for (let j = 0; j < temp[i].sub_questions.length; j++) {
                if (temp[i].sub_questions[j].answers.answer.length == 0) {
                    alert("Please Answer Question " + (i + 1) + " Part " + Number(j + 10).toString(36).toLowerCase());
                    setIsSaving(false);
                    return;
                }
                var filtered = temp[i].sub_questions[j].answers.answer;
                if (temp[i].answers?.status !== "Passed") {
                    postData.push({
                        "question_id": temp[i].sub_questions[j].id,
                        "data": filtered,
                        "status": 0
                    });
                }
            }
        }
        const auth2 = localStorage.getItem("user");
        const token = JSON.parse(auth2).token;

        if (auth2) {
            handellogin(postData, token);
        }
    };


    const handellogin = async (payload, token) => {
        let result = await fetch(`${baseurl}/api/cross-reference-answer`, {
            method: "POST",
            body: JSON.stringify({ "answers": payload }),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        });
        result = await result.json();
        getCrossReferencing(token);

        if (result) {
            setModal(true);

        }

    };

    const addEntry = () => {

        var cr_questions = { ...CrossReferencing };

        if (sqIndex > -1 && sqIndex != null) {
            cr_questions.questions[crIndex].sub_questions[sqIndex].answers.answer.push({
                "course_id": activeUnit.id,
                "course_name": activeUnit.name,
                "job_record_id": activeJob.id,
                "job_record_name": activeJob.job_title,
                "job_record_data": activeJob.job_title,
                "sections": sections,
                "FileLink": activeJob.file,
            });
        }
        else {
            cr_questions.questions[crIndex].answers.answer.push({
                "course_id": activeUnit.id,
                "course_name": activeUnit.name,
                "job_record_id": activeJob.id,
                "job_record_name": activeJob.job_title,
                "sections": sections,
            });
        }
        setCrossReferencing({ ...cr_questions });

        handleClose();
        setUnitList(false);
        setJobList(true);
        setSections([]);
        setSelectedQuestion(null);
    }


    const handleClose = () => setShow(false);
    const handleShow = (cr_index, sub_question_index) => {
        setCrIndex(cr_index);
        setSqIndex(sub_question_index);
        setShow(true);
    };

    const saveQuestion = async (item, cr_index) => {
        if (item?.answers?.status == "Edit") {
            var cr_questions = { ...CrossReferencing };
            cr_questions.questions[cr_index].answers.status = "Save";
            setCrossReferencing({ ...cr_questions });
            return;
        }

        setIsSaving(true);
        const auth = localStorage.getItem("user");
        var payload = [];
        if (item.sub_questions.length > 0) {
            payload = item.sub_questions;
        }
        else {

            payload = [item];
        }
        var result = await fetch(`${baseurl}/api/save-answer`, {
            method: "POST",
            body: JSON.stringify({ "type": 2, "questions": payload }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(auth).token}`
            },
        });
        if (result.ok) {
            var cr_questions = { ...CrossReferencing };
            cr_questions.questions[cr_index].answers.status = "Edit";
            setCrossReferencing({ ...cr_questions });
            setIsSaving(false);
        }
    };


    const addSection = (event, item) => {
        if (!event.target.checked) {
            var newSections = [...sections];
            const index = newSections.indexOf(item);
            newSections.splice(index, 1);
            setSections([...newSections]);
            return;
        }
        setSections([...sections, item]);
    }

    const getBorderColor = (answerStatusID) => {
        if (answerStatusID == 1) {
            return "border-success"
        }
        if (answerStatusID == 2) {
            return "border-danger"
        }
        return "border";
    }

    const getQuestionBarClassName = (answerStatusID) => {
        var answerStatusID
        if (answerStatusID == 1) {
            return 'Question-bar pass';
        }

        if (answerStatusID == 2) {
            return 'Question-bar fail';
        }


        return 'Question-bar';
    };


    return (
        <>
            <Header />
            <section className="banner-3">
                <div className="main-banner">
                    <div className="unit-1">

                        <div className="unit-icon">
                            <img src={note} alt="" />

                        </div>

                        <p className="unit-1-tx">Unit {CrossReferencing?.course?.unit_number}</p>

                    </div>

                    <h2 className="unit-1-heading">{CrossReferencing?.course?.title}</h2>

                </div>
            </section>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{activeUnit?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="outline-danger" onClick={() => {
                        setUnitList(true)
                        setJobList(false);
                    }}>Select Another Unit</Button>
                    <br />
                    <br />
                    <Row className="">
                        {
                            !unitList && !jobList && <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <p><b>Job Title:</b> {activeJob.job_title}</p>
                                        <p><b>Job Date:</b> {activeJob.job_date}</p>
                                        <p><b>Job Location:</b> {activeJob.job_location}</p>
                                        <p><b>Status</b> {activeJob?.status}</p>
                                        {
                                            (activeJob.description[0] == null || activeJob.description?.length == 0) ?
                                                <>
                                                    <p>
                                                        <hr />
                                                        <b dangerouslySetInnerHTML={{ __html: selectedQuestion }}></b>
                                                    </p>
                                                    <p><b>Uploaded File:</b><span className="text-primary"> {activeJob?.file}</span></p>
                                                </>
                                                : null
                                        }
                                        <hr />

                                        {!(activeJob.description[0] == null || activeJob.description?.length == 0) &&


                                            <p>
                                                <b dangerouslySetInnerHTML={{ __html: selectedQuestion }}></b>
                                            </p>

                                        }


                                        {!(activeJob.description[0] == null || activeJob.description?.length == 0) &&
                                            activeJob.description.map((item, index) => (
                                                <div key={index}>

                                                    <Row className="justify-content-between mb-2" key={index}>
                                                        <Col md={11}>
                                                            <JoditEditor
                                                                value={item}
                                                                config={{
                                                                    readonly: true,
                                                                }} />
                                                        </Col>
                                                        <Col md={1}>
                                                            <input type="checkbox" onChange={(event) => { addSection(event, item, index) }} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                        }
                                        {role === 4 ? <Button variant="primary" onClick={() => {
                                            addEntry();
                                        }}>
                                            Submit Selection
                                        </Button>
                                            : role === 5 ? < Button variant="secondary" style={{ cursor: "not-allowed" }}>
                                                Submit Selection
                                            </Button>
                                                : null}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        }
                        {!unitList && jobList && JobAssessmentRecord?.map((item, index) => (
                            (item.course_id == activeUnit?.id && item?.status == "Passed") ?
                                <Col md={3} key={index}>
                                    <Card>
                                        <Card.Body>
                                            <Button className="w-100" variant="primary" onClick={() => {
                                                setActiveJob(item);
                                                setJobList(false);
                                                setUnitList(false);
                                            }}>
                                                {item.job_title}
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                :
                                null
                        ))}
                        {unitList && courses?.map((item, index) => (
                            <Col md={3} key={index}>
                                <Card>
                                    <Card.Body>
                                        <Button className="w-100" variant="primary" onClick={() => {
                                            setActiveUnit(item);
                                            setUnitList(false);
                                            setJobList(true);
                                        }}>
                                            {item.name}
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Modal.Body>
            </Modal >

            <section className="sec-two-Underpinning-Questions">

                <div className="container">

                    <h2 className="sec-2-heading">Performance Learning Outcomes</h2>
                    <p className="sec-2-Underpinning-Questions-tx text-capitalize">{CrossReferencing?.course?.cross_refrencing_knowledge_outcome}</p>

                    <br></br>
                    {
                        CrossReferencing?.questions?.map((item, cr_index) =>
                            <div
                                key={cr_index}
                                className={`Question-1  ${getBorderColor(item?.answers?.status_id)}`}
                            >
                                <div className={getQuestionBarClassName(item?.answers?.status_id)}>

                                    <h4 className="Question">{cr_index + 1} :</h4>

                                    <div className="save-icon-box">

                                        {(item.answers.status == "Passed" || item.answers.status == "Failed" || item.answers.status == "Pending") ?

                                            (
                                                <>
                                                    <div className="save-icon">
                                                        {item.answers.status == "Passed" ?
                                                            <AiOutlineCheckSquare style={{ color: "green", fontSize: "20px" }} />
                                                            :
                                                            item.answers.status == "Failed" ?
                                                                <RxCross2 style={{ color: "red", fontSize: "20px" }} />
                                                                :
                                                                <img src={edit} alt="" />
                                                        }
                                                    </div>

                                                    <button disabled={isSaving} style={{
                                                        border: "none",
                                                        background: "none",
                                                        color: "none",
                                                        fontFamily: 'Lato',
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                        margin: "0 0 0 0",
                                                        color: "#0062f478",
                                                    }}
                                                        className={`${item.answers.status == "Passed" ? 'text-success' : item.answers.status == "Failed" ? "text-danger" : "text-primary"}`}
                                                    >
                                                        {item.answers.status}
                                                    </button>
                                                </>
                                            )

                                            : null
                                        }
                                        {item.answers.status == "Edit" || item.answers.status == "Failed" ?
                                            (<>
                                                <div className="save-icon">
                                                    <img src={edit} alt="" />
                                                </div>

                                                {role == 4 ? <button disabled={isSaving} style={{
                                                    border: "none",
                                                    background: "none",
                                                    color: "none",
                                                    fontFamily: 'Lato',
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    margin: "0 0 0 0",
                                                    color: "#0062f478",
                                                }}
                                                    onClick={() => {
                                                        saveQuestion(item, cr_index);
                                                    }}
                                                >
                                                    {
                                                        isSaving &&
                                                        <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
                                                    }
                                                    {item.answers.status == "Edit" ? item.answers.status : "Save"}
                                                </button>
                                                    :
                                                    <button disabled={isSaving} style={{
                                                        border: "none",
                                                        background: "none",
                                                        color: "none",
                                                        fontFamily: 'Lato',
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                        margin: "0 0 0 0",
                                                        color: "#0062f478",
                                                    }}
                                                    >
                                                        {item.answers.status == "Edit" ? item.answers.status : "Save"}
                                                    </button>
                                                }
                                            </>
                                            )
                                            :
                                            !item.answers.status || item.answers.status == "Edit" || item.answers.status == "Save" ?
                                                <>
                                                    <div className="save-icon">
                                                        <img src={edit} alt="" />
                                                    </div>

                                                    {role == 4 ?
                                                        <button disabled={isSaving} style={{
                                                            border: "none",
                                                            background: "none",
                                                            color: "none",
                                                            fontFamily: 'Lato',
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                            margin: "0 0 0 0",
                                                            color: "#0062f478",
                                                        }}
                                                            onClick={() => {
                                                                saveQuestion(item, cr_index);
                                                            }}
                                                        >
                                                            {
                                                                isSaving &&
                                                                <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
                                                            }
                                                            {item.answers.status == "Edit" ? item.answers.status : "Save"}
                                                        </button>
                                                        :
                                                        <button style={{
                                                            border: "none",
                                                            background: "none",
                                                            color: "none",
                                                            fontFamily: 'Lato',
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                            margin: "0 0 0 0",
                                                            color: "#0062f478",
                                                        }}
                                                        >
                                                            Save
                                                        </button>
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                                <div className="type-bar">
                                    <h3 className="type-bar-heading py-4">
                                        <div dangerouslySetInnerHTML={{ __html: item.question }}></div>
                                    </h3>
                                    {
                                        item.sub_questions.length == 0 &&
                                        <div>
                                            <br />
                                            {item.answers && item.answers.answer.map((answer, answer_index) => (
                                                <ol>
                                                    <li key={answer_index}>
                                                        <div className="d-flex align-items-start" style={{ gap: 5 }}>
                                                            {!item?.answers?.assessor_answered == 1 ?
                                                                <p>{answer.job_record_name}</p>
                                                                :
                                                                <Link style={{ textDecoration: "none" }} to={"/showjobrecord/" + answer?.job_record_id}>
                                                                    <div className="p-2 ">
                                                                        <Button>Your assessor has cross referenced this performance assessment requirement with an observational record</Button>
                                                                    </div>
                                                                </Link>
                                                            }
                                                            {!item?.answers?.assessor_answered == 1 &&
                                                                <Button
                                                                    disabled={item.answers.status == "Edit" || item.answers.status == "Passed" || item.answers.status == "Pending" ? true : false}
                                                                    variant="danger"
                                                                    style={{ fontSize: "10px", width: "20px", padding: "2px" }}
                                                                    onClick={() => {
                                                                        var cr_questions = { ...CrossReferencing };
                                                                        cr_questions.questions[cr_index].answers.answer.splice(answer_index, 1);
                                                                        setCrossReferencing({ ...cr_questions });
                                                                    }}>
                                                                    X
                                                                </Button>
                                                            }
                                                        </div>
                                                    </li>
                                                </ol>
                                            ))}
                                            {(item?.answers != null && item?.answers?.feedback && item?.answers?.feedback?.length != 0) ?
                                                <div>
                                                    <h6 className="MyFeedBackHeading">Assessor's Feedback:</h6>
                                                    <p className="MyFeedBack mb-2">{item?.answers?.feedback[0].feedback}</p>
                                                    <div className="d-flex justify-content-end mt-0 m-4 ">
                                                        <p className="text-primary cursor-pointer" onClick={() => {
                                                            openFeedbackModal()
                                                            setSelectedQuestion(item?.question);
                                                            setFeedbackData(item?.answers?.feedback)
                                                        }
                                                        }>View All Feedbacks</p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {(item?.answers?.status == "Passed" || item?.answers?.status == "Pending") ?
                                                <>
                                                </>
                                                :
                                                !item.answers.assessor_answered == 1 &&
                                                < button type="submit" className="btns2" onClick={() => {
                                                    setSelectedQuestion(item.question);
                                                    handleShow(cr_index, null);
                                                }}>
                                                    +Add Entry
                                                </button>
                                            }
                                        </div>
                                    }
                                    {
                                        item.sub_questions.map((sub_question, sub_question_index) => {
                                            return (
                                                <div key={sub_question_index}>
                                                    <p className="sub-question fw-normal h6">
                                                        ({Number(sub_question_index + 10).toString(36).toLowerCase()}) {sub_question.question}
                                                    </p>
                                                    <br />
                                                    {
                                                        sub_question.answers && sub_question.answers.answer.map((answer, answer_index) => (
                                                            <ol>
                                                                <li key={answer_index}>
                                                                    <div className="d-flex align-items-start" style={{ gap: 5 }}>
                                                                        {!sub_question.answers?.assessor_answered == 1 ?
                                                                            <>
                                                                                <p>{answer.job_record_name}</p>
                                                                            </>
                                                                            :
                                                                            <Link style={{ textDecoration: "none" }} to={"/showjobrecord/" + answer?.job_record_id}>
                                                                                <div className="p-2 ">
                                                                                    <Button>Your assessor has cross referenced this performance assessment requirement with an observational record</Button>
                                                                                </div>
                                                                            </Link>
                                                                        }
                                                                        {!sub_question.answers?.assessor_answered == 1 &&
                                                                            <Button
                                                                                disabled={item.answers.status == "Edit" || item.answers.status == "Passed" || item.answers.status == "Pending"  ? true : false}
                                                                                variant="danger"
                                                                                style={{ fontSize: "10px", width: "20px", padding: "2px" }}

                                                                                onClick={() => {
                                                                                    var cr_questions = { ...CrossReferencing };
                                                                                    cr_questions.questions[cr_index].sub_questions[sub_question_index].answers.answer.splice(answer_index, 1)
                                                                                    setCrossReferencing({ ...cr_questions });
                                                                                }}>
                                                                                X
                                                                            </Button>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        ))
                                                    }
                                                    {(item?.answers?.status == "Passed" || item?.answers?.status == "Pending") ?
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            {!sub_question.answers?.assessor_answered == 1 &&
                                                                < button type="submit" className="btns2" onClick={() => {
                                                                    setSelectedQuestion(item.question + "<br><br>" + sub_question.question);
                                                                    handleShow(cr_index, sub_question_index);
                                                                }}>
                                                                    +Add Entry
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        (sub_question_index != (item.sub_questions.length - 1)) && <hr />
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    {role == 4 ?
                        <button disabled={isSaving || allAnswersPassed} type="submit" className={`btns1 ${allAnswersPassed ? "grayed-out-button" : ""}`} onClick={formSubmitHandler} title={allAnswersPassed ? 'All answers are asnwered' : ''}
                        >
                            {
                                isSaving &&
                                <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} role="status" aria-hidden="true"></span>
                            }
                            Submit for Grading
                        </button>

                        : role == 5 ?
                            <button type="submit" className="btns1" style={{ backgroundColor: "gray", cursor: "not-allowed" }}
                            >
                                Submit for Grading
                            </button>
                            : null

                    }
                </div>
                <FeedbackModal showModal={showFeedbackModal} Question={selectedQuestion} data={feedbackData} handleClose={closeFeedbackModal} />
            </section >

            <SupportFooter />
            {
                modal && (
                    <ConfirmationModal content="Answer Submitted Successfully" onButtonClick={closeModal} />
                )
            }
            <Footer />
        </>
    )
}
export default CrossReferencing;
