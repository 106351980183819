import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const CalendarModal = ({ showModal, handleClose, handleSave, }) => {

  const [title, setTitle] = useState("")
  const [date, setDate] = useState("")
  const [personalNote, setPersonalNote] = useState("")
  const { id } = useParams()
  const Submit = async () => {
    const baseurl = process.env.REACT_APP_BASE_URL
    const auth = localStorage.getItem("user");
    var token = JSON.parse(auth).token
    try {
      var data = { date, title, note: personalNote, student_id: id }
      let result = await fetch(`${baseurl}/api/calendar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      });
      if (!result.ok) {
        throw new Error('Calendar added successfully');
      }
      else {
        const newCalendar = await result.json();
        handleSave(newCalendar);
        handleClose()
        setTitle("");
        setDate("");
        setPersonalNote("");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='text-primary d-flex justify-content-center'>
          Add New Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='CalendarModal'>
          <div>
            <p>Date</p>
            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="CalendarInputBox" />
          </div>
          <div>
            <p>Title</p>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className='CalendarInputBox' />
          </div>
          <div>
            <p>Description Of Task</p>
            <textarea className="CalendarInputBox" value={personalNote} onChange={(e) => setPersonalNote(e.target.value)}></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={Submit}>
          Add Task
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CalendarModal;
