import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteReviewModal = ({ showModal, handleClose, handleSave, DeleteFunction }) => {
    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title className='text-primary'>Delete Review</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='px-2'>Do you want to delete this review ?</p>
            </Modal.Body>
            <div className='d-flex justify-content-center'>
                <Modal.Footer>
                    <Button variant="light" style={{ width: "200px" }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" style={{ width: "200px" }} onClick={DeleteFunction}>
                        Delete
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default DeleteReviewModal;
