import React, { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import DashboardHeader from "./DashboardHeader";
import DashboardSlider from "./DashboardSlider";
import Footer from "./Footer";
import SideBar from "./SideBar";
import SupportFooter from "./SupportFooter";

const Dashboard = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    let { state, dispatch } = useContext(GlobalContext);

    const navigate = useNavigate();

    const [courses, setCourses] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [learners, setLearners] = useState([]);
    const [overallProgramme, setOverallProgramme] = useState([])
    const username = state?.user?.name;
    const role = state?.user?.role
    const auth = localStorage.getItem("user");
    var token = JSON.parse(auth).token
    const qaSelectedLearnerId = state?.qaSelectedLearner

    useEffect(() => {
        if (token) {
            getCourses();
            getReviews()
            getOverallProgrammeAssessment()
        } else {
            navigate('/');
        }
    }, [qaSelectedLearnerId]);

    useEffect(() => {
        if (role === 5) {
            getLearners()
        }
    }, [])

    const getCourses = async () => {
        let result = await fetch(`${baseurl}/api/get-my-courses${role === 5 ? '?user_id=' + qaSelectedLearnerId : ''}`, {
            method: 'GET',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setCourses(result);
    }


    const getLearners = async () => {
        let result = await fetch(`${baseurl}/api/get-learners`, {
            method: 'GET',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setLearners(result);
    }

    const getReviews = async () => {
        try {
            let result = await fetch(`${baseurl}/api/get-review-by-login${role === 5 ? '?user_id=' + qaSelectedLearnerId : ''}`, {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setReviews(result)
        }
        catch (error) {
            console.log(error);
        }
    }

    const getOverallProgrammeAssessment = async () => {
        try {
            let result = await fetch(`${baseurl}/api/get-overall-program-assessment-by-login${role === 5 ? '?user_id=' + qaSelectedLearnerId : ''}`, {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setOverallProgramme([result[0]])
            console.log("result.length",overallProgramme)
        }
        catch (error) {
            console.log(error)
        }
    }

    const selectLearner = (learnerId, learnerName) => {
        dispatch({
            type: "QA_SELECTED_LEARNER",
            payload: {
                qaSelectedLearner: learnerId,
                qaSelectedLearnerName: learnerName
            }
        })
    }

    const getpathcolor = (percentage) => {
        if (percentage <= 15) {
            return 'orange'
        }
        else if (percentage <= 50) {
            return 'blue'
        }
        else if (percentage <= 75) {
            return '#ffcd01'
        }
        else {
            return 'green'
        }
    }

    let sumOfProgress = 0;
    let count = 0

    courses?.courses?.forEach((item) => {
        sumOfProgress += item.progress;
        count++;
    });

    const average = sumOfProgress / count;
    var averageFormated = Math.round(average)

    return (
        <>
            <div className="das-main">
                <SideBar />
                <section className="das-cntn">
                    <DashboardHeader />

                    <section className="Welcome-Back bg-white shadow">
                        <div className="container-Welcome-Back">
                            <h1 className="Welcome-Back-heading">Welcome Back {username}</h1>
                            {
                                (courses?.last_attempted?.course_name && !isNaN(averageFormated)) ?
                                    <p className="Welcome-Back-tag-line">You've completed <span>{averageFormated}%</span> of your <span>{courses?.last_attempted?.course_name}</span> qualification.</p>
                                    :
                                    <p className="Welcome-Back-tag-line">You've completed <span>0%</span> of your <span>{courses?.last_attempted?.course_name}</span> qualification.</p>
                            }
                        </div>
                    </section>

                    <section className="Dashbord-page1-sec-two bg-white">
                        <div className="learner-dashbord-container">

                            < div className="Overall-Progress">
                                <div className="Overall-Progress-txs">
                                    <h2 className="Overall-Progress-tx1">Units</h2>
                                    <p className="Overall-Progress-tx2"> Click on a unit to start or resume your journey! </p>
                                </div>
                                {role === 5 &&
                                    <div className="d-flex justify-content-end m-2">
                                        <select onChange={(e) => selectLearner(e.target.value, e.target.options[e.target.selectedIndex].text)} className="rounded p-1">
                                            <option value="" className="text-secondary">Select Learner</option>
                                            {state?.qaLearners?.map((item, index) =>
                                                <option key={index} value={item?.value}>{item?.label}</option>
                                            )}
                                        </select>

                                    </div>}
                            </div>

                            <div className="Progress-boxes row">
                                {
                                    courses?.courses?.map((item, index) =>
                                        <Link key={index} className="Progress-box-one col-12 col-sm-6 col-md-3 m-2 d-flex justify-content-center shadow" to={"/unitoverview/" + item.id}>
                                            <div>
                                                <div className="Progress-bar-image p-1" style={{ width: "100px", height: "auto" }}>
                                                    <CircularProgressbar value={item?.progress} text={`${item?.progress}%`} styles={buildStyles({ pathColor: getpathcolor(item?.progress) })} />
                                                </div>
                                                <h3 className="Progress-bar-tx">Unit {item?.row_number}</h3>
                                                { }
                                            </div>

                                        </Link>
                                    )
                                }
                            </div>
                        </div>


                        <div className="plannedActions">
                            <div><h2 className="Overall-Progress-tx1">Reviews</h2></div>
                        </div>

                        <div className="plannedActions-slider-container">
                            <DashboardSlider />
                        </div>

                        <div className="dashboard-Comments-Section">
                            <div className="Programme-Assessment">
                                <div className="programme-heading">
                                    <h2 className="m-2 Overall-Progress-tx1">Overall Programme Assessment</h2>
                                </div>

                                <div className="Programme-Assessment-Main shadow">
                                    {overallProgramme.length >0 && overallProgramme[0] != undefined ?
                                        overallProgramme?.map((item, index) => {

                                            const currentdate = new Date()
                                            const totalDurationInMs = Date.parse(item?.anticipated_completed_date) - Date.parse(item?.start_date);
                                            const numberOfDaysPassed = Math.max(0, Math.min(currentdate - Date.parse(item?.start_date), totalDurationInMs)) / (1000 * 60 * 60 * 24)
                                            const totalDurationInDays = (totalDurationInMs / (1000 * 60 * 60 * 24));
                                            const monthsLeft = Math.round((totalDurationInDays - numberOfDaysPassed) / 30.44);

                                            return (
                                                <>
                                                    <div className="Programme-Assessment-Container mb-4">
                                                        <div className="Programme-Assessment-Details border p-2 rounded">
                                                            <div className="left-blue-border">
                                                                <p className="m-0 p-0 text-primary overall-programme-text">Start Date</p>
                                                                <p className="m-0 p-0">{new Date(item?.start_date).toLocaleString('en-GB').slice(0, 10)}</p>
                                                            </div>
                                                        </div>

                                                        <div className="Programme-Assessment-Details border p-2 rounded">
                                                            <div className="left-blue-border">
                                                                <p className="m-0 p-0 text-primary overall-programme-text">Planned End Date</p>
                                                                <p className="m-0 p-0">{new Date(item?.planned_end_date).toLocaleString('en-GB').slice(0, 10)}</p>
                                                            </div>
                                                        </div>

                                                        <div className="Programme-Assessment-Details border p-2 rounded">
                                                            <div className="left-blue-border">
                                                                <p className="m-0 p-0 text-primary overall-programme-text">Anticipated comp. Date</p>
                                                                <p className="m-0 p-0">{new Date(item?.anticipated_completed_date).toLocaleString('en-GB').slice(0, 10)}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="Programme-Assessment-Container">
                                                        <div>
                                                            <div className="Programme-Assessment-Details border p-2 mb-4 rounded">
                                                                <div className="left-blue-border">
                                                                    <p className="m-0 p-0 text-primary overall-programme-text">Months Left</p>
                                                                    <p className="m-0 p-0">{monthsLeft}</p>
                                                                </div>
                                                            </div>
                                                            <div className="Programme-Assessment-Details border p-2 rounded">
                                                                <div className="left-blue-border">
                                                                    <p className="m-0 p-0 text-primary overall-programme-text">On Target</p>
                                                                    <p className="m-0 p-0">{item?.on_target}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        :
                                        <p className="text-secondary text-center"><i>No overall Programme Assessment</i></p>
                                    }
                                </div>
                            </div>

                            <div className="Programme-Reviews">
                                <div className="programme-heading">
                                    <h2 className="m-2 Overall-Progress-tx1">Next Review Details</h2>
                                </div>

                                < div className="Programme-Reviews-Main shadow" >
                                    {reviews?.length > 0 ?
                                        (
                                            <>
                                                <div className="Programme-Reviews-Container">

                                                    <div className="Programme-Reviews-Details border p-2 rounded mb-4">
                                                        <div className="left-orange-border">
                                                            <p className="m-0 p-0 next-review-text">Review Date</p>
                                                            <p className="m-0 p-0">{new Date(reviews[0]?.review_date).toLocaleString('en-GB').slice(0, 10)}</p>
                                                        </div>
                                                    </div>

                                                    <div className="Programme-Reviews-Details border p-2 rounded mb-4">
                                                        <div className="left-orange-border">
                                                            <p className="m-0 p-0 next-review-text">Review Time</p>
                                                            <p className="m-0 p-0">{reviews[0]?.review_time}</p>
                                                        </div>
                                                    </div>

                                                    <div className="Programme-Reviews-Details border p-2 rounded mb-4 text-wrap">
                                                        <div className="left-orange-border ">
                                                            <p className="m-0 p-0 next-review-text">Video Call Link</p>
                                                            <p className="m-0 p-0 text-wrap text-break">{reviews[0]?.video_call_link}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        <p className="text-secondary text-center"><i>No Reviews</i></p>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <SupportFooter />
                    <section className="footerwrapper">
                        <Footer />
                    </section>
                </section>
            </div >
        </>
    )
}

export default Dashboard;