import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import Footer from "./Footer";
import SideBar from "./SideBar";

const MyCourse = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getCourses(JSON.parse(auth).token);
        } else {
            navigate('/');
        }
    }, []);

    const getCourses = async (token) => {
        let result = await fetch(`${baseurl}/api/my-courses`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setCourses(result);
    }
    
    return (
        <>
            <div className="das-main">
                <SideBar />
                <section className="das-cntn">
                    <DashboardHeader />
                    <section className="Dashbord-banner">
                        <div className="main-banner">
                            <h2 className="Dashbord-unit-1-heading">NVQ Diploma in
                                Installation & Commissioning.</h2>
                            <div className="dashbord-banner-buttons">
                                <button type="submit" className="btns8">Level 3</button>
                                <button type="submit" className="btns8">55% Completed</button>
                            </div>
                        </div>
                    </section>
                    <section className="Dashbord-page2-sec-two">
                        <div className="dashbord-container Mycourses-dashbord-container">
                            <h2 className="Dashbord-page-2-sec-two-heading">Traction Lift Installation</h2>
                            <p className="Dashbord-page-2-sec-two-tx">This portfolio will provide the basis of your NVQ accreditation. When
                                completed, it will contain details of various tasks performed, training records and other competency
                                evidence. These documents will be used as the basis of your assessment against the appropriate NVQ units
                                of competency.</p>
                            <div className="Overall-Progress">
                                <div className="Overall-Progress-txs">
                                    <h2 className="Overall-Progress-tx2">Unit Progress</h2>
                                </div>
                            </div>
                            <div className="Progress-boxes">
                                {
                                    courses.map((item, index) =>
                                        <div className="Unit-Progress-box-2">
                                            <Link to={"/unitoverview/" + item.id}>
                                                <div className="Unit-Progress-status">
                                                    <h4 className="Unit-Progress-box-1-heading">Unit {item.unit_number}</h4>
                                                    <button type="submit" className="btns9">{item.status}</button>
                                                </div>
                                                <p className="Unit-Progress-box-1-tx">{item.description}</p>
                                                <div className="completion-percentage-box">
                                                    <h5 className="Completed-tx">Progress</h5>
                                                    <h6 className="completion-percentage">{item.progress}%</h6>
                                                </div>
                                                <div className="Progress-bar-image">
                                                    <div className="progress-bar-fill"></div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                    <section className="footerwrapper">
                        <Footer />
                    </section>
                </section>
            </div>
        </>
    )
}
export default MyCourse;