import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'
import DeleteReviewModal from './DeleteReviewModal'

function AssessorReviews() {

    const { id } = useParams()
    const baseurl = process.env.REACT_APP_BASE_URL
    const [showModal, setShowModal] = useState(false)
    const [reviews, setReviews] = useState([])
    const showModalFunction = () => {
        setShowModal(true)
    }
    const handleClose = () => {
        setShowModal(false)
    }
    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if (token) {
            getReviews(token)
        }
    }, [])
    const deleteReview = async (id) => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        let result = await fetch(`${baseurl}/api/review/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (result.ok) {
            getReviews(token)
            setShowModal(false)
        }
    }
    const getReviews = async (token) => {
        let result = await fetch(`${baseurl}/api/get-review/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setReviews(result)
    }
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <div className='ReviewsDashboard'>
                        <div className='d-flex justify-content-between my-4'>
                            <h2>Reviews</h2>
                            <Link to={`/addreview/${id}`}>
                                <Button className='px-4 py-2' variant="primary"> + Add New Review</Button>
                            </Link>
                        </div>
                        <div className='table-responsive'>
                            <table>
                                <tr className='text-center bg-primary text-white'>
                                    <th class="col-md-1">Review Date</th>
                                    <th class="col-md-1">Review Time</th>
                                    <th class="col-md-2">Video Call Link</th>
                                    <th class="col-md-1">Actions</th>
                                </tr>
                                {reviews?.map((item, index) =>
                                    <>
                                        <tr className="Assignments text-center">
                                            <td className="grading">{new Date(item?.review_date).toLocaleString('en-GB').slice(0, 10)}</td>
                                            <td className="grading">{item?.review_time}</td>
                                            <td className="grading">
                                                {item?.video_call_link}
                                            </td>
                                            <td className="grading">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item >
                                                            <Link to={`/editreview/${item?.id}/${id}`}>
                                                                <div>
                                                                    Edit
                                                                </div>
                                                            </Link>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item >
                                                            <div onClick={showModalFunction}>
                                                                Delete
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                        <DeleteReviewModal showModal={showModal} handleClose={handleClose} DeleteFunction={() => deleteReview(item?.id)} />
                                    </>
                                )}
                            </table>
                        </div>
                    </div>
                </section >
            </div >
        </>
    )
}
export default AssessorReviews