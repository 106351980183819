import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Login from './Components/Login';
import UnitOverview from './Components/UnitOverview';
import UnderpinningQuestions from './Components/UnderpinningQuestions';
import CrossReferencing from './Components/CrossReferencing';
import StudentProfile from './Components/StudentProfile';
import JobAssessmentRecord from './Components/JobAssessmentRecord';
import Dashboard from './Components/Dashboard';
import MyCourse from './Components/MyCourse';
import Calender from './Components/Calender';
import AssessorDashboard from './Components/assessorDashboard';
import TrainerDashboard from './Components/trainerDashboard';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AddJobRecord from './Components/addJobRecord';
import EditJobRecord from './Components/editJobRecord';
import ShowJobRecord from './Components/showJobRecord';
import Students from './Components/Students';
import { useLocation } from 'react-router-dom';
import StudentProfileAssignments from './Components/StudentProfileAssignments';
import StudentProfileCalendar from './Components/StudentProfileCalendar';
import StudentProfileProgress from './Components/StudentProfileProgress';
import ForgotPassword_email from './Components/ForgotPassword_email';
import ForgotPassword_newpass from './Components/ForgotPassword_newpass';
import AssessorCalender from './Components/AssessorCalendar';
import TrainerStudents from './Components/TrainerStudents';
import TrainerStudentProfile from './Components/TrainerStudentProfile';
import AssessorReviews from './Components/AssessorReviews';
import AssessorAddComments from './Components/AssessorAddComments';
import AssessorComments from './Components/AssessorComments';
import AssessorEditComments from './Components/AssessorEditComments';
import AddReview from './Components/AddReview';
import EditReview from './Components/EditReview';
import OverallProgrammeAssessment from './Components/OverallProgrammeAssessment';
import EditOverallProgrammeAssessment from './Components/EditOverallProgrammeAssessment';
import AddOverallProgrammeAssessment from './Components/AddOverallProgrammeAssessment';
import { useEffect } from 'react';
import { GlobalContext } from './context/Context';
import { useContext } from "react";
import TermsAndServices from './Components/TermsAndServices';
import Privacy from './Components/Privacy';
import TermsAndConditions from './Components/TermsAndConditions';
import Splashscreen from './Components/SpashScreen';
import EmbeddedIframe from './Components/EmbeddedIframe';
import Profile from './Components/Profile';
import SpecificChat from './Components/Chat';
import LearnerNotifications from "./Components/LearnerNotifications"

function App() {
  const baseurl = process.env.REACT_APP_BASE_URL
  let { state, dispatch } = useContext(GlobalContext);
  const [dataFetched, setDataFetched] = useState(false);
  var token = ''
  const checkUserAndNavigate = () => {
    return new Promise((resolve, reject) => {
      const auth = localStorage.getItem('user');
      if (auth) {
        const parsedAuth = JSON.parse(auth);
        token = parsedAuth.token
        resolve(parsedAuth);
      }
    });
  };
  const navigate = useNavigate()
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem('lastPath', location.pathname);
  }, [location.pathname]);
  const lastPath = localStorage.getItem('lastPath');
  const checkUser = async () => {
    checkUserAndNavigate()
    try {
      let result = await fetch(`${baseurl}/api/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      result = await result.json()
      if (result) {
        await dispatch({
          type: "USER_LOGIN",
          payload: {
            user: result.user,
            token: result.token
          }
        })
        await dispatch({
          type: "QA_LEARNERS",
          payload: {
            qaLearners: result.user.learners
          }
        })
      } else {
        dispatch({ type: "USER_LOGOUT" })
      }
    }
    catch {
      dispatch({ type: "USER_LOGOUT" })
    }
  }
  useEffect(() => {
    checkUser()
      .then(auth => {
        if (state?.user?.role) {
          navigate("/")
        }
        setDataFetched(true);
      })
      .catch(error => {
        setDataFetched(true);
        throw error
      });
  }, [])
  useEffect(() => {
    if (dataFetched && state?.user?.role) {
      if (lastPath) {
        navigate(lastPath);
      } else {
        navigate('/');
      }
    }
  }, [dataFetched, state.user]);
  return (
    <div className="App">
      {dataFetched ?
        (state?.user == null) ?
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/forgotpassword_email" element={<ForgotPassword_email />} />
            <Route path="/resetpassword/:email" element={<ForgotPassword_newpass />} />
          </Routes>
          :
          (state?.user) ?
            (state?.user?.role == 2) ?
              <Routes>
                <Route path="/" element={<AssessorDashboard />} />
                <Route path="/studentprofile/:id" element={<StudentProfile />} />
                <Route path="/assessor_calender" element={<AssessorCalender />} />
                <Route path="/assessordashboard" element={<AssessorDashboard />} />
                <Route path="/studentprofileassignments" element={<StudentProfileAssignments />} />
                <Route path="/studentprofilecalendar" element={<StudentProfileCalendar />} />
                <Route path="/studentprofileprogress" element={<StudentProfileProgress />} />
                <Route path="/students" element={<Students />} />
                <Route path="/assessorreviews/:id" element={<AssessorReviews />} />
                <Route path="/addreview/:id" element={<AddReview />} />
                <Route path="/editreview/:id/:studentid" element={<EditReview />} />
                <Route path="/showjobrecord/:id" element={<ShowJobRecord />} />
                <Route path="/addcomment/:id" element={<AssessorAddComments />} />
                <Route path="/editcomment/:id/:studentid" element={<AssessorEditComments />} />
                <Route path="/assessorcomments" element={<AssessorComments />} />
                <Route path="/overallprogramme" element={<OverallProgrammeAssessment />} />
                <Route path="/editoverallprogramme/:id" element={<EditOverallProgrammeAssessment />} />
                <Route path="/addoverallprogramme/:id" element={<AddOverallProgrammeAssessment />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/addjobrecord/:id" element={<AddJobRecord />} />
                <Route path="/editjobrecord/:id/:courseid" element={<EditJobRecord />} />
                <Route path="/showjobrecord/:id" element={<ShowJobRecord />} />
                <Route path="/chat/:userid" element={<SpecificChat />} />
                <Route path="/notifications" element={<LearnerNotifications />} />
              </Routes>
              :
              (state?.user?.role == 3) ?
                <Routes>
                  <Route path="/" element={<TrainerDashboard />} />
                  <Route path="/trainerdashboard" element={<TrainerDashboard />} />
                  <Route path="/studentprofileassignments" element={<StudentProfileAssignments />} />
                  <Route path="/trainerstudents" element={<TrainerStudents />} />
                  <Route path="/trainerstudentprofile/:id" element={<TrainerStudentProfile />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/chat/:userid" element={<SpecificChat />} />
                  <Route path="/notifications" element={<LearnerNotifications />} />
                </Routes>
                :
                (state?.user?.role == 4) ?
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/unitoverview/:id" element={<UnitOverview />} />
                    <Route path="/underpinningquestions/:id" element={<UnderpinningQuestions />} />
                    <Route path="/crossreferencing/:id" element={<CrossReferencing />} />
                    <Route path="/jobassessmentrecord/:id" element={<JobAssessmentRecord />} />
                    <Route path="/mycourse" element={<MyCourse />} />
                    <Route path="/calender" element={<Calender />} />
                    <Route path="/addjobrecord/:id" element={<AddJobRecord />} />
                    <Route path="/editjobrecord/:id/:courseid" element={<EditJobRecord />} />
                    <Route path="/showjobrecord/:id" element={<ShowJobRecord />} />
                    <Route path="/termsandservices" element={<TermsAndServices />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/termsandconditions" element={<TermsAndConditions />} />
                    <Route path="/learningmaterial/:id" element={<EmbeddedIframe />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/chat/:userid" element={<SpecificChat />} />
                    <Route path="/notifications/:id" element={<LearnerNotifications />} />
                  </Routes>
                  : (state?.user?.role == 5) ?
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/unitoverview/:id" element={<UnitOverview />} />
                      <Route path="/underpinningquestions/:id" element={<UnderpinningQuestions />} />
                      <Route path="/crossreferencing/:id" element={<CrossReferencing />} />
                      <Route path="/jobassessmentrecord/:id" element={<JobAssessmentRecord />} />
                      <Route path="/calender" element={<Calender />} />
                      <Route path="/addjobrecord/:id" element={<AddJobRecord />} />
                      <Route path="/editjobrecord/:id/:courseid" element={<EditJobRecord />} />
                      <Route path="/showjobrecord/:id" element={<ShowJobRecord />} />
                      <Route path="/termsandservices" element={<TermsAndServices />} />
                      <Route path="/privacy" element={<Privacy />} />
                      <Route path="/termsandconditions" element={<TermsAndConditions />} />
                      <Route path="/learningmaterial/:id" element={<EmbeddedIframe />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/chat/:userid" element={<SpecificChat />} />
                    </Routes>
                    :
                    null
            :
            null
        :
        <Splashscreen />
      }
    </div>
  );
}
export default App;
