import { React, useContext, useEffect } from "react";
import { BiSolidBell } from "react-icons/bi";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/Context';

const TrainerHeader = () => {

    let { state, dispatch } = useContext(GlobalContext);
    const username = state?.user?.name;
    const userAvatar = state?.user?.image;
    const navigate = useNavigate();

    return (
        <header>
            <div className="main-header">
                <div className="back-button" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                    <div className="">
                        <IoArrowBack style={{ color: "#0163f4", fontSize: "24px" }} />
                    </div>
                    <p className="back-button-tx">Back</p>
                </div>
                <div className="header-side-two">
                    <div className="">
                        <BiSolidBell style={{ color: "#0163f4", fontSize: "24px" }} />
                    </div>
                    <div className="user-info">
                        <div className="user-img">
                            <img src={userAvatar} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                        </div>
                        <div className="user-name-id">
                            <h3 className="user-name">{username}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default TrainerHeader;