import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import detailsIcon from "../images/eye-for-details-option.png";
import AssessorSideBar from "./AssessorSideBar";
import Footer from "./Footer";
import Header from "./Header";

const Students = () => {

    const baseurl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [studentData, setStudentData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [search, setSearch] = useState("");


    const getStudentData = async (token, perPage, page) => {
        try {
            let result = await fetch(`${baseurl}/api/students?per_page=${perPage}&page=${page}&search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch student data');
            }

            result = await result.json();
            setStudentData(result);
            const totalItems = result?.total;
            const totalPages = Math.ceil(totalItems / recordsPerPage);
            setTotalPages(totalPages);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getStudentData(JSON.parse(auth).token, recordsPerPage, currentPage);
        } else {
            navigate('/');
        }
    }, [currentPage, search, recordsPerPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <Header />
                    <section className="Dashbord-page-3-sec-one py-lg-0">
                        <div className="assessor-dashbord-container">
                            <div className="Dashbord-3-top-boxes-page">
                                <div className="Overall-Progress">
                                    <div className="Overall-Progress-txs">
                                        <h2 className="Overall-Progress-tx2">Student List</h2>
                                    </div>
                                    <div className="Assignments-that-needs-grading-select-boxes ">
                                        <input type="text" placeholder="Search...." value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <select name="rows-per-page" id="rows-per-page" placeholder="Show rows per page" value={recordsPerPage} onChange={(e) => setRecordsPerPage(e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                            <option value="60">60</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="Assessor-Dashbord-page1-sec-two">
                        <div className="assessor-dashbord-container">
                            <div className="table-responsive">
                                <table className="dashbord-table">
                                    <tr className="First-line">
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Course</th>
                                        <th>Enrolled Date</th>
                                        <th className="dashboard-table-actions"></th>
                                    </tr>
                                    {studentData?.data?.map((item, index) => (
                                        <tr className="Assignments ">
                                            <td className="grading"><img src={item?.photo} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" /></td>
                                            <td className="grading">{item?.name}</td>
                                            <td className="grading">{item?.course_name}</td>
                                            <td className="grading">{new Date(item?.enrolled_date).toLocaleString('en-GB').slice(0, 10)}</td>
                                            <td className="grading action">
                                                <Link to={`/studentprofile/${item?.id}`}>
                                                    <img src={detailsIcon} alt="" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>

                            <div className="d-flex justify-content-between align-items-end mt-3">
                                <div>
                                    <p className="text-secondary fw-bold">
                                        {((currentPage - 1) * recordsPerPage) + 1}-{Math.min((currentPage * recordsPerPage), studentData?.total || 0)} of {studentData?.total || 0}
                                    </p>
                                </div>
                                <div>
                                    <ul className="pagination">
                                        {currentPage !== 1 && (
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                >
                                                    &lt;
                                                </a>
                                            </li>
                                        )}
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li
                                                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                                key={index + 1}
                                            >
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                        {currentPage !== totalPages && (
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                >
                                                    &gt;
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </section>
            </div >
        </>
    )
}

export default Students;