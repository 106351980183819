import Modal from 'react-bootstrap/Modal';
function FeedBackModal({ showModal, handleClose, Question, data }) {
    return (
        <>
            <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Feedback History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className='px-5'>
                        <p className='p-2'><b>Question:</b> <span dangerouslySetInnerHTML={{ __html: Question }}></span></p>
                        <div >
                            <table>
                                <tr className="First-line">
                                    <th>Date</th>
                                    <th>Feedback</th>
                                </tr>
                                {data?.map((item, index) =>
                                    <>
                                        <tr key={index} className="Assignments" >
                                            <td className="grading">{
                                                item?.date
                                            }</td>
                                            <td className="grading">{item?.feedback}</td>
                                        </tr>
                                    </>
                                )}
                            </table>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FeedBackModal;
