import React, { useState } from 'react'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'
import { Button } from 'react-bootstrap'
 import { useParams, useNavigate } from 'react-router-dom'

function AddReview() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const [review_date, setReviewDate] = useState("")
    const [review_time, setReviewTime] = useState("")
    const [video_call_link, setVideoCallLink] = useState("")
    const { id } = useParams() //This is student id

    const navigate = useNavigate()

    const addReview = async () => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if(review_date=="" || review_time=="" || video_call_link=="" ){
            return
        }
        try {
            let result = await fetch(`${baseurl}/api/review`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: id, review_date, review_time, video_call_link })
            });
            if (result.ok) {
                alert("Review Submitted")
                navigate(-1)
            }
        }
        catch(error){
            console.log(error)
        }
    }
    
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                                <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Add Reviews</h1>
                                <form>
                                    <div className="top-fields">
                                        <div className="form-col-one">
                                            <div className="field">
                                                <h5>Review Date</h5>
                                                <input type="date" value={review_date} onChange={(e) => setReviewDate(e.target.value)} />
                                            </div>
                                            <div className="field">
                                                <h5>Review Time</h5>
                                                <input type="time" value={review_time} onChange={(e) => setReviewTime(e.target.value)} />
                                            </div>
                                            <div className="field w-100">
                                                <h5>Video Link</h5>
                                                <input type="text" value={video_call_link} onChange={(e) => setVideoCallLink(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" onClick={addReview}>Submit</Button>
                                </div>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default AddReview;