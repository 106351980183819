import JoditEditor from 'jodit-react'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'
import { config } from './JoditConfig'

function AssessorEditComments() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const { id } = useParams()
    const { studentid } = useParams()
    const [availableReviewDates, setAvailableReviewDates] = useState([])
    const navigate = useNavigate()
    const [commentData, setCommentData] = useState({
        user_id: "",
        review_made: "",
        location: "",
        description: ""
    })
    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if (token) {
            getComments(token)
            getReviews()
        }
    }, [])
    const getComments = async (token) => {
        let result = await fetch(`${baseurl}/api/planned-comment/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setCommentData({
            user_id: result?.user_id,
            review_made: new Date(result?.review_made).toISOString().substring(0, 10),
            location: result?.location,
            description: result?.description
        });
    }
    const getReviews = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        let result = await fetch(`${baseurl}/api/get-review/${studentid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setAvailableReviewDates(result)
    }
    const editcomment = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/planned-comment/${id}`, {
                method: 'PUT',
                headers: {
                    'Acccept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(commentData)
            });
            if (result.ok) {
                alert("Comment edited")
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                            <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Review Comments</h1>
                                <form>
                                    <div className="top-fields">
                                        <div className="form-col-one">
                                            <div className="field">
                                                <h5>Review Date</h5>
                                                <select value={commentData?.review_made}
                                                    onChange={(e) =>
                                                        setCommentData({
                                                            ...commentData,
                                                            review_made: e.target.value,
                                                        })
                                                    }>
                                                    <option value="" className='text-secondary' disabled selected hidden>Select date</option> { }
                                                    {availableReviewDates?.map((item, index) =>
                                                        <option value={item?.review_date}>{item?.review_date}</option>
                                                    )}
                                                </select>                                           </div>
                                            <div className="field">
                                                <h5>Location</h5>
                                                <input type="text" value={commentData?.location}
                                                    onChange={(e) =>
                                                        setCommentData({
                                                            ...commentData,
                                                            location: e.target.value,
                                                        })}
                                                    placeholder="Job Date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="location-field">
                                        <h5 className="Job-Location">Review Comments</h5>
                                        <br></br>
                                        <JoditEditor
                                            value={commentData?.description}
                                            onBlur={(e) =>
                                                setCommentData({
                                                    ...commentData,
                                                    description: e,
                                                })}
                                            config={config}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Button varaint="primary" onClick={editcomment}>Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default AssessorEditComments;