import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import editForDetails from "../images/edit-for-details.png";
import AssessorSideBar from "./AssessorSideBar";
import DashboardHeader from "./DashboardHeader";
import Footer from "./Footer";

const AssessorDashboard = () => {
    
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [currentPage, setCurrentPage] = useState(1);
    const [dashboardData, setDashboardData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const recordsPerPage = 10;
    const getDashboardData = async (token, perPage, page) => {
        try {
            let result = await fetch(`${baseurl}/api/assessor-dashboard-data?per_page=${perPage}&page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch dashboard data');
            }
            result = await result.json();
            setDashboardData(result);
            // Update the total number of pages based on the fetched data
            const totalItems = result?.assignments?.total;
            const totalPages = Math.ceil(totalItems / recordsPerPage);
            setTotalPages(totalPages);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getDashboardData(JSON.parse(auth).token, recordsPerPage, currentPage);
        } else {
            navigate('/');
        }
    }, [currentPage]);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <DashboardHeader />
                    <section className="Dashbord-page-3-sec-one">
                        <div className="assessor-dashbord-container">
                            <div className="Dashbord-3-top-boxes-page">
                                <div className="Dashbord-page-3-top-box-1">
                                    <h1 className="Welcome-Back-heading">{dashboardData?.students_count}</h1>
                                    <p className="Welcome-Back-tag-line">Total Number of Students
                                        {' '}
                                        <Link to="/students">
                                            Click to see
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="Assessor-Dashbord-page1-sec-two">
                        <h5 className="pb-4">Assignments Ready For Grading</h5>
                        <div className="assessor-dashbord-container">
                            <div className="table-responsive">
                                <table className="dashbord-table">
                                    <tr className="First-line">
                                        <th>Photo</th>
                                        <th>Name</th>
                                        <th>Unit Number</th>
                                        <th>Types of Assignment</th>
                                        <th>Submitted Date</th>
                                        <th className="dashboard-table-actions"></th>
                                    </tr>
                                    {dashboardData?.assignments?.data?.filter((item) =>
                                        item.type_of_assignment === "Cross Reference" ||
                                        item.type_of_assignment === "Underpinning Knowledge Questions"
                                    ).map((item, index) => (
                                        <tr className="Assignments" key={index}>
                                            <td className="grading">
                                                <img src={item?.photo} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                                            </td>
                                            <td className="grading">{item?.name}</td>
                                            <td className="grading">{item?.unit_number}</td>
                                            <td className="grading">{item?.type_of_assignment}</td>
                                            <td className="grading">{new Date(item?.submitted_at).toLocaleString('en-GB').slice(0, 10)}</td>
                                            <td className="grading action">
                                                <div onClick={() => {
                                                    localStorage.setItem('activeTab', 'tab2');
                                                    localStorage.setItem('activeUnit', `u-${item?.course_id}`);
                                                    localStorage.setItem('activeType', item?.type_of_assignment);
                                                }}>
                                                    <Link to={`/studentprofile/${item?.id}`}><img className="detailsIcon" src={editForDetails} alt="" /></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <div className="d-flex justify-content-between align-items-end mt-3">
                                <div>
                                    <p className="text-secondary fw-bold">
                                        {((currentPage - 1) * recordsPerPage) + 1}-{Math.min((currentPage * recordsPerPage), dashboardData?.assignments?.total || 0)} of {dashboardData?.assignments?.total || 0}
                                    </p>
                                </div>
                                <div>
                                    <ul className="pagination">
                                        {currentPage !== 1 && (
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage - 1)}
                                                >
                                                    &lt;
                                                </a>
                                            </li>
                                        )}
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li
                                                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                                                key={index + 1}
                                            >
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(index + 1)}
                                                >
                                                    {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                        {currentPage !== totalPages && (
                                            <li className="page-item">
                                                <a
                                                    className="page-link"
                                                    onClick={() => handlePageChange(currentPage + 1)}
                                                >
                                                    &gt;
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </section>
            </div>
        </>
    );
};
export default AssessorDashboard;
