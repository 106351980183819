import React, { useContext, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import Dashboard from "../images/das-Dashboard.png";
import calendar from "../images/das-calendar.png";
import frame from "../images/das-frame.png";
import messages from "../images/das-messages.png";
import dashboardlogo from "../images/dashboardlogo.png";
import logoutIcon from "../images/logout2.png";

const SideBar = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    const navigate = useNavigate();
    let { state, dispatch } = useContext(GlobalContext);
    const [unreadChatCount, setUnreadChatCount] = useState('')
    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("lastPath");
        localStorage.removeItem("activeType");
        dispatch({
            type: "USER_LOGOUT"
        })
        navigate('/');
    }
    const role = state?.user?.role

    const getUnreadChatCount = async () => {
        const auth = localStorage.getItem('user');
        try {
            let result = await fetch(`${baseurl}/api/chat-unread-count
            `, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(auth).token}`
                }
            });
            result = await result.json();
            setUnreadChatCount(result?.unread_count)
        }
        catch {
            console.log("error");
        }
    }

    useEffect(() => {
        getUnreadChatCount()
    }, [])

    return (
        <>
            <section className="sidebarContainer">
                <section className="sidebar">
                    <div className="logo">
                        <div className="img">
                            <img src={dashboardlogo} alt="" />
                        </div>
                        <div className="txt">
                            <h2>onwards & Upwards <br></br>Training</h2>
                        </div>
                    </div>
                    <div className="sidebar-nav">
                        <ul>
                            <Link to="/">
                                <li>
                                    <img src={Dashboard} alt="" />
                                    <h3>Dashboard</h3>
                                </li>
                            </Link>
                            <Link to="/calender">
                                <li>
                                    <img src={calendar} alt="" />
                                    <h3>Calendar</h3>
                                </li>
                            </Link>
                            {role != 5 && <Link to="/chat/0">
                                <li className="d-flex justify-content-between">
                                    <span className="d-flex">
                                        <img src={messages} alt="" />
                                        <h3>Chat</h3>
                                    </span>
                                    <span>
                                        <Badge bg="danger" pill>{unreadChatCount !=0 ? unreadChatCount : ""}</Badge>
                                    </span>
                                </li>
                            </Link>}
                            <Link to="/profile">
                                <li>
                                    <img src={frame} alt="" />
                                    <h3>Profile</h3>
                                </li>
                            </Link>
                            <li className="logout" onClick={logout}>
                                <img src={logoutIcon} alt="" />
                                <h3>Log Out</h3>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </>
    )
}

export default SideBar;