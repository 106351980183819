import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'

 function EditReview() {

    const { id } = useParams()
    const navigate = useNavigate()
    const baseurl = process.env.REACT_APP_BASE_URL
    const [reviewData, setReviewData] = useState({
        user_id: "",
        review_date: "",
        review_time: "",
        video_call_link: "",
    });

    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if (token) {
            getReviews(token)
        }
    }, [])

    const formatTimeToHHMM = (time) => {
        const [hours, minutes] = time.split(":");
        const formattedTime = `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        return formattedTime;
    };

    const getReviews = async (token) => {
        let result = await fetch(`${baseurl}/api/review/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setReviewData({
            user_id: result?.user_id,
            review_date: new Date(result?.review_date).toISOString().substring(0, 10),
            review_time: formatTimeToHHMM(result?.review_time),
            video_call_link: result?.video_call_link,
        });
    }

    const handleFormSubmit = async () => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token;
        try {
            let result = await fetch(`${baseurl}/api/review/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(reviewData),
            });
            if (result.ok) {
                alert("review edited")
                navigate(-1)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                                <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Edit Reviews</h1>
                                <form>
                                    <div className="top-fields">
                                        < div className="form-col-one" >
                                            <div className="field">
                                                <h5>Review Date</h5>
                                                <input
                                                    type="date"
                                                    value={reviewData?.review_date}
                                                    onChange={(e) =>
                                                        setReviewData({
                                                            ...reviewData,
                                                            review_date: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="field">
                                                <h5>Review Time</h5>
                                                <input
                                                    type="time"
                                                    value={reviewData?.review_time}
                                                    onChange={(e) =>
                                                        setReviewData({
                                                            ...reviewData,
                                                            review_time: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <div className="field w-100">
                                                <h5>Video Link</h5>
                                                <input
                                                    type="text"
                                                    value={reviewData?.video_call_link}
                                                    onChange={(e) =>
                                                        setReviewData({
                                                            ...reviewData,
                                                            video_call_link: e.target.value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" onClick={handleFormSubmit}>Submit</Button>
                                </div>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default EditReview;