import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'

function AddOverallProgrammeAssessment() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const [startDate, setStartDate] = useState("")
    const [plannedEndDate, setPlannedEndDate] = useState("")
    const [onTarget, setOnTarget] = useState("")
    const [anticipated_completed_date, setanticipated_completed_date] = useState("")
    const { id } = useParams()
    const navigate = useNavigate()
    const submitOverall = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/overall-program-assessment`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    user_id: id,
                    start_date: startDate,
                    planned_end_date: plannedEndDate,
                    anticipated_completed_date,
                    month_left: 4,
                    on_target: onTarget,
                })
            });
            if (result.ok) {
                alert("comments submitted")
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                                <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Add Details</h1>
                                <form>
                                    <div className="top-fields">
                                        <div className="form-col-one">
                                            <div className="field">
                                                <h5>Start Date</h5>
                                                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder="Review Date" />
                                            </div>
                                            <div className="field">
                                                <h5>Planned End Date</h5>
                                                <input type="date" value={plannedEndDate} onChange={(e) => setPlannedEndDate(e.target.value)} placeholder="Review Time" />
                                            </div>
                                            <div className="field">
                                                <h5>Anticipated Comp. Date</h5>
                                                <input type="date" value={anticipated_completed_date} onChange={(e) => setanticipated_completed_date(e.target.value)} placeholder="Job Date" />
                                            </div>
                                            <div className="field ">
                                                <h5>On Target</h5>
                                                <input type="text" value={onTarget} onChange={(e) => setOnTarget(e.target.value)} placeholder="On Target" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" onClick={submitOverall}>Submit</Button>
                                </div>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default AddOverallProgrammeAssessment;