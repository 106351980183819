import React, { useContext, useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { GlobalContext } from '../context/Context';

function DashboardSlider() {

    const baseurl = process.env.REACT_APP_BASE_URL
    let { state, dispatch } = useContext(GlobalContext);
    const qaSelectedLearner = state?.qaSelectedLearner
    const role = state?.user?.role
    const [plannedComments, setPlannedComments] = useState([]);
    const [allPlannedComments, setAllPlannedComments] = useState([]);
    const [PlannedCommentsYears, setPlannedCommentsYears] = useState([]);

    const getPlannedComments = async () => {
        const auth = localStorage.getItem('user');
        const token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/get-planned-comment-by-login${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setPlannedComments(result);
            setAllPlannedComments(result);
        }
        catch (error) {
            console.log(error);
        }
    }

    const getPlannedCommentsYears = async () => {
        const auth = localStorage.getItem('user');
        const token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/comment-years${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setPlannedCommentsYears(result);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getPlannedComments()
        getPlannedCommentsYears()
    }, [qaSelectedLearner])

    const arrowStyle = {
        color: "red",
        fontSize: "36px",
        textAlign: "center",
        borderRadius: "50%",
        cursor: "pointer",
        marginTop: "-100px"
    };

    const CustomPrevArrow = (props) => (
        <div
            {...props}
            style={{
                ...props.style,
                ...arrowStyle,
            }}
        >
        </div>
    );

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomPrevArrow />,
    };

    const sessionYearChanged = (event) => {
        let comments = allPlannedComments.filter((item) => {
            if (item.review_made.includes(event.target.value)) {
                return item;
            }
        });
        setPlannedComments(comments);
    }

    return (
        <div>
            <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                <label for="years" >Session Year</label>
                <select id="years" name="year" className="mx-2 rounded" onChange={(event) => {
                    sessionYearChanged(event)
                }}>
                    <option value="">Show All</option>
                    {PlannedCommentsYears?.map((item, index) =>
                        <>
                            <option value={item.date}>{item.date}</option>
                        </>
                    )}
                </select>
            </div>
            <div className='slidercontainer'>
                <Slider {...settings}>
                    {
                        (plannedComments?.length > 0) ?
                            plannedComments?.map((item, index) =>
                                < div key={index} className='SlideMain' >
                                    <div>
                                        <div className='d-flex col justify-content-center'>
                                            <div className='text-center'>
                                                <div className='d-flex justify-content-center my-2'>
                                                    <img src={item?.assessor?.image} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                                                </div>
                                                <h6>{item?.assessor?.name}</h6>
                                            </div>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: item?.description }}>
                                        </p>
                                        <div className='sliderDates'>
                                            <div className='d-flex'><span>Location: </span><span><p className='text-primary fw-bold mx-2'>{item?.location}</p></span></div>
                                            <div className='d-flex'>Review Made: <span><p className='text-primary fw-bold mx-2'>{new Date(item?.review_made).toLocaleDateString('en-GB')}</p></span></div>
                                        </div>
                                    </div>
                                </div>

                            )
                            :
                            <p className='text-secondary text-center h5'><i>No Comments</i></p>
                    }


                </Slider >
            </div>
        </div >
    );

}

export default DashboardSlider