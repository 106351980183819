import React from 'react'
import devicemessage from "../images/device-message.png";
import messagequestion from "../images/message-question.png";
import book from "../images/book.png";
import { Link } from 'react-router-dom';

function SupportFooter() {

    return (
        <>
            <section className="sec-5-unit-Overview bg-white">
                <div className="sec-5-unit-Overview-container">
                    <div className="main-sec-5-unit-Overview">
                        <h3 className="sec-5-unit-Overview-heading">Need Some Assistance?</h3>
                        <Link to="https://out-lifts.com/how-to-use-the-platform">
                            <div className="Study-Guide">
                                <div className="Study-Guide-icon">
                                    <img src={book} alt="" />
                                </div>
                                <p className="Study-Guide-tx">How To Use The Platform</p>
                            </div>
                        </Link>
                        <div className="Study-Guide">
                            <div className="Study-Guide-icon">
                                <img src={messagequestion} alt="" />
                            </div>
                            <p className="Study-Guide-tx">FAQs</p>
                        </div>
                        <div className="Study-Guide">
                            <div className="Study-Guide-icon">
                                <img src={devicemessage} alt="" />
                            </div>
                            <p className="Study-Guide-tx">Contact your Assessor</p>
                        </div>
                    </div>
                </div>
            </section>
        </>)
}

export default SupportFooter