import { Carousel } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../images/Group1000001554.png";
import MyModal from "./Modal";

const ForgotPassword_email = () => {
    
    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const baseurl = process.env.REACT_APP_BASE_URL;

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handellogin = async (e) => {
        e.preventDefault()
        if (!email) {
            setShowModal(true);
        }
        else {
            await getEmail(email)
        }
    }

    const getEmail = async (email) => {
        let result = await fetch(`${baseurl}/api/auth/forget-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "email": email })
        });
        if (!result.ok) {
            alert("Email not Registered")
            return
        }
        if (result.ok) {
            navigate(`/resetpassword/${email}`)
        }
    }
    
    return (
        <>
            <div className="LoginBody row row-no-gutters mx-0 " >
                <div className="col-sm-4 px-md-0 px-1">
                    <div className="form-style">
                        <div className="login-one">
                            <div className="main-login Loginform-container">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="icon-text">
                                        <p className="icon-tx">onwards & Upwards </p>
                                        <p className="icon-tx">Training </p>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="login-form">
                                    <h2 className="Hello">Forgot Password</h2>
                                    <p className="hello-tag">Enter Your Registered Email</p>
                                    <div className="main-form">
                                        <form onSubmit={handellogin}>
                                            <div className="form-group pb-md-3 pb-3">
                                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" name="uname" required />
                                            </div>
                                            <button className="LoginButton" type="submit">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 d-none d-md-block  px-0">
                    <div className="login-two">
                        <div className="crousel">
                            <Carousel>
                                <div>
                                    <h3>Lift & Learn</h3>
                                    <p className="crousel-tx">
                                        Hey there, future elevator expert! Ready to elevate your skills? Dive in!</p>
                                </div>
                                <div>
                                    <h3>Skyward Elevations</h3>
                                    <p className="crousel-tx">Reach for the stars! Every login here takes you a level up. Let's do this!</p>
                                </div>
                                <div>
                                    <h3>Peak Performance Lift</h3>
                                    <p className="crousel-tx">Hello, game changer! Every assessment, a step closer to the top.</p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <MyModal showModal={showModal} handleClose={handleCloseModal} />
        </>
    )
}
export default ForgotPassword_email;
