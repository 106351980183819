import { Badge, Calendar } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import Header from "./Header";
import SideBar from "./SideBar";

const Calender = () => {

    const baseurl = process.env.REACT_APP_BASE_URL;
    const [calendarData, setCalendarData] = useState([])
    const [value, setValue] = useState();
    const [selectedDateEvent, setSelectedDateEvent] = useState([]);
    let { state, dispatch } = useContext(GlobalContext);
    const qaSelectedLearner = state?.qaSelectedLearner
    const role = state?.user?.role
    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    }
    const onSelect = async (newValue) => {
        setValue(newValue);
        var date = { "date": newValue.format('YYYY-MM-DD') }
        const auth = localStorage.getItem("user");
        try {
            let result = await fetch(`${baseurl}/api/get-events${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(auth).token}`
                },
                body: JSON.stringify(date)
            });
            if (!result.ok) {
                throw new Error('Failed to fetch calendar data');
            }
            result = await result.json();
            setSelectedDateEvent(result)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const onPanelChange = (newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate()
    const getCalendarData = async (token) => {
        try {
            let result = await fetch(`${baseurl}/api/calendar${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch calendar data');
            }
            result = await result.json();
            setCalendarData(result);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        const auth = localStorage.getItem("user");
        if (auth) {
            getCalendarData(JSON.parse(auth).token);
        } else {
            navigate("/");
        }
    }, [qaSelectedLearner]);
    const dateCellRender = (value) => {
        const eventsForDate = calendarData.filter((item) => item.date === value.format("YYYY-MM-DD"));
        return (
            eventsForDate?.map((item, index) => (
                <ul className="events p-0">
                    <li key={index}>
                        <Badge
                            status={"success"}
                            text={truncateText(item.title, 5)}
                        />
                    </li>
                </ul >
            ))
        );
    };
    const monthCellRender = (value) => {
        if (value.month() === 8) {
            const backlogNumber = calendarData.filter((item) => item.category).length;
            return (
                <div className="notes-month">
                    <section>{backlogNumber}</section>
                    <span>Backlog number</span>
                </div>
            );
        }
        return null;
    };
    return (
        <>
            <div className="das-main">
                <SideBar />
                <section className="das-cntn">
                    <Header />
                    <section className="Dashbord-page3-sec-two">
                        <div className="dashbord-container">
                            <div className="Dashbord-sec-two">
                                <div className="Calander-page-sec-one-left">
                                    <Calendar value={value} onSelect={onSelect} dateCellRender={dateCellRender} monthCellRender={monthCellRender} onPanelChange={onPanelChange} />
                                </div>
                                <div className="Calander-page-sec-one-right">
                                    <h2 className="Calander-page-sec-one-right-heading">Tasks</h2>
                                    <br></br>
                                    {
                                        (selectedDateEvent?.map((item, index) =>
                                            <>
                                                <h3 className="Tasks-title mb-2">{item?.category}</h3>
                                                <p className="Tasks-tx text-black mb-2"><b>{item?.title}</b></p>
                                                <p className="Tasks-tx  m-0 py-0"><b>{item?.time ? item?.time : new Date(item?.date).toLocaleString('en-GB').slice(0, 10)}</b></p>
                                                <p className="Tasks-tx">{item?.note}</p>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}
export default Calender;