import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'

function EditOverallProgrammeAssessment() {
    
    const [overallprogramme, setOverallProgramme] = useState({
        user_id:"",
        start_date: "",
        planned_end_date: "",
        anticipated_completed_date: "",
        month_left: "",
        on_target: "",
    })
    const { id } = useParams()
    const baseurl = process.env.REACT_APP_BASE_URL
    const navigate = useNavigate()

    useEffect(() => {
        getOverallProgrammeAssessment()
    }, [])

    const getOverallProgrammeAssessment = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/overall-program-assessment/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setOverallProgramme({
                user_id:result?.user_id,
                start_date: new Date(result?.start_date).toISOString().substring(0, 10),
                planned_end_date: new Date(result?.planned_end_date).toISOString().substring(0, 10),
                anticipated_completed_date: new Date(result?.anticipated_completed_date).toISOString().substring(0, 10),
                month_left: result?.months_left,
                on_target: result?.on_target,
            })
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const submitOverall = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/overall-program-assessment`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(overallprogramme)
            });
            if (result.ok) {
                alert("overall details edited")
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                                <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Edit Details</h1>
                                <form>
                                    <div className="top-fields">
                                        <div className="form-col-one">
                                            <div className="field">
                                                <h5>Start Date</h5>
                                                <input type="date"
                                                    value={overallprogramme?.start_date}
                                                    onChange={(e) =>
                                                        setOverallProgramme({
                                                            ...overallprogramme,
                                                            start_date: e.target.value,
                                                        })
                                                    } placeholder="Review Date" />
                                            </div>
                                            <div className="field">
                                                <h5>Planned End Date</h5>
                                                <input type="date"
                                                    value={overallprogramme?.planned_end_date}
                                                    onChange={(e) =>
                                                        setOverallProgramme({
                                                            ...overallprogramme,
                                                            planned_end_date: e.target.value,
                                                        })
                                                    } placeholder="Review Date" />
                                            </div>
                                            <div className="field">
                                                <h5>Anticipated Comp. Date</h5>
                                                <input type="date"
                                                    value={overallprogramme?.anticipated_completed_date}
                                                    onChange={(e) =>
                                                        setOverallProgramme({
                                                            ...overallprogramme,
                                                            anticipated_completed_date: e.target.value,
                                                        })
                                                    } placeholder="Review Date" />
                                            </div>
                                            <div className="field ">
                                                <h5>On Target</h5>
                                                <input type="text"
                                                    value={overallprogramme?.on_target}
                                                    onChange={(e) =>
                                                        setOverallProgramme({
                                                            ...overallprogramme,
                                                            on_target: e.target.value,
                                                        })
                                                    } placeholder="Review Date" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='d-flex justify-content-end'>
                                    <Button variant="primary" onClick={submitOverall}>Submit</Button>
                                </div>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default EditOverallProgrammeAssessment;