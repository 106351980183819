import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Groupside from "../images/Group-side.png";
import calendar from "../images/calendar-2.png";
import CalendarModal from "./CalendarModal";
import CalendarModal_Edit from "./CalendarModal_Edit";
import DeleteReviewModal from './DeleteReviewModal';
import DeleteTaskModal from './DeleteTaskModal';


const StudentProfileCalendar = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const [isDropdownOpen, setIsDropdownOpen] = useState([]);
    const [calendarData, setCalendarData] = useState([])
    const [editCalendarData, setEditCalendarData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [reviews, setReviews] = useState([])
    const navigate = useNavigate();
    const { id } = useParams()

    const showReviewModalFunction = () => {
        setShowReviewModal(true)
    }

    const handleClose = () => {
        setShowReviewModal(false)
    }

    const handleToggleDropdown = (itemId) => {
        setIsDropdownOpen((prevState) => ({
            ...prevState,
            [itemId]: !prevState[itemId],
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setShowTaskModal(false)
    };
    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    const addCalendarData = (newCalendar) => {
        setCalendarData((prevCalendarData) => [...prevCalendarData, newCalendar]);
    };

    const handleEditCalendar = (calendar) => {
        setEditCalendarData(calendar);
        setShowModal2(true);
    };

    const handleDeleteCalendar = (calendar) => {
        setShowTaskModal(true);
    };

    const review = () => {
        navigate(`/addreview/${id}`)
    }

    const handleEditSave = (updatedCalendar) => {
        setCalendarData((prevCalendarData) => {
            const index = prevCalendarData.findIndex((item) => item.id === updatedCalendar.id);

            const updatedData = [...prevCalendarData];
            updatedData[index] = updatedCalendar;

            return updatedData;
        });
    };

    const getCalendarData = async (token) => {
        try {
            let result = await fetch(`${baseurl}/api/calendar?user_id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch dashboard data');
            }

            result = await result.json();
            setCalendarData(result);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const deleteCalendarData = async (token, item) => {
        try {
            var id = item?.id
            var result = await fetch(`${baseurl}/api/calendar/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (result.ok) {
                setShowTaskModal(false)
            }
            setCalendarData((prevCalendarData) =>
                prevCalendarData.filter((record) => record.id !== id)
            );
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getReviews = async (token) => {
        let result = await fetch(`${baseurl}/api/get-review/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setReviews(result)
    }


    const deleteReview = async (id) => {

        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        let result = await fetch(`${baseurl}/api/review/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        if (result.ok) {
            getReviews(token)
            setShowReviewModal(false)
        }
    }

    useEffect(() => {
        const auth = localStorage.getItem("user");
        if (auth) {
            getCalendarData(JSON.parse(auth).token);
            getReviews(JSON.parse(auth).token)

        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            <section className="sec-2-Student-Profile-Progress">
                <div className="container">
                    <div className="calendar-heading-button d-flex flex-wrap ">
                        { }
                        <div className="calendar-buttongroup pl-10">
                            <button
                                type="submit"
                                className="btns2"
                                onClick={() => review()}
                            >
                                Create Review
                            </button>
                            <button
                                type="submit"
                                className="btns2"
                                onClick={() => setShowModal(true)}
                            >
                                Create Task
                            </button>
                        </div>
                        <CalendarModal showModal={showModal} handleClose={handleCloseModal} handleSave={addCalendarData} />
                        <CalendarModal_Edit showModal={showModal2} handleClose={handleCloseModal2} calendarData={editCalendarData} handleSave={handleEditSave} />
                    </div>
                    <div className="calendar-boxes">
                        {calendarData.map((item, index) => (
                            <div className="box-1" key={index}>
                                <div className="box-top-bar">
                                    <div className="Deadline-icon-box">
                                        <div className="Deadline-icon">
                                            <img src={calendar} alt="" />
                                        </div>
                                        <h3 className="Deadline-tx my-2">Task</h3>

                                    </div>
                                    <div className="side-icon">
                                        <img
                                            src={Groupside}
                                            alt=""
                                            onMouseEnter={() => handleToggleDropdown(`task_${index}`)}
                                        />
                                        {isDropdownOpen[`task_${index}`] && (
                                            <div className="dropdownCalendar"
                                                onMouseLeave={() => handleToggleDropdown(`task_${index}`)}
                                            >
                                                <option
                                                    className="dropdown-item"
                                                    value="Edit"
                                                    onClick={() => handleEditCalendar(item)}
                                                >
                                                    Edit
                                                </option>
                                                <hr className="my-1 p-0" />
                                                <option
                                                    className="dropdown-item"
                                                    value="Delete"
                                                    onClick={() => handleDeleteCalendar(item)}
                                                >
                                                    Delete
                                                </option>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className="Deadline-date">{new Date(item?.date).toLocaleString('en-GB').slice(0,10)}</p>
                                <h4 className="Title-goes-here">{item?.title}</h4>
                                <br></br>
                                <p className="Personal-note">Description Of Task</p>
                                <p className="Lorem text-wrap">{item?.note}</p>
                                <DeleteTaskModal showModal={showTaskModal} handleClose={handleClose} DeleteFunction={() => deleteCalendarData(JSON.parse(localStorage.getItem("user")).token, item)} />
                            </div>
                        ))}

                        {reviews?.map((item, index) => (
                            <div className="box-1" key={index}>
                                <div className="box-top-bar">
                                    <div className="Deadline-icon-box">
                                        <div className="Deadline-icon">
                                            <img src={calendar} alt="" />
                                        </div>
                                        <h3 className="Deadline-tx m-2">Review</h3>
                                    </div>
                                    <div className="side-icon">
                                        <img
                                            src={Groupside}
                                            alt=""
                                            onMouseEnter={() => handleToggleDropdown(`review_${index}`)}
                                        />
                                        {isDropdownOpen[`review_${index}`] && (
                                            <div className="dropdownCalendar"
                                                onMouseLeave={() => handleToggleDropdown(`review_${index}`)}
                                            >
                                                <Link style={{ textDecoration: "none", color: "#000" }} to={`/editreview/${item?.id}/${id}`}>
                                                    <option
                                                        className="dropdown-item"
                                                        value="Edit"
                                                    >
                                                        Edit
                                                    </option>
                                                </Link>
                                                <hr className="my-1 p-0" />
                                                <option
                                                    className="dropdown-item"
                                                    value="Delete"
                                                    onClick={() => showReviewModalFunction()}
                                                >
                                                    Delete
                                                </option>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className="Deadline-date">{new Date(item?.review_date).toLocaleString('en-GB').slice(0,10)}</p>
                                <h4 className="Title-goes-here">{item?.review_time}</h4>
                                <br></br>
                                <p className="Personal-note">Video Call Link</p>
                                <p className="Lorem text-wrap">{item?.video_call_link}</p>
                                <DeleteReviewModal showModal={showReviewModal} handleClose={handleClose} DeleteFunction={() => deleteReview(item?.id)} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default StudentProfileCalendar;


