import Pusher from 'pusher-js'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Header from "./Header"
import SideBar from "./SideBar"

function LearnerNotifications() {

    const { id } = useParams()
    var channel;
    var pusher;
    const baseurl = process.env.REACT_APP_BASE_URL
    const [userNotification, setUserNotification] = useState([])
    const getNotifications = async (token) => {
        try {
            let result = await fetch(`${baseurl}/api/notifications?read=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch notifications data');
            }
            result = await result.json();
            setUserNotification(result?.notifications)
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const PusherNotifications = () => {
         Pusher.logToConsole = false;
        pusher = new Pusher('4a0829c13e55b642e1ae', {
            cluster: 'eu'
        });
        channel = pusher.subscribe(`notification-${id}`);
        channel.bind('receive', function (data) {
            setUserNotification((prevNotifications) => [data.data, ...prevNotifications]);
        });
    }
    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if (token) {
            getNotifications(token)
            PusherNotifications()
        }
        return () => {
            channel.unbind('receive');
            channel.unsubscribe(`notification-${id}`);
            channel.disconnect();
        };
    }, [])

    return (
        <>
            <div className="das-main">
                <SideBar />
                <section className="das-cntn">
                    <Header />
                    <div className='notifications'>
                        {userNotification?.map((item, index) =>
                            <Link style={{ textDecoration: "none", color:"inherit" }} to={item?.route_to}>
                                < div className="notification-container" key={index} >
                                    <div className='notification-container-image'>
                                        <img src={item?.fromuser?.image} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                                    </div>
                                    <div className='notification-container-text'>
                                        <div className='d-flex justify-content-between'>
                                            <div >
                                                <h6 className='mb-2'><b>{item?.fromuser?.name}</b></h6>
                                            </div>
                                            <div>
                                                <p className='notification-date'>
                                                    {new Date(item?.created_at).toLocaleString()}
                                                </p>
                                            </div>
                                        </div >
                                        <div >
                                            <p className='m-0 p-0' style={{ fontSize: "16px" }}>
                                                {item?.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>)}
                    </div>
                </section >
            </div >
        </>
    )
}
export default LearnerNotifications