import { Carousel } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import img1 from "../images/Group1000001554.png";
import MyModal from "./Modal";

const Login = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    let { state, dispatch } = useContext(GlobalContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const checkUserAndNavigate = () => {
        return new Promise((resolve, reject) => {
            const auth = localStorage.getItem('user');
            if (auth) {
                const parsedAuth = JSON.parse(auth);
                resolve(parsedAuth);
            }
        });
    };
    useEffect(() => {
        checkUserAndNavigate()
            .then(() => {
                switch (state?.user?.role) {
                    case 1:
                        navigate('/');
                        break;
                    case 2:
                        navigate('/');
                        break;
                    case 3:
                        navigate('/');
                        break;
                    case 4:
                        navigate('/');
                        break;
                    default:
                        navigate('/');
                        break;
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [navigate])
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handellogin();
        }
    };
    const handellogin = async () => {
        if (!email || !password) {
            setShowModal(true)
        }
        let result = await fetch(`${baseurl}/api/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        result = await result.json();
        if (result?.message == "Unauthenticated") {
            setShowModal(true);
            return
        }
        if (result) {
            await dispatch({
                type: "USER_LOGIN",
                payload: {
                    user: result.user,
                    token: result.token
                }
            })
            await dispatch({
                type: "QA_LEARNERS",
                payload: {
                    qaLearners: result?.user?.learners
                }
            })
            const userToken = { token: result.token };
            const userData = JSON.stringify(userToken);
            localStorage.setItem("user", userData);
            if (result.user.role == 1) {
                alert('Invalid User role');
                navigate('/');
            } else if (result.user.role == 2 || result.user.role == 2 || result.user.role == 4) {
                navigate('/');
            }
        }
    }
    return (
        <>
            <div className="LoginBody row row-no-gutters mx-0 " >
                <div className="col-sm-4 px-md-0 px-1">
                    <div className="form-style">
                        <div className="login-one">
                            <div className="main-login Loginform-container">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={img1} alt="" />
                                    </div>
                                    <div className="icon-text">
                                        <p className="icon-tx">onwards & Upwards </p>
                                        <p className="icon-tx">Training </p>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="login-form">
                                    <h2 className="Hello">Hello Again!</h2>
                                    <p className="hello-tag">Welcome back, you’ve been missed.</p>
                                    <div className="main-form" onKeyDown={handleKeyPress}>
                                        <div className="form-group pb-md-3 pb-3">
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" name="uname" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Your Password" name="psw" required />
                                        </div>
                                        <Link style={{ textDecoration: "none" }} to="/forgotpassword_email">
                                            <p className="Forgot-Password pt-1">Forgot Password?</p>
                                        </Link>
                                        <button onClick={handellogin} className="LoginButton" type="button">Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8 d-none d-md-block  px-0">
                    <div className="login-two">
                        <div className="crousel">
                            <Carousel>
                                <div>
                                    <h3>Lift & Learn</h3>
                                    <p className="crousel-tx">
                                        Hey there, future elevator expert! Ready to elevate your skills? Dive in!</p>
                                </div>
                                <div>
                                    <h3>Skyward Elevations</h3>
                                    <p className="crousel-tx">Reach for the stars! Every login here takes you a level up. Let's do this!</p>
                                </div>
                                <div>
                                    <h3>Peak Performance Lift</h3>
                                    <p className="crousel-tx">Hello, game changer! Every assessment, a step closer to the top.</p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <MyModal showModal={showModal} handleClose={handleCloseModal} />
        </>
    )
}
export default Login;