import 'jodit';
import JoditEditor from 'jodit-react';
import 'jodit/build/jodit.min.css';
import React, { useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Select, { components } from 'react-select';
import iconone from "../images/icon-one.png";
import iconthree from "../images/icon-three.png";

const JobEnteries = (props) => {

    const baseurl = process.env.REACT_APP_BASE_URL;
    const { id, rowNumber, title, unitId } = props;
    const [isTextClickable, setIsTextClickable] = useState(false);
    const [accordionOpenStates, setAccordionOpenStates] = useState([]);
    const [CrossReferencingPageData, setCrossReferencingPageData] = useState([]);
    const [grade, setGrade] = useState("")
    const navigate = useNavigate();

    const handleProvideFeedbackClick = (index) => {
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = true;
        updatedCrossReferencingPageData[index].editingFeedback = updatedCrossReferencingPageData[index]?.feedback[0]?.feedback || '';
        setCrossReferencingPageData(updatedCrossReferencingPageData);
    };
    const handleCancelFeedback = (index, questionNumber) => {
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = false;
        setCrossReferencingPageData(updatedCrossReferencingPageData);
    };
    const handleSaveFeedback = (index, questionNumber) => {
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].isFeedbackOpen = false;
        updatedCrossReferencingPageData[index].savedFeedback = updatedCrossReferencingPageData[index].editingFeedback;
        setCrossReferencingPageData(updatedCrossReferencingPageData);
    };
    const handleGradeChange = async (selectedOption, answerId, index) => {
        const selectedGrade = selectedOption?.value;
        setGrade(selectedOption);
        const updatedCrossReferencingPageData = [...CrossReferencingPageData];
        updatedCrossReferencingPageData[index].grade = selectedOption;
        setCrossReferencingPageData(updatedCrossReferencingPageData);
        gradingData(
            JSON.parse(localStorage.getItem('user')).token,
            selectedGrade,
            answerId
        );
    };
    const getQuestionBarClassName = (grade, answerStatusID) => {
        if (grade?.value == undefined && answerStatusID == 1) {
            return 'Question-bar pass';
        }
        if (grade?.value == undefined && answerStatusID == 2) {
            return 'Question-bar fail';
        }
        if (grade?.value == 1) {
            return 'Question-bar pass';
        } else if (grade?.value == 2) {
            return 'Question-bar fail';
        }
        return 'Question-bar';
    };
    const options = [
        {
            value: 1,
            label: 'Passed',
            icon: <AiOutlineCheckSquare color="green" style={{ fontSize: "22px" }} />,
            color: "green",
        },
        {
            value: 2,
            label: 'Failed',
            icon: <RxCross2 color="red" style={{ fontSize: "22px" }} />,
            color: 'red',
        },
    ];
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            backgroundColor: 'inherit',
            width: '95px',
            color: state.selectProps.value?.color,
            '@media (max-width: 767px)': {
                paddingBottom: '10px',
                fontSize: '10px'
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#0062f4',
            fontWeight: '600'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 'bold',
        }),
    };
    const DropdownIndicator = (props) => {
        return <components.DropdownIndicator {...props} />;
    };
    const getOptionLabel = (option) => (
        <div style={{ color: option.color }}>{option.icon} {option.label}</div>
    );
    const getCrossReferencingData = async (token, unitId, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments-job-record/${id}/${unitId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }
            result = await result.json();
            const initialCrossReferencingPageData = result?.jobs?.map((question) => ({
                isFeedbackOpen: false,
                savedFeedback: '',
                editingFeedback: '',
                grade: null,
                ...question
            }));
            setCrossReferencingPageData(initialCrossReferencingPageData);
            setAccordionOpenStates(new Array(initialCrossReferencingPageData.length).fill(false));
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const gradingData = async (token, selectedGrade, answerId) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments-job-record-grading/${answerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "status": selectedGrade })
            });
            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleButtonClick = (index) => {
        setIsTextClickable(!isTextClickable);
        const updatedAccordionOpenStates = [...accordionOpenStates];
        updatedAccordionOpenStates[index] = !updatedAccordionOpenStates[index];
        setAccordionOpenStates(updatedAccordionOpenStates);
    };

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('accordion clicked!'),
        );
        return (
            <div onClick={decoratedOnClick}>
                {children}
            </div>
        );
    }
    const FeedBack = async (token, feedback, answerId, index) => {
        try {
            const updatedCrossReferencingPageData = [...CrossReferencingPageData];
            updatedCrossReferencingPageData[index].editingFeedback = feedback;
            setCrossReferencingPageData(updatedCrossReferencingPageData);
            let result = await fetch(`${baseurl}/api/student-assignments-job-record-feedback/${answerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "feedback": feedback })
            });
            if (!result.ok) {
                throw new Error('Failed to fetch cross referencing data');
            }
            const updatedAnswers = { ...CrossReferencingPageData[index], feedback: [{ feedback }] };
            updatedCrossReferencingPageData[index] = updatedAnswers;
            setCrossReferencingPageData(updatedCrossReferencingPageData);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getCrossReferencingData(JSON.parse(auth).token, unitId, id);
        } else {
            navigate('/');
        }
    }, []);

    return (
        <>
            <section className="sec-2-Student-Profile-Progress px-0">
                <div className="container px-0">
                    <h2 className="sec-2-heading">{rowNumber}</h2>
                    <p className="sec-2-Underpinning-Questions-tx">{title}</p>
                    <Accordion defaultActiveKey="0" alwaysOpen >
                        {CrossReferencingPageData?.map((item, index) => {
                            return (
                                (item?.description.every((desc) => !desc || desc.length === 0)) ?
                                    (<Card key={index}>
                                        <Card.Header className='p-0'>
                                            <div className="Question-1  p-0">
                                                <div className={getQuestionBarClassName(item?.grade, item?.status_id)}>
                                                    <div className='w-full'>
                                                        <h6 className='job_enteries_title'><b>Job Title:</b> {item?.job_title}</h6>
                                                        <h6 className="job_enteries_title"><b>Job Date:</b> {new Date(item?.job_date).toLocaleString('en-GB').slice(0, 10)}</h6>
                                                        <h6 className='job_enteries_title'><b>Job Location:</b> {item?.job_location}</h6>
                                                    </div>
                                                    {!item?.isFeedbackOpen ? (
                                                        <>
                                                            <div className="Student-Profile-Assignments-icons-boxes">
                                                                <div className="save-icon-box">
                                                                    <div className="save-icon">
                                                                        <img src={iconone} alt="" />
                                                                    </div>
                                                                    <p className="save-tx">
                                                                        <a
                                                                            className="save-tx"
                                                                            href={`${item?.file}`}
                                                                            target="_blank"
                                                                        >
                                                                            Download
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                                <Select
                                                                    value={item.grade}
                                                                    onChange={(grade, e) => handleGradeChange(grade, item?.id, index)}
                                                                    className="grade-dropdown"
                                                                    styles={customStyles}
                                                                    isSearchable={false}
                                                                    placeholder={
                                                                        <div className={`${item?.status == "Passed" ? "text-success fw-bold" : item?.status == "Failed" ? "text-danger fw-bold" : "text-primary"}`}>
                                                                            {(item?.status == "Passed") ?
                                                                                <AiOutlineCheckSquare style={{ fontSize: "22px", marginRight: "4px" }} />
                                                                                :
                                                                                (item?.status == "Failed") ?
                                                                                    <RxCross2 style={{ fontSize: "22px", marginRight: "4px" }} />
                                                                                    :
                                                                                    <img src={iconthree} className="selectimage" alt="" style={{ marginRight: "2px" }} />
                                                                            }
                                                                            {item.grade ? item.grade.label : (item?.status)}
                                                                        </div>
                                                                    }
                                                                    style={{ border: "none", outline: "none", backgroundColor: "inherit", }}
                                                                    options={options}
                                                                    components={{ DropdownIndicator }}
                                                                    getOptionValue={(option) => option?.value}
                                                                    getOptionLabel={getOptionLabel}
                                                                />
                                                            </div>
                                                        </>
                                                    ) :
                                                        (
                                                            <>
                                                                <div className="save_cancel">
                                                                    <div className="save-icon-box" onClick={() => handleCancelFeedback(index)}>
                                                                        <p className="save-tx">Cancel</p>
                                                                    </div>
                                                                    <div className="save-icon-box" onClick={() => handleSaveFeedback(index)}>
                                                                        <p className="save-tx"
                                                                            onClick={() => {
                                                                                FeedBack(
                                                                                    JSON.parse(localStorage.getItem('user')).token,
                                                                                    item?.editingFeedback,
                                                                                    item?.id,
                                                                                    index
                                                                                )
                                                                            }}>Save</p>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                </div>
                                            </div>
                                        </Card.Header>
                                        {
                                        }
                                    </Card>)
                                    :
                                    (item?.description) ?
                                        (<Card key={index}>
                                            <Card.Header className='p-0'>
                                                <div className="Question-1 w-100 p-0">
                                                    <div className={getQuestionBarClassName(item?.grade, item?.status_id)}>
                                                        <div className='w-full'>
                                                            <h6 className='job_enteries_title'><b>Job Title:</b> {item?.job_title}</h6>
                                                            <h6 className="job_enteries_title"><b>Job Date:</b> {new Date(item?.job_date).toLocaleString('en-GB').slice(0, 10)}</h6>
                                                            <h6 className='job_enteries_title'><b>Job Location:</b> {item?.job_location}</h6>
                                                        </div>
                                                        {!item?.isFeedbackOpen ? (
                                                            <>
                                                                <div className="Student-Profile-Assignments-icons-boxes accordion-box">
                                                                    <div className="save-icon-box " onClick={(index) => handleButtonClick(index)}>
                                                                        <div className="save-icon" >
                                                                            <img src={iconone} alt="" />
                                                                        </div>
                                                                        <CustomToggle
                                                                            eventKey={index}>
                                                                            <p className="save-tx" >Show</p>
                                                                        </CustomToggle>
                                                                    </div>
                                                                    <Select
                                                                        value={item.grade}
                                                                        onChange={(grade, e) => handleGradeChange(grade, item?.id, index)}
                                                                        className="grade-dropdown"
                                                                        styles={customStyles}
                                                                        isSearchable={false}
                                                                        placeholder={
                                                                            <div className={`${item?.status == "Passed" ? "text-success fw-bold" : item?.status == "Failed" ? "text-danger fw-bold" : "text-primary"}`}>
                                                                                {(item?.status == "Passed") ?
                                                                                    <AiOutlineCheckSquare style={{ fontSize: "22px" }} />
                                                                                    :
                                                                                    (item?.status == "Failed") ?
                                                                                        <RxCross2 style={{ fontSize: "22px" }} />
                                                                                        :
                                                                                        <img src={iconthree} className="selectimage" alt="" />
                                                                                }
                                                                                {item.grade ? item.grade.label : (item?.status)}
                                                                            </div>
                                                                        }
                                                                        style={{ border: "none", outline: "none", backgroundColor: "inherit", }}
                                                                        options={options}
                                                                        components={{ DropdownIndicator }}
                                                                        getOptionValue={(option) => option?.value}
                                                                        getOptionLabel={getOptionLabel}
                                                                    />
                                                                    {
                                                                    }
                                                                </div>
                                                            </>
                                                        ) :
                                                            (
                                                                <>
                                                                    <div className="save_cancel">
                                                                        <div className="save-icon-box" onClick={() => handleCancelFeedback(index)}>
                                                                            <p className="save-tx">Cancel</p>
                                                                        </div>
                                                                        <div className="save-icon-box" onClick={() => handleSaveFeedback(index)}>
                                                                            <p className="save-tx"
                                                                                onClick={() => {
                                                                                    FeedBack(
                                                                                        JSON.parse(localStorage.getItem('user')).token,
                                                                                        item?.editingFeedback,
                                                                                        item?.id,
                                                                                        index
                                                                                    )
                                                                                }}>Save</p>
                                                                        </div>
                                                                    </div>
                                                                </>)}
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Accordion.Collapse
                                                eventKey={index}>
                                                <Card.Body>
                                                    {item?.description?.map((item, index) => (
                                                        < div
                                                            className='mb-3'
                                                            key={index}
                                                        >
                                                            <JoditEditor
                                                                value={item}
                                                                config={{
                                                                    readonly: true,
                                                                    allowTags: ["video", "iframe"],
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        )
                                        :
                                        null
                            )
                        })}
                    </Accordion>
                </div>
            </section >
        </>
    );
};
export default JobEnteries;