import JoditEditor from 'jodit-react'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import AssessorHeader from './AssessorHeader'
import AssessorSideBar from './AssessorSideBar'
import { config } from './JoditConfig'

function AssessorAddComments() {

    const baseurl = process.env.REACT_APP_BASE_URL
    const [review_made, setReview_made] = useState("")
    const [description, setDescription] = useState("")
    const [reviewLocation, setReviewLocation] = useState("")
    const [availableReviewDates, setAvailableReviewDates] = useState([])
    const { id } = useParams()
    const navigate = useNavigate()
    const editor = useRef(null);

    useEffect(() => {
        getReviews()
    }, [])

    const addComments = async (token) => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        try {
            let result = await fetch(`${baseurl}/api/planned-comment`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: id, review_made, description, location: reviewLocation })
            });
            if (result.ok) {
                alert("comments submitted")
                navigate(-1)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const getReviews = async () => {
        const auth = localStorage.getItem("user");
        var token = JSON.parse(auth).token
        let result = await fetch(`${baseurl}/api/get-review/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setAvailableReviewDates(result)
    }
    
    return (
        <>
            <div className="das-main">
                <AssessorSideBar />
                <section className="das-cntn">
                    <AssessorHeader />
                    <section className="Add-New-Job-Assessment-Record">
                        <div className="Add-New-Job-Assessment-Record-container">
                            <div className="Add-New-Job-Assessment-Record-main-one">
                                <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Review Comments</h1>
                                <form>
                                    <div className="top-fields">
                                        <div className="form-col-one">
                                            <div className="field">
                                                <h5>Review Date</h5>
                                                <select value={review_made} onChange={(e) => setReview_made(e.target.value)}>
                                                    <option value="" className='text-secondary' disabled selected hidden>Select date</option> { }
                                                    {availableReviewDates?.map((item, index) =>
                                                        <option value={item?.review_date}>{item?.review_date}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="field">
                                                <h5>Location</h5>
                                                <input type="text" value={reviewLocation} onChange={(e) => setReviewLocation(e.target.value)} placeholder="Location" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="location-field">
                                        <h5 className="Job-Location">Review Comments</h5>
                                        <br></br>
                                        {
                                        }
                                        <JoditEditor
                                            ref={editor}
                                            value={description}
                                            onBlur={
                                                (newContent) => {
                                                    setDescription(newContent);
                                                }
                                            }
                                            style={{ height: "800px" }}
                                            config={config}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Button varaint="primary" onClick={addComments}>Submit</Button>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </section >
                </section >
            </div >
        </>
    )
}
export default AssessorAddComments;