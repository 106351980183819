import { Carousel } from "antd";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import img1 from "../images/Group1000001554.png";
import MyModal from "./Modal";

const ForgorPassword_newpass = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [OTP, setOTP] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const { email } = useParams()

    const handleCloseModal = async () => {
        setShowModal(false);
    };

    const handellogin = async (e) => {
        e.preventDefault()
        if (!password) {
            setShowModal(true)
            return
        }
        if (password != confirmPassword) {
            setShowModal(true)
            return
        }
        await resetPassword()
    }

    const resetPassword = async () => {
        let result = await fetch(`${baseurl}/api/auth/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "otp": OTP, "email": email, "new_password": password, "new_password_confirmation": confirmPassword })
        });
        if (result.ok) {
            alert("Password Changed Successfully")
            navigate("/")
        }
        else {
            alert("Invalid OTP")
        }
    }

    return (
        <>
            <div className="LoginBody row row-no-gutters mx-0 " >
                <div className="col-sm-4 px-md-0 px-1">
                    <div className="form-style">
                        <div className="login-one">
                            <div className="main-login Loginform-container">
                                <div className="icon-box">
                                    <div className="icon">
                                        <img src={img1} alt="" />
                                    </div>

                                    <div className="icon-text">
                                        <p className="icon-tx">onwards & Upwards </p>
                                        <p className="icon-tx">Training </p>
                                    </div>
                                </div>
                                <br /><br />
                                <div className="login-form">
                                    <h2 className="Hello">Forgot Password</h2>
                                    <p className="hello-tag">OTP is sent to your account <Link to="/forgotpassword_email">Resend</Link></p>
                                    <div className="main-form">
                                        <form onSubmit={handellogin}>
                                            <div className="form-group">
                                                <input type="number" value={OTP} onChange={(e) => setOTP(e.target.value)} placeholder="OTP" name="OTP" required />
                                            </div>
                                            <div className="form-group pt-md-3 pt-3">
                                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New Password" name="psw" required />
                                            </div>
                                            <div className="form-group pt-md-3 pt-3">
                                                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" name="new_psw" required />
                                            </div>
                                            <button className="LoginButton" type="submit">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-8 d-none d-md-block  px-0">
                    <div className="login-two">
                        <div className="crousel">
                            <Carousel>
                                <div>
                                    <h3>Lift & Learn</h3>
                                    <p className="crousel-tx">
                                        Hey there, future elevator expert! Ready to elevate your skills? Dive in!</p>
                                </div>
                                <div>
                                    <h3>Skyward Elevations</h3>
                                    <p className="crousel-tx">Reach for the stars! Every login here takes you a level up. Let's do this!</p>
                                </div>
                                <div>
                                    <h3>Peak Performance Lift</h3>
                                    <p className="crousel-tx">Hello, game changer! Every assessment, a step closer to the top.</p>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <MyModal showModal={showModal} handleClose={handleCloseModal} />
        </>
    )
}

export default ForgorPassword_newpass;

