import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import note2 from "../images/note-2.png";
import note from "../images/note.png";
import Footer from "./Footer";
import Header from "./Header";
import SupportFooter from "./SupportFooter";

const JobAssessmentRecord = () => {

  const baseurl = process.env.REACT_APP_BASE_URL;
  const [coursesDetails, setCoursesDetails] = useState()
  const { id } = useParams()
  const navigate = useNavigate();
  const params = useParams();
  const [JobAssessmentRecord, setJobAssessmentRecord] = useState([]);
  let { state, dispatch } = useContext(GlobalContext);
  const qaSelectedLearner = state?.qaSelectedLearner
  const role = state?.user?.role

  useEffect(() => {
    const auth = localStorage.getItem("user");
    if (auth) {
      getJobAssessmentRecord(JSON.parse(auth).token);
      getCoursesDetails(JSON.parse(auth).token)
    } else {
      navigate("/");
    }
  }, [qaSelectedLearner]);

  const getJobAssessmentRecord = async (token) => {
    let result = await fetch(`${baseurl}/api/job-records${role === 5 ? '?user_id=' + qaSelectedLearner : ''}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    result = await result.json();
    setJobAssessmentRecord(result);
  };

  const getCoursesDetails = async (token) => {
    let result = await fetch(`${baseurl}/api/units/${params?.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    result = await result.json();
    setCoursesDetails(result);
  }

  return (
    <>
      <Header />
      <section className="banner-3">
        <div className="main-banner">
          <div className="unit-1">
            <div className="unit-icon">
              <img src={note} alt="" />
            </div>
            <p className="unit-1-tx">Unit {coursesDetails?.unit_number}</p>
          </div>
          <h2 className="unit-1-heading h1-responsive">
            {coursesDetails?.title}
          </h2>
        </div>
      </section>

      <section className="sec-two-Underpinning-Questions">
        <div className="sec-two-Underpinning-Questions-container">
          <div className="Job-Records-sec">
            <div className="type-bar">
              <div className="Entry">
                <p className="h1 Job-Records">Job Records</p>
                <Link to={`/addjobrecord/${id}`}>
                  <button type="submit" className="d-flex align-self-center btns2">
                    + Add New Job Assessment Record
                  </button>
                </Link>
              </div>

              <div className="table-responsive">
                <table className="jobAssessmentrecordTable">
                  <tr className="First-row">
                    <th>Date</th>
                    <th>Job Entry Title</th>
                    <th>Job Location</th>
                    <th>Status</th>
                    <th className="dashboard-table-actions"></th>
                  </tr>
                  {JobAssessmentRecord?.map((item, index) => (
                    (item?.course_id == id) ?
                      <tr>
                        <td>{new Date(item?.job_date).toLocaleString('en-GB').slice(0, 10)}</td>
                        <td class="overflow-ellipsis">{item?.job_title}</td>
                        <td class="overflow-ellipsis" >{item?.job_location}</td>
                        <td>

                          {(item?.status == "Passed") ?
                            <button type="submit" className="btns5">
                              {item?.status}
                            </button>
                            :
                            (item?.status == "Failed") ?
                              <button type="submit" className="btns5Failed">
                                {item?.status}
                              </button>
                              :
                              (item?.status == "Pending") ?
                                <button type="submit" className="btns5Pending">
                                  {`${item?.status} Witness Approval`}
                                </button>
                                :
                                <button type="submit" className="btns5Draft">
                                  {item?.status}
                                </button>
                          }

                        </td>
                        <td>
                          {(item?.status == "Failed" || item?.status == "Draft") ?
                            <Link to={"/editjobrecord/" + item?.id + "/" + id}>
                              <div className="Review-icon-box">
                                <div className="Review-icon">
                                  <img src={note2} alt="" />
                                </div>
                                <h4 className="Review-tx">Edit</h4>
                              </div>
                            </Link>
                            :
                            (item?.status == "Passed") ?
                              <Link to={"/showjobrecord/" + item?.id}>
                                <div className="Review-icon-box">
                                  <div className="Review-icon">
                                    <img src={note2} alt="" />
                                  </div>
                                  <h4 className="Review-tx">Review</h4>
                                </div>
                              </Link>
                              :
                              <Link to={"/showjobrecord/" + item?.id}>
                                <div className="Review-icon-box">
                                  <div className="Review-icon">
                                    <img src={note2} alt="" />
                                  </div>
                                  <h4 className="Review-tx">Review</h4>
                                </div>
                              </Link>
                          }
                        </td>
                      </tr>
                      : null
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SupportFooter />

      <Footer />
    </>
  );
};

export default JobAssessmentRecord;
