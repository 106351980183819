import React, { useState, useEffect } from "react";
import { BiSolidBell } from "react-icons/bi";
import { GlobalContext } from '../context/Context';
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Badge } from 'react-bootstrap';
import Pusher from 'pusher-js';

const DashboardHeader = () => {

    let { state, dispatch } = useContext(GlobalContext);
    const baseurl = process.env.REACT_APP_BASE_URL
    const [userNotification, setUserNotification] = useState([])
    const [bagdeCounter, setBagdeCounter] = useState(0)
    var channel;
    var pusher;
    const username = state?.user?.name;
    const userAvatar = state?.user?.image;
    const role = state?.user?.role
    const id = state?.user?.id;

    const getNotifications = async (token) => {
        try {
            let result = await fetch(`${baseurl}/api/notifications`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch notifications data');
            }

            result = await result.json();

            setUserNotification(result?.notifications)
            setBagdeCounter(result?.unread_count)

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const PusherNotifications = () => {
         Pusher.logToConsole = false;
        pusher = new Pusher('4a0829c13e55b642e1ae', {
            cluster: 'eu'
        });
        channel = pusher.subscribe(`notification-${id}`);
        channel.bind('receive', function (data) {
            setUserNotification((prevNotifications) => [data.data, ...prevNotifications]);
            setBagdeCounter((prev) => prev + 1)
        });
    }

    useEffect(() => {
        const auth = localStorage.getItem("user");
        const token = JSON.parse(auth).token
        if (token) {
            getNotifications(token)
            PusherNotifications()
        }
        return () => {
            channel.unbind('receive');
            channel.unsubscribe(`notification-${id}`);
            channel.disconnect();
        };
    }, [])

    return (
        <>
            <header>
                <div className="main-header-Dashbord">
                    <div className="dashbord-heading-tag">
                        <h3 className="dashbord-heading">Dashboard</h3>
                    </div>
                    <div className="header-side-two">
                        {role === 4 && <div className="">
                            <Dropdown >
                                <Dropdown.Toggle className="bell_dropdown">
                                    <BiSolidBell style={{ color: "#0163f4", fontSize: "24px" }} />
                                    <Badge bg="danger" pill className="bell_dropdown_badge">{bagdeCounter}</Badge>
                                </Dropdown.Toggle>
                                <div className="bell_dropdown_items">
                                    <Dropdown.Menu className="bell_dropdown_menu">
                                        {role === 4 && userNotification?.slice(0, 5)?.map((item, index) =>
                                            < Dropdown.Item >
                                                <Link style={{textDecoration:"none",color:"inherit"}} to={item?.route_to}>
                                                    <div className="bell_dropdown_message">
                                                        <div className="fw-bold">
                                                            {item?.fromuser?.name}
                                                        </div>
                                                        <div>
                                                            <p className="text-wrap mb-1">
                                                                {item?.message}
                                                            </p>
                                                        </div>

                                                        <div className="">
                                                            <p className="text-secondary m-0 p-0">
                                                                {new Date(item?.created_at).toLocaleString()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item className="bell_dropdown_view_all">
                                            <Link to={`/notifications/${id}`}>
                                                <div className="text-primary d-flex justify-content-center mt-2 p-0">
                                                    <p>
                                                        View All
                                                    </p>
                                                </div>
                                            </Link>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </div>
                            </Dropdown>
                        </div>}
                        <div className="user-info">
                            <div className="user-img">
                                <img src={userAvatar} style={{ width: "40px", height: "40px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                            </div>
                            <div className="user-name-id">
                                <h3 className="user-name">{username}</h3>
                            </div>
                        </div>
                    </div>
                </div >

            </header >
        </>
    )
}

export default DashboardHeader;