import React, { useRef, useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useParams } from 'react-router-dom'

export default () => {
  
  const { id } = useParams()
  const baseurl = process.env.REACT_APP_BASE_URL
  const iframeRef = useRef(null)
  const [learningMaterial, setLearningMaterial] = useState('')

  useEffect(() => {
    const auth = localStorage.getItem('user');
    if (auth) {
      getLearningMaterial(JSON.parse(auth).token);
    }
  }, []);
  const getLearningMaterial = async (token) => {
    let result = await fetch(`${baseurl}/api/units/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    result = await result.json();
    setLearningMaterial(result?.learning_material_link?.url)
  }

  return (
    <>
      <div className='iframe-container'>
        <IframeResizer
          forwardRef={iframeRef}
          heightCalculationMethod="lowestElement"
          inPageLinks
          log
          src={learningMaterial}
          style={{ width: '1px', minWidth: '100%', height: "100vh" }}
        />
      </div>
    </>
  )
}
