import { Upload } from 'antd';
import 'filepond/dist/filepond.min.css';
import JoditEditor from "jodit-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FilePond } from 'react-filepond';
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from '../context/Context';
import file from '../images/bi_cloud-upload.png';
import Header from "./Header";
import { config } from "./JoditConfig";

const AddJobRecord = () => {

    const baseurl = process.env.REACT_APP_BASE_URL
    const { id } = useParams()
    const course_id = id
    const [project_number, setProjectName] = useState("test");
    const [job_reference, setJobReference] = useState("test");
    const [job_title, setJobTitle] = useState("");
    const [job_date, setJobDate] = useState("");
    const [job_location, setJobLocation] = useState("");
    const [description, setContent] = useState("");
    const editor = useRef(null);
    const filePondRef = useRef(null);
    const [fileUpload, setfileUpload] = useState([]);
    const [sections, setSections] = useState([""]);
    const [trainers, setTrainers] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState("");
    const [filelink, setfilelink] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    let { state, dispatch } = useContext(GlobalContext);
    const role = state?.user?.role
    const auth = localStorage.getItem("user");
    const token = JSON.parse(auth).token
    const collectData = async (status) => {
        const auth = localStorage.getItem('user');
        setIsLoading(true);
        var jobRecord = { project_number, job_reference, job_title, job_date, job_location, sections, file: filelink, course_id, trainer_id: selectedTrainer }
        if (status) {
            jobRecord["status"] = status
        }
        if (status !== 4) {
            if (role == 4 && !selectedTrainer) {
                alert("Please Select Trainer")
                setIsLoading(false)
                return
            }
        }
        try {
            let result = await fetch(`${baseurl}/api/job-entry`, {
                method: 'post',
                body: JSON.stringify(jobRecord),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(auth).token}`
                }
            });
            result = await result.json();
            if (result) {
                setIsLoading(false);
                navigate(-1);
            }
        }
        catch {
            setIsLoading(false);
        }
    }
    const [show, setShow] = useState(false);
    const showModal = () => {
        setShow(true);
    };
    const handleClose = () => {
        filePondRef.current.removeFiles();
        setFiles([])
        setShow(false);
    }
    const [files, setFiles] = useState([]);
    const [filesList, setfilesList] = useState([]);
    useEffect(() => {
        getallfiles();
        getTrainers()
    }, []);
    const getTrainers = async () => {
        const auth = localStorage.getItem('user');
        try {
            let result = await fetch(`${baseurl}/api/get-trainers`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(auth).token}`
                }
            });
            result = await result.json();
            setTrainers(result?.trainers)
        }
        catch {
            console.log("error");
        }
    }
    const getFiles = async (file) => {
        const formData = new FormData();
        formData.append("uploaded_file", file);
        try {
            const response = await fetch(`${baseurl}/api/store-file`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const relativeURL = await response.text();
            setfilelink(relativeURL);
        } catch (error) {
            console.error(error);
        }
    };
    const getallfiles = async () => {
        try {
            var result = await fetch(`${baseurl}/api/get-file`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            result = await result.json();
            setfilesList(result)
        } catch (error) {
            console.error(error);
        }
    }
    const CodeRef = useRef(null);
    const CopyEmbeddedCode = ({ baseurl, item }) => {
        const embeddedCodeRef = useRef(null);
        const generateEmbeddedCode = () => {
            // const embeddedCode = `<div><video controls><source src="${baseurl}${item.file_url.replace('public', '/storage')}" type="video/mp4" /></video></div>`;
            const embeddedCode = `<div><iframe  width="679px" height="373px" src="${baseurl}${item.file_url.replace('public', '/storage')}" autoplay="false" allowfullscreen="" sandbox></iframe></div>`
            if (embeddedCodeRef.current) {
                embeddedCodeRef.current.value = embeddedCode;
                embeddedCodeRef.current.select();
                document.execCommand('copy');
            }
        };
        return (
            <div className="d-flex flex-column row g-1 mt-1">
                <input className="col" ref={embeddedCodeRef} type="text" readOnly />
                <button className="col CopyLinkBtn" onClick={generateEmbeddedCode} >Copy Embedded Code</button>
            </div>
        );
    };
    const CopyCode = ({ baseurl, item, }) => {
        const generateCode = () => {
            var Code = baseurl + item.file_url.replace('public', '/storage')
            if (CodeRef.current) {
                CodeRef.current.value = Code;
                CodeRef.current.select();
                document.execCommand('copy');
            }
        };
        return (
            <div className="d-flex flex-column row g-1 mt-1">
                <input className="col" ref={CodeRef} type="text" readOnly width="100%" />
                <button className="col CopyLinkBtn" onClick={generateCode}>Copy Link</button>
            </div>
        );
    };
    return (
        <>
            <Header />
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <label>Upload New File</label>
                        <FilePond
                            ref={filePondRef}
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={false}
                            name="uploaded_file"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            server={
                                {
                                    process: {
                                        url: `${baseurl}/api/store-file`,
                                        method: 'POST',
                                        headers: {
                                            'Authorization': `Bearer ${token}`
                                        },
                                        onload: (response) => {
                                            getallfiles();
                                        },
                                    }
                                }
                            }
                        />
                    </Row>
                    <Row>
                        <label>List</label>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gap: '5px',
                        }}>
                            {
                                filesList?.map((item, index) => (
                                    <div>
                                        {
                                            item.file_type == "mp4" && <img style={{ width: 200, aspectRatio: 1, objectFit: 'cover' }} src="https://isl.global/wp-content/uploads/2021/12/notfound.jpg" />
                                        }
                                        {
                                            item.file_type == "png" && <img style={{ width: 200, aspectRatio: 1 }} src={baseurl + item.file_url.replace('public', '/storage')} />
                                        }
                                        {
                                            item.file_type == "jpg" && <img style={{ width: 200, aspectRatio: 1 }} src={baseurl + item.file_url.replace('public', '/storage')} />
                                        }
                                        {
                                            item.file_type == "jpeg " && <img style={{ width: 200, aspectRatio: 1 }} src={baseurl + item.file_url.replace('public', '/storage')} />
                                        }
                                        <a style={{
                                            fontSize: '10px',
                                            display: 'block',
                                            width: '200px',
                                            wordWrap: 'break-word',
                                        }}
                                        >
                                            {item.file_type === "mp4" ?
                                                <>
                                                    {baseurl + item.file_url.replace('public', '/storage')}
                                                    < CopyEmbeddedCode baseurl={baseurl} item={item} />
                                                </> :
                                                <>
                                                    {baseurl + item.file_url.replace('public', '/storage')}
                                                    < CopyCode baseurl={baseurl} item={item} />
                                                </>
                                            }
                                        </a>
                                    </div>
                                ))}
                        </div>
                    </Row>
                </Modal.Body>
            </Modal >
            <section className="Add-New-Job-Assessment-Record">
                <div className="Add-New-Job-Assessment-Record-container">
                    <div className="Add-New-Job-Assessment-Record-main-one">
                        <h1 className="Add-New-Job-Assessment-Record-heading pb-5">Online Job Entry Submission</h1>
                        <form>
                            <div className="top-fields">
                                <div className="form-col-one">
                                    <div className="field">
                                        <h5>Job Title</h5>
                                        <input type="text" value={job_title} onChange={(e) => setJobTitle(e.target.value)} placeholder="Job Title" />
                                    </div>
                                    <div className="field">
                                        <h5>Job Date</h5>
                                        <input type="date" value={job_date} onChange={(e) => setJobDate(e.target.value)} placeholder="Job Date" />
                                    </div>
                                </div>
                            </div>
                            <div className="location-field">
                                <h5 className="Job-Location">Job Location</h5>
                                <input type="text" value={job_location} onChange={(e) => setJobLocation(e.target.value)} placeholder="Job Location" className="Job-Location" />
                            </div>
                            <div className="job-editor">
                                <div className="d-flex justify-content-between">
                                    <h5>Describe what you did to carry out this task</h5>
                                    {role == 4 &&
                                        <div>
                                            <select className="px-4" value={selectedTrainer} onChange={(event) => setSelectedTrainer(event.target.value)} required>
                                                < option value="" disabled>Select Witness Trainer</option>
                                                {trainers?.map((item, index) =>
                                                    < option value={item?.value}>{item?.label}</option>
                                                )}
                                            </select>
                                        </div>}
                                </div>
                                <br />
                                {fileUpload.length === 0 && (
                                    <div>
                                        {
                                            sections.map((section, section_index) => (
                                                <div key={section_index} style={{ marginBottom: 10 }}>
                                                    <JoditEditor
                                                        ref={editor}
                                                        value={section}
                                                        onBlur={
                                                            (newContent) => {
                                                                var s = [...sections];
                                                                s[section_index] = newContent;
                                                                setSections(s);
                                                            }
                                                        }
                                                        style={{ height: "800px" }}
                                                        config={config}
                                                    />
                                                </div>
                                            ))
                                        }
                                        <div className="d-flex justify-content-center justify-content-md-start pt-md-0 py-2">
                                            <Button variant="outline-success"
                                                style={{ width: "178px" }}
                                                onClick={() => {
                                                    var s = [...sections];
                                                    s.push("");
                                                    setSections(s);
                                                }}>+ Add New Step</Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <Button className="mt-2" variant="outline-danger"
                                    style={{ width: "178px" }}
                                    onClick={() => {
                                        showModal();
                                    }}>File Manager</Button>
                            </div>
                        </form>
                        {(role == 4 || role == 2) ?
                            <div className="">
                                {fileUpload.length === 0 && (
                                    <>
                                        <div>
                                            <button type="button" onClick={() => collectData()} style={{ width: "178px" }} className="btns10">Submit</button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => collectData(4)}
                                                className="btns10"
                                                disabled={isLoading}                                            >
                                                {isLoading ? "Uploading File..." : "Save As Draft"}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            :
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <button type="button" style={{ width: "178px" }} className="btns10">Submit </button>
                                <button type="button" style={{ width: "178px" }} className="btns10">Save As Draft </button>
                            </div>
                        }
                    </div>
                    <div className="Add-New-Job-Assessment-Record-main-two">
                        <div className="Add-New-Job-Assessment-Record-main-two-top-bar">
                            <div className="Add-New-Job-Assessment-Record-main-two-tx">
                                <h1 className="Add-New-Job-Assessment-Record-heading-one">Doc/files Submission</h1>
                                <p className="Add-New-Job-Assessment-Record-heading-text">If you have a word/pdf document, you can
                                    upload here.</p>
                            </div>
                            {!description && (role == 4 || role == 2) && (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => collectData()}
                                        className="btns10 mt-md-0 mt-2"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? "Uploading File..." : "Submit"}
                                    </button>
                                </>
                            )}
                            {role == 5 && (
                                <button
                                    type="button"
                                    className="btns10 mt-md-0 mt-2"
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                        {!description && (
                            <Upload.Dragger
                                multiple={false}
                                maxCount={1}
                                accept=".doc,.docx,.pdf"
                                beforeUpload={async (file) => {
                                    setIsLoading(true);
                                    try {
                                        await getFiles(file);
                                        setIsLoading(false);
                                    } catch (error) {
                                        console.error(error);
                                        setIsLoading(false);
                                    }
                                    return false;
                                }}
                                onRemove={e => setfileUpload([])}
                            >
                                <img src={file} alt="" />
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Supported file : PDF/Word
                                </p>
                            </Upload.Dragger>
                        )}
                    </div>
                </div >
            </section >
        </>
    )
}
export default AddJobRecord;
