import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clipboard from "../images/clipboard-text.svg";
import Footer from "./Footer";
import JobEnteries from "./JobEnteries";
import TrainerHeader from "./TrainerHeader";

const TrainerStudentProfile = () => {
    const baseurl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate()
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'tab1');
    const [activeUnit, setActiveUnit] = useState(localStorage.getItem('activeUnit') || '');
    const [studentAssignment, setStudentAssignment] = useState([])
    const [studentProfile, setStudentProfile] = useState([])

    const handleUnitChange = (key) => {
        setActiveUnit(key);
        localStorage.setItem('activeUnit', key);
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
        localStorage.setItem('activeTab', key);
    };

    const getStudentAssignment = async (token, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-assignments/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch student assignment data');
            }

            result = await result.json();
            setStudentAssignment(result);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const getStudentProfile = async (token, id) => {
        try {
            let result = await fetch(`${baseurl}/api/student-profile/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!result.ok) {
                throw new Error('Failed to fetch student profile data');
            }

            result = await result.json();
            setStudentProfile(result);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getStudentAssignment(JSON.parse(auth).token, id);
            getStudentProfile(JSON.parse(auth).token, id);
        } else {
            navigate('/');
        }
    }, []);

    return (
        <>
            <TrainerHeader />

            <section className="sec-1-Student-Profile-Progress">
                <div className="container">
                    <div className="main-sec-1-Student-Profile-Progress">
                        <div className="col-1">
                            <div className="student-image">
                                <img src={studentProfile?.photo} style={{ width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover" }} alt="" />
                            </div>
                            <h4 className="student-name text-sm-center">{studentProfile?.name}</h4>
                        </div>
                        <div className="col-2">
                            <h3 className="Course">Course</h3>
                            <p className="Course-tag">{studentProfile?.course_name}</p>
                        </div>
                        <div className="col-3">
                            <h3 className="student-percentage">{Math.round(studentProfile?.overall_progress)}%</h3>
                            <p className="percentage-tag">Overall Progress</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="custabs">
                <div className="container">
                    <Tabs activeKey={activeTab} onChange={handleTabChange} tabBarStyle={{ display: 'flex', flexDirection: 'column' }}>
                        <Tabs.TabPane tab={
                            <span>
                                <img src={clipboard} alt="" />Assignments
                            </span>
                        }
                            key="tab1">

                            <div className="custabs2">

                                <div className="custabs2 subcustabs2 row">
                                    <Tabs activeKey={activeUnit} onChange={handleUnitChange} className="col">

                                        {studentAssignment?.map((item, index) => (
                                            <Tabs.TabPane tab={
                                                <span>
                                                    {item?.row_number}                                                        </span>
                                            }
                                                key={item?.id}
                                            >
                                                <JobEnteries
                                                    id={id}
                                                    unitId={item?.id}
                                                    rowNumber={item?.row_number}
                                                    title={item?.title}
                                                />
                                            </Tabs.TabPane>
                                        ))}
                                    </Tabs>
                                </div>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default TrainerStudentProfile;



