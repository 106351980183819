import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Frame4 from "../images/Frame-4.png";
import Frame from "../images/Frame.png";
import Frame1 from "../images/Frame1.png";
import Frame2 from "../images/Frame2.png";
import bookopencover from "../images/book-open-cover 1.png";
import clock from "../images/clock.png";
import diploma1 from "../images/diploma1.png";
import note from "../images/note.png";
import Footer from "./Footer";
import Header from "./Header";
import SupportFooter from "./SupportFooter";

const UnitOverview = () => {

    const navigate = useNavigate();
    const params = useParams();
    const baseurl = process.env.REACT_APP_BASE_URL
    const [coursesDetails, setCoursesDetails] = useState([]);
    useEffect(() => {
        const auth = localStorage.getItem('user');
        if (auth) {
            getCoursesDetails(JSON.parse(auth).token);
        } else {
            navigate('/');
        }

    }, []);

    const getCoursesDetails = async (token) => {
        let result = await fetch(`${baseurl}/api/units/${params?.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        result = await result.json();
        setCoursesDetails(result);
    }
    return (
        <>
            <Header />
            <section className="banner">
                <div className="main-banner">
                    <div className="unit-1">
                        <div className="unit-icon">
                            <img src={note} alt="" />
                        </div>
                        <p className="unit-1-tx">Unit{coursesDetails?.unit_number}</p>
                    </div>
                    <h2 className="unit-1-heading">{coursesDetails?.title}</h2>
                </div>
            </section>

            <section className="sec-2-unit-Overview">
                <div className="main-sec-2-unit-Overview">
                    <div className="QCF-Level-box">
                        <div className="QCF-Level-icon-box">
                            <div className="QCF-Level-icon">
                                <img src={diploma1} alt="" />
                            </div>
                            <p className="QCF-Level-tx">QCF Level</p>
                        </div>
                        <div className="QCF-Level">
                            <p className="QCF-Level-number">{coursesDetails?.qcf_level}</p>
                        </div>
                    </div>

                    <div className="QCF-Level-box">
                        <div className="QCF-Level-icon-box">
                            <div className="QCF-Level-icon">
                                <img src={bookopencover} alt="" />
                            </div>
                            <p className="QCF-Level-tx">GLH</p>
                        </div>

                        <div className="QCF-Level">
                            <p className="QCF-Level-number">{coursesDetails?.glh}</p>
                        </div>

                    </div>

                    <div className="QCF-Level-box">
                        <div className="QCF-Level-icon-box">
                            <div className="QCF-Level-icon">
                                <img src={clock} alt="" />
                            </div>
                            <p className="QCF-Level-tx">Credit-Value</p>
                        </div>

                        <div className="QCF-Level">
                            <p className="QCF-Level-number">{coursesDetails?.credit_value}</p>
                        </div>
                    </div>
                </div>

            </section>
            <section className="sec-3-unit-Overview">
                <div className="main-sec-3-unit-Overview">
                    <div dangerouslySetInnerHTML={{ __html: coursesDetails?.summary }}></div>
                </div>
            </section>

            <section className="sec-4-unit-Overview">
                <div>

                    <div className="sec-4-unit-Overview-img-boxes d-flex">
                        <Link
                            to={`/learningmaterial/${params?.id}`}
                            className="img-boxes-1"
                        >
                            <div className="Learning-Materials-img-box">
                                <div className="Frame-img">
                                    <img src={Frame} alt="" />
                                </div>
                                <p className="Learning-Materials-tx">Learning Materials</p>
                            </div>
                        </Link>

                        <Link to={"/jobassessmentrecord/" + coursesDetails?.id} className="img-boxes-1">
                            <div className="Learning-Materials-img-box">
                                <div className="Frame-img">
                                    <img src={Frame4} alt="" />
                                </div>
                                <p className="Learning-Materials-tx">Job Assessment Record</p>
                            </div>
                        </Link>

                        <Link to={"/crossreferencing/" + coursesDetails?.id} className="img-boxes-1">
                            <div className="Learning-Materials-img-box">
                                <div className="Frame-img">
                                    <img src={Frame1} alt="" />
                                </div>
                                <p className="Learning-Materials-tx">Cross Referencing</p>
                            </div>
                        </Link>

                        <Link to={"/underpinningquestions/" + coursesDetails?.id} className="img-boxes-1">
                            <div className="Learning-Materials-img-box">
                                <div className="Frame-img">
                                    <img src={Frame2} alt="" />
                                </div>
                                <p className="Learning-Materials-tx">Underpinning Questions</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
            <SupportFooter />
            <Footer /></>
    )
}
export default UnitOverview;