import React from 'react'

function ConfirmationModal(props) {
    return (
        <>
            <div className="modal-wrapper">
                <div className="modal-inner">
                    <div>
                        <h2>{props.content}</h2>
                        <br />
                        <button onClick={props.onButtonClick}>Close</button>
                    </div>
                </div>
            </div>
        </>)
}

export default ConfirmationModal