export const config = {
    buttons: [
        "bold",
        "italic",
        "underline",
        "ul",
        "ol",
        "|",
        "outdent",
        "indent",
        "|",
        "font",
        "fontsize",
        "paragraph",
        "|",
        "image",
        "video",
        "|",
        "align",
        "|",
        "fullsize",
    ],
    height: 500,
    defaultCssValues: {
      'text-align': 'normal',
    },};